/**
 * @generated SignedSource<<80665d73978f896751ab193c3c40b248>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Terms_viewer$data = {
  readonly terms: any | null | undefined;
  readonly " $fragmentType": "Terms_viewer";
};
export type Terms_viewer$key = {
  readonly " $data"?: Terms_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Terms_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Terms_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terms",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "2531904140b693598d612d7d885c2ebd";

export default node;
