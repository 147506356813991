/**
 * @generated SignedSource<<7fd25ec76539cd3fdfbb72e37fbe50c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_viewer$data = {
  readonly cart: {
    readonly qty: number | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly firstName: string | null | undefined;
    readonly role: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Navigation_viewer";
};
export type Navigation_viewer$key = {
  readonly " $data"?: Navigation_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qty",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e646792391a4a265a1cbe0e892e669cd";

export default node;
