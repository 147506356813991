import {money} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Input} from 'semantic-ui-react';
import styled, {createGlobalStyle} from 'styled-components';
import {go, withWindowSize} from '../../helpers';
import {CustomIcon} from '../CustomIcon/CustomIcon';
import Badge from './Badge';
import {saleCategory} from './HeaderCategories';
import Logo from './Logo';

const Container = styled.div`
  padding: 0 10px;
`;

const OverflowHidden = createGlobalStyle`
  #root {
    overflow: hidden;
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.5em;
  width: 100%;

  & div {
    cursor: pointer;
  }

  & a {
    margin-top: -10px;
  }

  & .badge {
    font-size: 13px;
    font-weight: bold;
    text-align: center;

    line-height: 22px;
    right: 5px;
    top: 5px;
    width: 22px;
    height: 22px;
  }
`;

const Menu = styled.div`
  flex: 1;
  border-top: 1px solid #ddd;
  position: absolute;
  z-index: 1986;

  &.none {
    left: -5000px;
  }

  &.slide {
    transition: 0.33s;
    left: 0;
  }

  width: 100%;
  height: 100%;
  overflow: auto;

  background: #fff;
`;

const SearchInput = styled(Input)`
  border-bottom: 1px solid #ddd;
  border-left: 10px solid #1976d2;

  & > input {
    padding-top: 0.9em !important;
    padding-bottom: 0.9em !important;
    border: 0 !important;
    border-radius: 0 !important;

    &:focus {
      outline: none !important;
      background: #fff !important;
    }
  }
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75em 15px;
  border-bottom: 1px solid #eee;
  font-size: 1em;
  cursor: pointer;
  // background: #efefef;
  // border-bottom: 1px solid #fff;
  border-bottom: 1px solid #ddd;
  border-left: 10px solid ${({color}) => color};

  &:hover {
    background: #efefef;
  }
`;

const TopMenuItem = (props) => (
  <div className={props.isSelected ? 'selected' : ''} onClick={props.onClick}>
    <CustomIcon name={props.icon} style={{color: '#2185d0', fontSize: 26}} />
    <span>{props.children}</span>
  </div>
);

const MobileHeader = (props) => {
  const {height, match, router, viewer} = props;
  const pathname = (match && match.location && match.location.pathname) || '';
  const cartQty = (viewer && viewer.cart && viewer.cart.qty) || 0;

  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const [currentCategorySlug, setCurrentCategorySlug] = React.useState(null);
  const [isCartOpen, setCartOpen] = React.useState(false);

  const slugs = (currentCategorySlug || '')
    .split('/')
    .filter(Boolean)
    .reduce((p, c, i) => (i === 0 ? [c] : [...p, p[p.length - 1] + '/' + c]), []);

  const level0 = {
    ...viewer,
    categories: {
      ...viewer?.categories,
      edges: [...(viewer?.categories?.edges || []), saleCategory],
    },
  };

  const level1 = ((slugs[0] && level0.categories.edges.find(({node: c}) => c.slug === slugs[0])) || {node: null}).node;
  const level2 = ((slugs[1] && level1.categories.edges.find(({node: c}) => c.slug === slugs[1])) || {node: null}).node;

  //
  const announcement = document.getElementById('announcement');
  const menuHeight = height - 53 - (announcement, (announcement && announcement.offsetHeight) || 0);

  return (
    <>
      {viewer.user.isBanned ? (
        <div style={{alignItems: 'center', background: 'rgb(222, 53, 11)', color: 'white', display: 'flex', padding: 20}}>
          <h4 style={{display: 'inline', margin: 0, padding: 0, paddingRight: 10}}>Account Locked:</h4>
          <p style={{display: 'inlne', margin: 0, padding: 0}}>
            Please{' '}
            <Link style={{color: 'white', textDecoration: 'underline'}} to="/pay/owed">
              PAY HERE
            </Link>{' '}
            <a href="mailto:pastdues@kckoshercoop.com" style={{color: 'white', textDecoration: 'underline'}}>
              pastdues@kckoshercoop.com
            </a>{' '}
            to resolve your outstanding balance of {money(viewer.user.owed)} due to "{viewer.user.owedReason}", before placing your next
            order.
          </p>
        </div>
      ) : null}
      <Container>
        {(isMenuOpen || isCartOpen) && <OverflowHidden />}
        <Top>
          <TopMenuItem
            icon={isMenuOpen ? 'fas-times' : 'fas-bars'}
            isSelected={pathname.indexOf('/account') === 0}
            onClick={() => {
              if (!isMenuOpen) {
                const menu = document.getElementById('mobile-menu');
                if (menu) {
                  menu.scrollTop = 0;
                }
              }
              setMenuOpen(!isMenuOpen);
            }}
          />
          <Logo
            isMobile={true}
            onClick={(e) => {
              setCartOpen(false);
              setMenuOpen(false);
              go('/', {router})(e);
            }}
          />
          <TopMenuItem
            icon="fas-shopping-cart"
            onClick={() => {
              // if (isCartOpen === false) {
              window.history.pushState({cart: true}, '', '/cart');
              // }
              // setCartOpen(!isCartOpen);
            }}>
            <Badge>{cartQty}</Badge>
          </TopMenuItem>
        </Top>
        <Menu className={isMenuOpen ? 'slide' : 'none'} id="mobile-menu" style={{height: menuHeight}}>
          <SearchInput
            fluid
            icon="search"
            // loading
            // defaultValue={get(this.props.match, 'location.query.query') || ''}
            iconPosition="left"
            // onFocus={this.handleSearchFocus}
            onKeyPress={(e) => {
              if (e.which === 13) {
                const query = e.target.value;
                e.target.value = '';
                setMenuOpen(false);
                router.push(`/search?query=${query}`);
              }
            }}
            placeholder="Search our store"
            // ref={this.searchRef}
          />
          {level1 && (
            <>
              <MenuItem color="#2196f3" key="categories" onClick={(e) => setCurrentCategorySlug(null)}>
                <span>Categories</span>
                <CustomIcon name="fas-angle-right" />
              </MenuItem>
              <MenuItem
                color="#2196f3"
                key={level1.id}
                onClick={(e) => {
                  if (level1.skipRedirect) {
                    return;
                  }

                  if (currentCategorySlug === level1.slug) {
                    setMenuOpen(false);
                    setCurrentCategorySlug(null);
                    go('/categories/' + level1.slug, {router})(e);
                  } else {
                    setCurrentCategorySlug(level1.slug);
                  }
                }}>
                <span>&nbsp;&nbsp;{level1.name}</span>
                <CustomIcon name="fas-angle-right" />
              </MenuItem>
            </>
          )}
          {level2 && (
            <MenuItem
              color="#2196f3"
              key={level2.id}
              onClick={(e) => {
                if (currentCategorySlug === level2.slug) {
                  setMenuOpen(false);
                  setCurrentCategorySlug(null);
                  go('/categories/' + level2.slug, {router})(e);
                } else {
                  setCurrentCategorySlug(level2.slug);
                }
              }}>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;{level2.name}</span>
              <CustomIcon name="fas-angle-right" />
            </MenuItem>
          )}
          {(level2 || level1 || level0).categories.edges.map(({node: category}) => (
            <MenuItem
              color="#2196f3"
              key={category.id}
              onClick={(e) => {
                console.log('@', level2, category);
                if (category.categories) {
                  console.log('1');
                  setCurrentCategorySlug(category.slug);
                } else {
                  console.log('2');
                  setMenuOpen(false);
                  setCurrentCategorySlug(null);
                  go('/categories/' + category.slug, {router})(e);
                }
              }}>
              <span>
                {(level2 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>) || (level1 && <>&nbsp;&nbsp;&nbsp;&nbsp;</>) || ''}
                {category.name}
              </span>
              {!level2 && !category.withoutIcon && <CustomIcon name="fas-angle-right" />}
            </MenuItem>
          ))}
          <MenuItem
            color="#FF5722"
            onClick={(e) => {
              setMenuOpen(false);
              go('/account', {router})(e);
            }}>
            Account
          </MenuItem>
          <MenuItem
            color="#FF5722"
            onClick={(e) => {
              setMenuOpen(false);
              go('/pay', {router})(e);
            }}>
            Pay
          </MenuItem>
          <MenuItem
            color="#FF5722"
            onClick={(e) => {
              setMenuOpen(false);
              go('/help', {router})(e);
            }}>
            Help
          </MenuItem>
          <MenuItem
            color="#FF5722"
            onClick={(e) => {
              setMenuOpen(false);
              go('/contact', {router})(e);
            }}>
            Contact Us
          </MenuItem>
        </Menu>
      </Container>
    </>
  );
};

export default createFragmentContainer(withRouter(withWindowSize(MobileHeader)), {
  viewer: graphql`
    fragment MobileHeader_viewer on Viewer {
      categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
        edges {
          node {
            id
            name
            slug
            categories(filterBy: {isActive: true}) {
              edges {
                node {
                  id
                  name
                  slug
                  categories(filterBy: {isActive: true}) {
                    edges {
                      node {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      cart {
        qty
      }
      user {
        role
        isBanned
        owed
        owedReason
      }
    }
  `,
});
