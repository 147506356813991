import {graphql} from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import {ConnectionHandler} from 'relay-runtime';
import {environment} from '../environment';

const mutation = graphql`
  mutation PayMutation($input: PayInput!) {
    pay(input: $input) {
      order {
        id
        rowId
        subtotal
        shippingRate
        shipping
        taxRate
        tax
        total
        credit
        payment
        paymentType
        owed
      }
      payment
      cart {
        id
        qty
        subtotal
        total
        items(first: 10000) {
          edges {
            node {
              id
              total
              qty
              product {
                id
                brand {
                  name
                }
                name
                unit
                cost
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const PayMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    updater: (store) => {
      const viewer = store.getRoot().getLinkedRecord('viewer');

      const filter = `client:${viewer.getDataID()}:orders(filterBy:{"unpaid":true},first:100,orderBy:[["cycleId","DESC"]])`;
      const connection = store.get(filter);

      const mutationPayload = store.getRootField('pay');
      const node = mutationPayload.getLinkedRecord('order');
      if (node) {
        const newEdge = ConnectionHandler.createEdge(store, connection, node, 'OrderEdge');
        ConnectionHandler.insertEdgeAfter(connection, newEdge);
      }
    },
    variables: {
      input,
    },
  });
};
