import styled from 'styled-components';

const Container = styled.a`
  display: block;
  flex-shrink: 0;
  margin: 0px 25px;
  width: ${({isMobile}) => (isMobile ? 200 : 250)}px;
  height: ${({isMobile}) => (isMobile ? 45 : 66)}px;
  background-image: url('/next/img/logo.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 7px;
  cursor: pointer;
  text-indent: -1986em;
  overflow: hidden;
`;

const slogan = 'KC Kosher Co-op - Affordable Kosher Food';

const Logo = (props) => (
  <Container isMobile={typeof props.isMobile !== 'undefined' ? props.isMobile : false} onClick={props.onClick} title={slogan}>
    {slogan}
  </Container>
);

export default Logo;
