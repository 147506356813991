import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Search} from 'semantic-ui-react';
import styled from 'styled-components';
import {decimal} from '../../helpers';

const Container = styled.div`
  display: inline-block;
  flex-grow: 1;

  & .ui.input {
    width: 100%;
  }
  & > .ui.search .ui.input input::-webkit-input-placeholder {
    color: #757575;
  }
  & > .ui.search .ui.input input::-moz-placeholder {
    color: #757575;
  }

  & > .ui.search .ui.input input:-ms-input-placeholder {
    color: #757575;
  }
  & > .ui.search .prompt {
    background: #f5f5f5;
    border-radius: 0.28571429rem;
  }
  & > .ui.search .ui.input input:focus {
    background: #fff !important;
  }
  & > .ui.search > .results .result .image {
    display: flex;
    justify-content: center;
  }
`;

const SearchDescription = styled.div`
  & :first-child {
  }

  & > :last-child {
    color: red;
  }
`;

const ViewAll = styled.div`
  color: #2196f3;
  text-align: center;
`;

// type Props = {
//   onSearch: () => void,
// };

const cache = {};

class SearchBar extends React.Component {
  // static defaultProps = {};

  // props: Props;

  state = {
    isLoading: false,
    results: [],
    value: null,
  };

  keyDelayTimeout = null;

  searchRef = React.createRef();

  refetchVariables = {};

  static getDerivedStateFromProps(props, state) {
    if (props.match.location.pathname.indexOf('/search') > -1) {
      return {
        value: state.value !== null ? state.value : get(props.match, 'location.query.query'),
      };
    } else {
      return {
        value: state.value !== null ? state.value : null,
      };
    }
  }

  handleKeyPress = (e, ...rest) => {
    if (e.which === 13) {
      const newQuery = this.state.value || '';
      const oldQuery = get(this.props.match, 'location.query.query') || '';
      if (newQuery !== oldQuery) {
        this.searchRef.current.close();
        this.props.router.push(`/search?query=${encodeURIComponent(newQuery)}`);
      }
    }
  };

  handleSearchChange = (e, {name, value, ...rest}) => {
    this.setState({value});

    // Handle value === null
    value = value || '';

    // / Cache previously displayed values
    const now = new Date();
    const oldValue = this.state.value;
    if (!cache[oldValue]) {
      cache[oldValue] = {
        products: (get(this.props.viewer, 'products.edges') || []).map((v) => v.node),
        ttl: new Date(now.getTime() + 30 * 60 * 1000),
      };
    }

    // Check if new cache has to be destroyed
    if (cache[value] && cache[value].ttl < now) {
      cache[value] = null;
    }

    clearTimeout(this.keyDelayTimeout);

    this.keyDelayTimeout = setTimeout(() => {
      if (value.length > 1 && !cache[value]) {
        this.setState({isLoading: true});

        this.refetchVariables = {
          filterBy: {
            isActive: true,
            isAvailable: true,
            query: value,
          },
          orderBy: [['rank', 'ASC']],
        };

        this.props.relay.refetch(this.refetchVariables, null, () => this.setState({isLoading: false}));
      }
    }, 500);
  };

  handleResultSelect = (e, data) => {
    const id = get(data, 'result.id') || null;
    if (id) {
      this.props.router.push(id.substr(0, 8) === '/search?' ? id : `/products/${data.result.id}`);
    }
  };

  handleCategoryOpen = (url) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.router.push(url);
  };

  render() {
    const isLoading = this.state.isLoading;
    const value = this.state.value || '';

    let rawResults = [];
    if (value.length <= 1) {
      rawResults = [];
    } else if (cache[value]) {
      rawResults = cache[value].products;
    } else {
      rawResults = (get(this.props.viewer, 'products.edges') || []).map((v) => v.node);
    }

    const results = {
      [value]: {
        name: value,
        results: rawResults
          .filter((v) => v.fullName.trim() !== '')
          .splice(0, 5)
          .map((product) => {
            let cost = '';
            let id = '';
            let name = '';
            if (product.UNSAFE_clearanceItem) {
              cost = product.UNSAFE_clearanceItem?.cost;
              id = product.productId + '/' + product.UNSAFE_clearanceItem.id;
              name = 'ON CLEARANCE — ' + product.fullName;
            } else if (product.UNSAFE_specialItem) {
              cost = product.UNSAFE_specialItem?.cost;
              id = product.productId + '/' + product.UNSAFE_specialItem.id;
              name = 'ON SALE — ' + product.fullName;
            } else {
              cost = product.cost;
              id = product.productId;
              name = product.fullName;
            }

            return {
              description: (
                <SearchDescription>
                  <div>{product.package}</div>
                  <div>
                    {product.category && (
                      <a
                        href={`/categories/${product.category.slug}`}
                        onClick={this.handleCategoryOpen(`/categories/${product.category.slug}`)}>
                        {product.category.name}
                      </a>
                    )}
                  </div>
                </SearchDescription>
              ),
              id,
              image: get(product, 'images.edges[0].node.previewUrl') || '/next/img/default-product.png',
              price: `$${decimal(cost)}`,
              title: `${name}`,
            };
          }),
      },
    };

    const total = get(this.props.viewer, 'products.total') || 0;
    const resultsCount = results[value].results.length;

    if (resultsCount) {
      results[value].results.push({
        description: null,
        id: `/search?query=${encodeURIComponent(value)}`,
        image: null,
        price: null,
        title: (
          <ViewAll>
            View All
            {total ? ` ${total} Results` : ''}
          </ViewAll>
        ),
      });
    }

    return (
      <Container>
        <Search
          category
          className="search"
          fluid
          loading={isLoading}
          minCharacters={1}
          onKeyPress={this.handleKeyPress}
          onResultSelect={this.handleResultSelect}
          onSearchChange={this.handleSearchChange}
          placeholder="Search Kosher..."
          ref={this.searchRef}
          results={results}
          value={value}
          {...this.props}
        />
      </Container>
    );
  }
}

const SearchBarContainer = createRefetchContainer(
  withRouter(SearchBar),
  {
    viewer: graphql`
      fragment SearchBar_viewer on Viewer
      @argumentDefinitions(
        filterBy: {type: "ProductFilterInput"}
        orderBy: {type: "[[String]]"}
        page: {type: "Int"}
        limit: {type: "Int"}
      ) {
        ...ProductItem_viewer
        products(first: 10000, limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {
          edges {
            node {
              ... on Product {
                id
                productId
                cost
                unit
                rank
                fullName
                package
                images(first: 1) {
                  edges {
                    node {
                      previewUrl
                    }
                  }
                }
                category {
                  rowId
                  name
                  slug
                }
                UNSAFE_clearanceItem {
                  cost
                  id
                  qty
                  unit
                }
                UNSAFE_specialItem {
                  cost
                  id
                  unit
                }
              }
            }
          }
          total
        }
        cart {
          ...ProductItem_cart
          items(first: 10000) {
            edges {
              node {
                ...ProductItem_cartItem
                product {
                  id
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query SearchBar_Refetch_Query(
      $filterBy: ProductFilterInput
      $orderBy: [[String]] # $page: Int, $limit: Int
    ) {
      viewer {
        ...SearchBar_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy)
      }
    }
  `,
);

export default SearchBarContainer;
