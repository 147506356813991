import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {useState} from 'react';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import useInterval from '../helpers/useInterval';

export const Container = styled.div`
  width: 100%;
  padding: 1em;
  color: white;
  background: #2196f3;
  background: #455a64;
  text-align: center;
  font-size: 1em;

  @media (max-width: 640px) {
    font-size: 0.9em;
  }
`;

const Announcement = ({viewer}) => {
  const announcements = [];

  const zone = viewer?.coop?.timezone;
  const rawDeadline = viewer?.coop?.currentCycle?.deadline;
  const deadline = DateTime.fromSQL(rawDeadline && `${rawDeadline} 23:59:59 ${zone}`, {setZone: true});
  const [now, setNow] = useState(DateTime.utc().toFormat('x'));
  const diff = deadline.isValid ? (deadline.toFormat('x') - now) / 1000 : undefined;

  if (diff > 0) {
    let tmp = diff;
    const counters = {};
    counters.days = Math.floor(tmp / 24 / 60 / 60);
    tmp = tmp - counters.days * 24 * 60 * 60;
    counters.hours = Math.floor(tmp / 60 / 60);
    tmp = tmp - counters.hours * 60 * 60;
    counters.minutes = Math.floor(tmp / 60);
    tmp = tmp - counters.minutes * 60;
    counters.seconds = Math.floor(tmp);

    const countdown = ['days', 'hours', 'minutes', 'seconds'].reduce(
      (p, c, i) => p + (i === 0 ? '' : ' ') + (counters[c] < 10 ? '0' : '') + counters[c] + c[0],
      '',
    );

    announcements.push(`Deadline Countdown — ${countdown}`);
  } else if (diff < 3 * 24 * 60 * 60) {
    announcements.push(`${deadline.toFormat('MMMM')} order cycle is now closed.`);
  }

  const announcement = String((viewer.coop && viewer.coop.announcement) || '').trim();
  if (announcement.length) {
    announcements.push(announcement);
  }

  const [currentAnnouncement, setCurrentAnnouncement] = useState(0);

  useInterval(() => {
    if (diff) {
      const _now = DateTime.utc().toFormat('x');
      setNow(_now);

      if (announcements.length > 1 && _now % 15 === 0) {
        setCurrentAnnouncement(currentAnnouncement + 1 === announcements.length ? 0 : currentAnnouncement + 1);
      }
    }
  }, 1000);

  if (announcements.length === 0) {
    return null;
  }

  return <Container id="announcement">{announcements[currentAnnouncement]}</Container>;
};

export default createFragmentContainer(Announcement, {
  viewer: graphql`
    fragment Announcement_viewer on Viewer {
      coop {
        announcement
        currentCycle {
          deadline
        }
        timezone
      }
    }
  `,
});
