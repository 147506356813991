/**
 * @generated SignedSource<<336b1d4788f9c9d4b562852fdfbbf453>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendSplitRequestInput = {
  message: string;
  splitUserId: string;
  subject: string;
};
export type SendSplitRequestMutation$variables = {
  input: SendSplitRequestInput;
};
export type SendSplitRequestMutation$data = {
  readonly sendSplitRequest: {
    readonly viewer: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
};
export type SendSplitRequestMutation = {
  response: SendSplitRequestMutation$data;
  variables: SendSplitRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SendSplitRequestPayload",
    "kind": "LinkedField",
    "name": "sendSplitRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendSplitRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendSplitRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6771d60c0220a1ae9545fb810c1c4336",
    "id": null,
    "metadata": {},
    "name": "SendSplitRequestMutation",
    "operationKind": "mutation",
    "text": "mutation SendSplitRequestMutation(\n  $input: SendSplitRequestInput!\n) {\n  sendSplitRequest(input: $input) {\n    viewer {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da40d5ab89e5a3086bc3b60e65acd0d2";

export default node;
