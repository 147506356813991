/**
 * @generated SignedSource<<e0450d0604eadb20f53b8e7c018666de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PayInput = {
  amount?: string | null | undefined;
  cardCity?: string | null | undefined;
  cardCode?: string | null | undefined;
  cardDonationId?: string | null | undefined;
  cardExpiration?: string | null | undefined;
  cardFirstName?: string | null | undefined;
  cardLastName?: string | null | undefined;
  cardName?: string | null | undefined;
  cardNumber?: string | null | undefined;
  cardRemember?: boolean | null | undefined;
  cardState?: string | null | undefined;
  cardStreet?: string | null | undefined;
  cardZip?: string | null | undefined;
  checkAccount?: string | null | undefined;
  checkCity?: string | null | undefined;
  checkDonationId?: string | null | undefined;
  checkDonationNumber?: string | null | undefined;
  checkFirstName?: string | null | undefined;
  checkLastName?: string | null | undefined;
  checkNumber?: string | null | undefined;
  checkRemember?: boolean | null | undefined;
  checkRouting?: string | null | undefined;
  checkState?: string | null | undefined;
  checkStreet?: string | null | undefined;
  checkZip?: string | null | undefined;
  isCheckout?: boolean | null | undefined;
  note?: string | null | undefined;
  orderId?: string | null | undefined;
  payment?: number | null | undefined;
  paymentType: string;
};
export type PayMutation$variables = {
  input: PayInput;
};
export type PayMutation$data = {
  readonly pay: {
    readonly cart: {
      readonly id: string;
      readonly items: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly product: {
              readonly brand: {
                readonly name: string | null | undefined;
              } | null | undefined;
              readonly cost: number | null | undefined;
              readonly id: string;
              readonly images: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly url: string | null | undefined;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly name: string | null | undefined;
              readonly unit: number | null | undefined;
            } | null | undefined;
            readonly qty: number | null | undefined;
            readonly total: number | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly qty: number | null | undefined;
      readonly subtotal: number | null | undefined;
      readonly total: number | null | undefined;
    } | null | undefined;
    readonly errors: ReadonlyArray<{
      readonly field: string | null | undefined;
      readonly message: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly order: {
      readonly credit: number | null | undefined;
      readonly id: string;
      readonly owed: number | null | undefined;
      readonly payment: number | null | undefined;
      readonly paymentType: string | null | undefined;
      readonly rowId: number | null | undefined;
      readonly shipping: number | null | undefined;
      readonly shippingRate: number | null | undefined;
      readonly subtotal: number | null | undefined;
      readonly tax: number | null | undefined;
      readonly taxRate: number | null | undefined;
      readonly total: number | null | undefined;
    } | null | undefined;
    readonly payment: number | null | undefined;
  } | null | undefined;
};
export type PayMutation = {
  response: PayMutation$data;
  variables: PayMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtotal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "payment",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Order",
  "kind": "LinkedField",
  "name": "order",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipping",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tax",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "credit",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owed",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qty",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PayMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayPayload",
        "kind": "LinkedField",
        "name": "pay",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "CartItemConnection",
                "kind": "LinkedField",
                "name": "items",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartItem",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "brand",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": (v12/*: any*/),
                                "concreteType": "ImageConnection",
                                "kind": "LinkedField",
                                "name": "images",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ImageEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Image",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "images(first:1)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "items(first:10000)"
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PayMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PayPayload",
        "kind": "LinkedField",
        "name": "pay",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Cart",
            "kind": "LinkedField",
            "name": "cart",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "CartItemConnection",
                "kind": "LinkedField",
                "name": "items",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CartItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CartItem",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Brand",
                                "kind": "LinkedField",
                                "name": "brand",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": (v12/*: any*/),
                                "concreteType": "ImageConnection",
                                "kind": "LinkedField",
                                "name": "images",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ImageEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Image",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v13/*: any*/),
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "images(first:1)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "items(first:10000)"
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e44a637a11e51be5a470b85360a0bb39",
    "id": null,
    "metadata": {},
    "name": "PayMutation",
    "operationKind": "mutation",
    "text": "mutation PayMutation(\n  $input: PayInput!\n) {\n  pay(input: $input) {\n    order {\n      id\n      rowId\n      subtotal\n      shippingRate\n      shipping\n      taxRate\n      tax\n      total\n      credit\n      payment\n      paymentType\n      owed\n    }\n    payment\n    cart {\n      id\n      qty\n      subtotal\n      total\n      items(first: 10000) {\n        edges {\n          node {\n            id\n            total\n            qty\n            product {\n              id\n              brand {\n                name\n                id\n              }\n              name\n              unit\n              cost\n              images(first: 1) {\n                edges {\n                  node {\n                    url\n                    id\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e8e8c9d339f1a2f2264057bd97718291";

export default node;
