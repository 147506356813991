import {isEmpty, toArray} from '@ivosabev/helpers';
import {commitLocalUpdate} from 'react-relay';
import type {RecordProxy} from 'relay-runtime';
import {environment} from '../environment';

// TODO: This is 1:1 implementation with what was previously used
// It needs to be redone and improved !!!

let messageRelayId = -1;
const getNextMessageRelayId = () => ++messageRelayId;

export const clearMessages = () => {
  commitLocalUpdate(environment, (updater) => {
    const root = updater.getRoot();

    const store = root.getOrCreateLinkedRecord('store', 'Store');
    if (!store) return;

    const existingMessages = store.getLinkedRecords('messages') || [];

    const newMessages = existingMessages.filter(
      (existingMessage) => (existingMessage && existingMessage.getValue('persists')) || false,
    ) as RecordProxy[];

    store.setLinkedRecords(newMessages, 'messages');
  });
};

export const showBanner = (type: string, body: any, autoHide = true, details = null) => {
  if (isEmpty(details)) {
    details = null;
  }

  commitLocalUpdate(environment, (updater) => {
    const root = updater.getRoot();

    const store = root.getOrCreateLinkedRecord('store', 'Store');
    if (!store) return;

    const existingMessages: RecordProxy[] = []; // store.getLinkedRecords('messages') || [];

    const newMessages = toArray(body).map((m: any, i: number) => {
      const message = updater.create(`client:messages:${getNextMessageRelayId()}`, 'Message');

      message.setValue(type, 'type');
      message.setValue(m, 'body');
      message.setValue(details ? JSON.stringify(details) : null, 'details');
      message.setValue(!!!autoHide, 'persists');

      return message;
    });

    // HACK: Auto hide
    setTimeout(() => clearMessages(), 3000);

    store.setLinkedRecords([...existingMessages, ...newMessages], 'messages');
  });
};
