/**
 * @generated SignedSource<<1b2262d7eedc13e24bcf0fb1cbba07db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddImageInput = {
  file: any;
  productId?: string | null | undefined;
};
export type AddImageMutation$variables = {
  input: AddImageInput;
};
export type AddImageMutation$data = {
  readonly addImage: {
    readonly imageEdge: {
      readonly __typename: "ImageEdge";
      readonly node: {
        readonly id: string;
        readonly isPrimary: boolean | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AddImageMutation = {
  response: AddImageMutation$data;
  variables: AddImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddImagePayload",
    "kind": "LinkedField",
    "name": "addImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ImageEdge",
        "kind": "LinkedField",
        "name": "imageEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPrimary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddImageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "139acc201b42da1743dd7744fe749339",
    "id": null,
    "metadata": {},
    "name": "AddImageMutation",
    "operationKind": "mutation",
    "text": "mutation AddImageMutation(\n  $input: AddImageInput!\n) {\n  addImage(input: $input) {\n    imageEdge {\n      __typename\n      node {\n        id\n        isPrimary\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d30f40de4e36cfb0666a29efc4b41ff6";

export default node;
