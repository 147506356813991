import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Button, Dropdown, Form, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {states} from '../helpers/states';
import {UpdateUserMutation} from '../mutations/UpdateUserMutation';

const Container = styled.div``;

const Column = styled.div`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Heading = styled.h2``;

const PlaceOrderButton = styled(Button)`
  padding: 10px 10px !important;
  // width: 100%;
  // font-size: 19px !important;
  font-size: 19px !important;
  font-weight: normal !important;
  color: white;
  background: #43b02a;
  border: 0;
`;

function getDefaultErrors() {
  return {
    fields: {
      cityId: false,
      city: false,
      email: false,
      firstName: false,
      lastName: false,
      password: false,
      password2: false,
      phone: false,
      phone2: false,
      state: false,
      street: false,
      zip: false,
    },
    messages: [],
  };
}

class Settings extends React.Component {
  state = {
    cityId: this.props.viewer.user.cityId,
    email: this.props.viewer.user.email,
    errors: getDefaultErrors(),
    firstName: this.props.viewer.user.firstName,
    isSubmitting: false,
    city: this.props.viewer.user.address.city,
    lastName: this.props.viewer.user.lastName,
    optoutSms: this.props.viewer.user.optoutSms,
    password: '',
    password2: '',
    phone: this.props.viewer.user.phone,
    phone2: this.props.viewer.user.phone2,
    success: '',
    state: this.props.viewer.user.address.state,
    street: this.props.viewer.user.address.street,
    zip: this.props.viewer.user.address.zip,
  };

  handleFieldChange = (e, {name, type, value, ...rest}) => {
    if (name === 'optoutSms') {
      value = !rest.checked;
    } else if (type === 'checkbox') {
      value = rest.checked;
    }

    this.setState({[name]: value});
  };

  handleSubmit = () => {
    this.setState({isSubmitting: true});

    const fields = [
      'email',
      'password',
      'password2',
      'cityId',
      'firstName',
      'lastName',
      'phone',
      'phone2',
      'optoutSms',
      'street',
      'city',
      'state',
      'zip',
    ];
    const input = fields.reduce((p, c) => ({...p, [c]: this.state[c]}), {});

    // If only one of the password fields is set do not try to update the password
    if (input.password === '' || input.password2 === '') {
      input.password = '';
      input.password2 = '';
      this.setState({password: '', password2: ''});
    }

    const errors = getDefaultErrors();

    // Are the same
    const existingValues = {
      cityId: this.props.viewer.user.cityId,
      city: this.props.viewer.user.address.city,
      email: this.props.viewer.user.email,
      firstName: this.props.viewer.user.firstName,
      lastName: this.props.viewer.user.lastName,
      optoutSms: this.props.viewer.user.optoutSms,
      password: '',
      password2: '',
      phone: this.props.viewer.user.phone,
      phone2: this.props.viewer.user.phone2,
      state: this.props.viewer.user.address.state,
      street: this.props.viewer.user.address.street,
      zip: this.props.viewer.user.address.zip,
    };
    const isEqual = fields.reduce((p, c) => (p === false ? p : existingValues[c] === input[c]), true);

    if (isEqual) {
      this.setState({errors, isSubmitting: false});
      return;
    }

    input.id = this.props.viewer.id;

    // Errors
    input.password = String(input.password).trim();
    input.password2 = String(input.password2).trim();

    if (input.password === '') {
      delete input.password;
      delete input.password2;
    } else {
      if (input.password !== input.password2) {
        errors.fields.password = true;
        errors.fields.password2 = true;
        errors.messages.push('Passwords do not match.');
      }
    }

    if (errors.messages.length) {
      this.setState({errors, isSubmitting: false});
      return;
    }

    //
    const payload = {input};

    const onSuccess = (response) => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      this.setState({
        errors,
        isSubmitting: false,
        password: '',
        password2: '',
        success: 'Your account profile has been updated successfully.',
      });

      if (Number(input.cityId) !== Number(existingValues.cityId)) {
        setTimeout(() => window.location.reload(true), 1000);
      }
    };

    const onFailure = (rawErrors) => {
      const errors = getDefaultErrors();

      const validationErrors = (rawErrors && rawErrors[0] && rawErrors[0].errors) || [];
      validationErrors.forEach((validationError) => {
        errors.messages.push(validationError.message);
        errors.fields[validationError.field] = true;
      });

      if (errors.fields.password) {
        errors.fields.password2 = true;
      }

      this.setState({errors, isSubmitting: false});
    };

    UpdateUserMutation(payload, onSuccess, onFailure);
  };

  render() {
    const {isSubmitting} = this.state;
    const {viewer} = this.props;

    const pickupCities = viewer.coops.edges.map((v) => ({text: v.node.name, value: v.node.rowId}));

    return (
      <Container>
        <Form error={Boolean(this.state.errors.messages.length)} onSubmit={this.handleSubmit} success={Boolean(this.state.success.length)}>
          <Message
            content={
              <div>
                {this.state.errors.messages.map((v, i) => (
                  <p key={i}>{v}</p>
                ))}
              </div>
            }
            error
          />
          <Message content={<div>{this.state.success}</div>} success />
          <Column>
            <Inner>
              <Heading>Account Info</Heading>
              <Form.Input
                error={this.state.errors.fields.email}
                label="Email"
                name="email"
                onChange={this.handleFieldChange}
                placeholder="Email"
                required
                size="small"
                value={this.state.email}
              />
              <Form.Group widths="equal">
                <Form.Input
                  error={this.state.errors.fields.password}
                  label="Password"
                  name="password"
                  onChange={this.handleFieldChange}
                  placeholder="Password"
                  size="small"
                  type="password"
                  value={this.state.password}
                />
                <Form.Input
                  error={this.state.errors.fields.password2}
                  label="Confirm Password"
                  name="password2"
                  onChange={this.handleFieldChange}
                  placeholder="Confirm Password"
                  size="small"
                  type="password"
                  value={this.state.password2}
                />
              </Form.Group>
            </Inner>
          </Column>
          <br />
          <Column>
            <Inner>
              <Heading>Pickup City</Heading>
              <Form.Field
                control={Dropdown}
                error={this.state.errors.fields.cityId}
                name="cityId"
                onChange={this.handleFieldChange}
                options={pickupCities}
                required
                selection
                size="small"
                value={this.state.cityId}
                width={6}
              />
            </Inner>
          </Column>
          <br />
          <Column>
            <Inner>
              <Heading>Personal Info</Heading>
              <Form.Group widths="equal">
                <Form.Input
                  error={this.state.errors.fields.firstName}
                  label="First Name"
                  name="firstName"
                  onChange={this.handleFieldChange}
                  placeholder="First Name"
                  required
                  size="small"
                  value={this.state.firstName}
                />
                <Form.Input
                  error={this.state.errors.fields.lastName}
                  label="Last Name"
                  name="lastName"
                  onChange={this.handleFieldChange}
                  placeholder="Last Name"
                  required
                  size="small"
                  value={this.state.lastName}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  error={this.state.errors.fields.phone}
                  label="Main Phone"
                  name="phone"
                  onChange={this.handleFieldChange}
                  placeholder="Main Phone"
                  required
                  size="small"
                  value={this.state.phone}
                />
                <Form.Input
                  label="Mobile Phone"
                  name="phone2"
                  onChange={this.handleFieldChange}
                  placeholder="Mobile Phone"
                  size="small"
                  value={this.state.phone2}
                />
              </Form.Group>
              <Form.Checkbox
                defaultChecked={Number(this.state.optoutSms) === 0}
                label="I would like to be notified when order is ready for pick up."
                name="optoutSms"
                onChange={this.handleFieldChange}
              />
              <Form.Input
                error={this.state.errors.fields.street}
                label="Address"
                name="street"
                onChange={this.handleFieldChange}
                placeholder="Address"
                required
                size="small"
                value={this.state.street}
              />
              <Form.Group>
                <Form.Input
                  error={this.state.errors.fields.city}
                  label="City"
                  name="city"
                  onChange={this.handleFieldChange}
                  placeholder="City"
                  required
                  size="small"
                  value={this.state.city}
                  width={6}
                />
                <Form.Field
                  control={Dropdown}
                  error={this.state.errors.fields.state}
                  label="State"
                  name="state"
                  onChange={this.handleFieldChange}
                  options={states}
                  required
                  search
                  selection
                  size="small"
                  value={this.state.state}
                  width={6}
                />
                <Form.Input
                  error={this.state.errors.fields.zip}
                  label="Zip"
                  name="zip"
                  onChange={this.handleFieldChange}
                  placeholder="Zip"
                  required
                  size="small"
                  value={this.state.zip}
                  width={4}
                />
              </Form.Group>
            </Inner>
          </Column>
          <br />
          <PlaceOrderButton disabled={isSubmitting} positive size="huge">
            Update Account
          </PlaceOrderButton>
        </Form>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Settings), {
  viewer: graphql`
    fragment Settings_viewer on Viewer {
      id
      coops {
        edges {
          node {
            id
            rowId
            name
          }
        }
      }
      user {
        cityId
        email
        phone
        phone2
        firstName
        lastName
        optoutSms
        address {
          street
          city
          state
          zip
        }
      }
    }
  `,
});
