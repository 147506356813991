import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation UpdateCartItemMutation($input: UpdateCartItemInput!) {
    updateCartItem(input: $input) {
      cartItem {
        clearanceItem {
          id
          qty
        }
        product {
          id
        }
        qty
        total
      }
      cart {
        qty
        subtotal
        shipping
        shippingRate
        tax
        taxRate
        total
        credit
        owed
      }
    }
  }
`;

export const UpdateCartItemMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['id', 'qty']),
    },
  });
};
