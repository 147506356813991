import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import formatTime from '../../../helpers/formatTime';

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    & > div {
      width: 100% !important;
    }
  }

  .map {
    display: block;
    overflow: hidden;
    text-indent: -1986em;
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  & > div {
    &:first-child {
      width: 40%;
      margin-bottom: 2em;
    }

    &:last-child {
      width: 58%;
    }

    & h4:first-child {
      color: #e65100;
    }

    & h4 {
      color: #f57c00;
    }

    & img {
      width: 100%;
    }
  }
`;

const DeliveryStatus = (props) => {
  const cycle = (props && props.viewer && props.viewer.coop && props.viewer.coop.currentCycle) || null;

  const orderStatus = (cycle && cycle.deliveryStatus) || 'N/A';
  const pickupStart = cycle && cycle.pickup && cycle.pickupStart ? cycle.pickup + ' ' + cycle.pickupStart : null;
  const pickupEnd = cycle && cycle.pickup && cycle.pickupEnd ? cycle.pickup + ' ' + cycle.pickupEnd : null;
  const pickupAddress = cycle.pickupStreet + ', ' + cycle.pickupZip;

  return (
    <Container>
      <div>
        <h4>Order Status</h4>
        <p>{orderStatus}</p>
        <h4>Delivery Date</h4>
        <p>
          {DateTime.fromSQL(pickupStart).toFormat('LLL d')}, {formatTime(pickupStart, pickupEnd)}
        </p>
        <h4>Delivery Location</h4>
        <p>{pickupAddress}</p>
        <h4> Directions to Delivery Location</h4>
        <p style={{whiteSpace: 'pre-wrap'}}>{cycle.directions}</p>
      </div>
      <div>
        <a
          className="map"
          href={`https://www.google.com/maps/place/${encodeURIComponent(pickupAddress)}`}
          rel="noopener noreferrer"
          style={{
            backgroundImage: `url(http://maps.google.com/maps/api/staticmap?center=${encodeURIComponent(
              pickupAddress,
            )}&zoom=15&size=1000x1000&maptype=roadmap&markers=color:blue|label:|${encodeURIComponent(
              pickupAddress,
            )}&sensor=false&key=AIzaSyCN--UXDNPK2xsuMsgA7CRh_Y09OpEabuk)`,
          }}
          target="_blank">
          {pickupAddress}
        </a>
      </div>
    </Container>
  );
};

export default createFragmentContainer(DeliveryStatus, {
  viewer: graphql`
    fragment DeliveryStatus_viewer on Viewer {
      coop {
        currentCycle {
          pickup
          pickupStart
          pickupEnd
          pickupStreet
          pickupZip
          deliveryStatus
          directions
        }
        nextCycle {
          pickup
          pickupStart
          pickupStreet
          pickupZip
        }
      }
      user {
        role
      }
    }
  `,
});
