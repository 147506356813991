import {money, percentage} from '@ivosabev/helpers';
import {List} from 'semantic-ui-react';

const Totals = ({
  cart = 0,
  credit = 0,
  discount = 0,
  order = 0,
  owed = 0,
  payment = 0,
  paymentType = null,
  refund = 0,
  shipping = 0,
  shippingRate = 0,
  subtotal = 0,
  surcharge = 0,
  surchargeRate = 0,
  tax = 0,
  taxRate = 0,
  total = 0,
} = {}) => (
  <List>
    {order > 0 && (
      <List.Item>
        <List.Content floated="right">{money(order)}</List.Content> Existing Order
      </List.Item>
    )}
    {cart > 0 && (
      <List.Item>
        <List.Content floated="right">{money(cart)}</List.Content> Cart
      </List.Item>
    )}
    {order > 0 && cart > 0 && <hr />}
    {subtotal > 0 && (
      <List.Item style={order > 0 && cart > 0 ? {fontWeight: 'bold'} : {}}>
        <List.Content floated="right">{money(subtotal)}</List.Content> Subtotal
      </List.Item>
    )}
    {discount > 0 && (
      <List.Item>
        <List.Content floated="right">-{money(discount)}</List.Content> Discount
      </List.Item>
    )}
    {shipping > 0 && (
      <List.Item title={`Subtotal x Delivery Rate = ${money(subtotal)} x ${percentage(shippingRate / 100, 2)} = ${money(shipping)}`}>
        <List.Content floated="right">{shippingRate ? money(shipping) : 'Free'}</List.Content>
        Delivery {shippingRate > 0 && `(${percentage(shippingRate / 100, 2)})`}
      </List.Item>
    )}
    {tax > 0 && (
      <List.Item
        title={`(Subtotal - Discount) x Tax Rate = (${money(subtotal)} - ${money(discount)}) x ${percentage(taxRate / 100, 2)} = ${money(
          tax,
        )}`}>
        <List.Content floated="right">{money(tax)}</List.Content> Tax ({percentage(taxRate / 100, 2)})
      </List.Item>
    )}
    {surchargeRate > 0 && (
      <List.Item
        title={`(Subtotal - Discount + Delivery + Tax) x Surcharge = (${money(subtotal)} - ${money(discount)} + ${money(
          shipping,
        )} + ${money(tax)}) x ${percentage(surchargeRate / 100)} = ${money(surcharge)}`}>
        <List.Content floated="right">{money(surcharge)}</List.Content> Surcharge ({percentage(surchargeRate / 100)})
      </List.Item>
    )}
    <hr />
    <List.Item style={{fontWeight: 'bold'}}>
      <List.Content floated="right">{money(total)}</List.Content> Total
    </List.Item>
    {credit > 0 && (
      <List.Item>
        <List.Content floated="right">-{money(credit)}</List.Content> Credit
      </List.Item>
    )}
    {payment > 0 && (
      <List.Item>
        <List.Content floated="right">-{money(payment)}</List.Content> Payment {paymentType && `(${paymentType.toUpperCase()})`}
      </List.Item>
    )}
    <hr />
    {/*<List.Item><List.Content floated='right'>{money(cart.subtotal)}</List.Content>
                      Previous Balance</List.Item>*/}
    <List.Item style={{fontWeight: 'bold'}}>
      <List.Content floated="right">{money(owed)}</List.Content> Owed
    </List.Item>
    {refund > 0 && (
      <List.Item style={{fontStyle: 'italic'}}>
        <List.Content floated="right">{money(refund)}</List.Content> Refund
      </List.Item>
    )}
  </List>
);

export default Totals;
