import BrowserProtocol from 'farce/BrowserProtocol';
import queryMiddleware from 'farce/queryMiddleware';
import createFarceRouter from 'found/createFarceRouter';
import createRender from 'found/createRender';
import {Resolver} from 'found-relay';
import {ScrollManager} from 'found-scroll';
import {createRoot} from 'react-dom/client';
import 'jquery';
import 'react-app-polyfill/ie9';
import './config';
import environment from './environment';
// import registerServiceWorker from './registerServiceWorker';
import './index.css';
import routes from './routes';

// Hack: Patch pushState listener
((history) => {
  const pushState = history.pushState;
  history.pushState = function (state) {
    if (typeof history.onpushstate === 'function') {
      history.onpushstate({state: state});
    }
    return pushState.apply(history, arguments);
  };
})(window.history);

const Router = createFarceRouter({
  historyMiddlewares: [queryMiddleware],
  historyProtocol: new BrowserProtocol(),
  render: (renderArgs) => (
    <ScrollManager renderArgs={renderArgs} shouldUpdateScroll={() => false}>
      {createRender({})(renderArgs)}
    </ScrollManager>
  ),
  routeConfig: routes,
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Router resolver={new Resolver(environment)} />);

// registerServiceWorker();
