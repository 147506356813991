import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation ContactMutation($input: ContactInput!) {
    contact(input: $input) {
      _empty
    }
  }
`;

export const ContactMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    // 	configs: [{
    // 		type: 'REQUIRED_CHILDREN',
    // 		children: [
    // 			graphql`
    // 				fragment LoginMutationAccessToken on LoginPayload {
    // 					accessToken
    // 				}
    // 			`,
    // 		],
    // 	}, {
    // 		type: 'FIELDS_CHANGE',
    // 		fieldIDs: {
    // 			user: input.user.id,
    // 		},
    // 	}],
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),

    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['email', 'name', 'phone', 'message', 'captcha', 'referer']),
    },
  });
};
