import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import copyToClipboard from 'copy-to-clipboard';
import {Link, withRouter} from 'found';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Breadcrumb, Button, Divider, Form, Grid, Label, Message, Modal} from 'semantic-ui-react';
import styled from 'styled-components';
import {decimal, go, withWindowSize} from '../helpers';
import fromGlobalId from '../helpers/fromGlobalId';
import getCartItemForProduct from '../helpers/getCartItemForProduct';
import {AddAnswerMutation} from '../mutations/AddAnswerMutation';
import {AddCartItemMutation} from '../mutations/AddCartItemMutation';
import {AddCommentMutation} from '../mutations/AddCommentMutation';
import {AddImageMutation} from '../mutations/AddImageMutation';
import {AddQuestionMutation} from '../mutations/AddQuestionMutation';
import {AddSplitRequestMutation} from '../mutations/AddSplitRequestMutation';
import {DeleteCartItemMutation} from '../mutations/DeleteCartItemMutation';
import {DeleteCommentMutation} from '../mutations/DeleteCommentMutation';
import {DeleteImageMutation} from '../mutations/DeleteImageMutation';
import {UpdateCartItemMutation} from '../mutations/UpdateCartItemMutation';
import {UpdateImageMutation} from '../mutations/UpdateImageMutation';
import {CustomIcon} from './CustomIcon/CustomIcon';
import ProductImage from './Products/ProductImage';
import StoreCategories from './Store/StoreCategories';

const Container = styled.div`
  // box-sizing: border-box;
  // margin: 0 auto;
  // padding: 4rem 2rem;

  display: flex;
  background: #fff;

  & > div:last-child {
    width: 100%;

    & > div:last-child {
      padding: 2rem;
      max-width: 1360px;
    }
  }
`;

const ProductFilters = styled.div`
  display: flex;
  padding: 14px 25px 13px 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5edec;
  & .ui.dropdown .menu {
    left: -100px;
  }
`;

const ProductImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const QuestionsList = styled.div`
  margin: 30px 0 20px 0;
  padding: 0 0 10px 0;
`;

const QuestionsItem = styled.div`
  padding: 10px;
  & > p {
    margin-bottom: 0.5em;
  }
`;

const AnswersItem = styled.div`
  padding: 10px 0 0 20px;
`;

const CommentsList = styled.div``;

const CommentsItem = styled.div`
  padding: 10px;
  & > p {
    margin-bottom: 0.25em;
  }
`;

const ImagesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  & button {
    margin: 10px;
  }
`;

const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
  padding: 10px;
  max-width: 150px;
  max-height: 150px;
  text-align: center;
  & > img {
    height: 130px;
  }
  & > div {
    display: flex;
  }
  & a {
    flex: 1;
    padding: 5px;
    text-align: center;
  }
`;

const Modal2 = styled(Modal)`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  z-index: 9000;
`;

const Name2 = styled.div`
  color: #212121;
  font-size: 21px;

  & > em {
    color: rgb(33, 150, 243);
  }
`;

const Size2 = styled.div`
  color: #9e9e9e;
  font-size: 17px;
`;

const Category = styled.div`
  margin: 0 0 1em 0;

  & a {
    font-size: 0.9em;
  }

  & a + a:before {
    color: #ccc;
    content: ' › ';
  }
`;

const Price2 = styled.div`
  margin: 1em 0 0 0;
  color: #212121;
  font-size: 31px;
  font-weight: 400;
  & > span {
    color: #9e9e9e;
    font-size: 15px;
    font-weight: normal;
  }
  & .icon {
    color: #2196f3;
  }
`;

const Availability = styled.div`
  color: #9e9e9e;
  font-size: 13px;
  font-weight: 300;

  & > span {
    color: #ff9800;

    &.new {
      color: #0275d8;
    }

    &.varies,
    &.medium {
      color: #ff9100;
    }

    &.low {
      color: #d73131;
    }

    &.high,
    &.excellent {
      color: #008a00;
    }

    &.out-of-stock {
      background: #e53e3e;
      color: white;
      padding: 4px 6px;
      border-radius: 3px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  margin: 2em 0 0 0 !important;

  & .ui.button {
    flex: 1;
    font-size: 19px;
  }
`;

const IncreaseButton = styled(Button)``;

const DecreaseButton = styled(Button)`
  max-width: 4.4rem;
`;

const Subnote = styled.div`
  margin: 5px 0 0 0;
  color: #9e9e9e;
  font-size: 15px;
  font-weight: 300;
  text-align: center;
`;

const Subactions = styled.div`
  & > a {
    display: block;
    margin: 5px 0;
  }
`;

const Copied = styled.div`
  color: #008a00;
  font-style: oblique;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const DEFAULT_STATE = {
  comment: '',
  isAnswersDialogOpen: false,
  isAnswersFormSubmitting: false,
  answers: '',
  isCommentsDialogOpen: false,
  isCommentsFormSubmitting: false,
  isImageDialogOpen: false,
  isQuestionsDialogOpen: false,
  isQuestionsFormSubmitting: false,
  productIdCopiedToClipboardTimeout: null,
  question: '',
  questionContent: '',
  rating: 5,
  questionId: null,
};

const getCommentAuthorName = (author) =>
  author.split(' ').reduce((p, c, i, a) => p + (a.length > 1 ? (i === a.length - 1 ? c[0] + '.' : c + ' ') : c), '');

class Product extends React.Component {
  state = {
    ...DEFAULT_STATE,
    breadcrumbs: [],
  };

  constructor(props) {
    super(props);

    if (window.location.hash.indexOf('#question/') > -1) {
      const questions = (get(this.props, 'viewer.product.questions.edges') || []).map((v) => v.node).filter(Boolean);
      const questionId = Number(window.location.hash.replace('#question/', ''));
      const question = questionId ? questions.find((q) => Number(q.rowId) === questionId) : null;
      if (question) {
        this.state.isAnswersDialogOpen = true;
        this.state.questionId = question.id;
        this.state.questionContent = question.content;
        this.state.answer = DEFAULT_STATE['answer'];
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    let breadcrumbs = [];

    const slug = get(props.viewer, 'product.category.slug') || '';
    const [categorySlug, subcategorySlug, subsubcategorySlug] = slug.split('/');

    let category;
    let subcategory;
    let subsubcategory;

    breadcrumbs.push({
      content: 'Categories',
      key: 'categories',
      link: true,
      onClick: '',
    });

    if (categorySlug === 'clearance') {
      breadcrumbs.push({
        active: true,
        content: 'Clearance',
        key: 'clearance',
        link: false,
        onClick: 'clearance',
      });
    } else if (categorySlug === 'specials') {
      breadcrumbs.push({
        active: true,
        content: 'Specials',
        key: 'specials',
        link: false,
        onClick: 'specials',
      });
    } else if (categorySlug === 'new') {
      breadcrumbs.push({
        active: true,
        content: 'New Products',
        key: 'new',
        link: false,
        onClick: 'new',
      });
    } else {
      const categories = (get(props.viewer, 'categories.edges') || []).map((v) => v.node);
      category = categories.find((c) => c.slug === categorySlug);
      if (category) {
        breadcrumbs.push({
          active: subcategorySlug ? false : true,
          content: category.name,
          key: category.slug,
          link: true,
          onClick: category.slug,
        });
      }
    }

    if (subcategorySlug === 'top') {
      breadcrumbs.push({
        active: true,
        content: 'Top Products',
        key: categorySlug + '/top',
        onClick: categorySlug + '/top',
      });
    } else {
      const subcategories = (get(category, 'categories.edges') || []).map((v) => v.node);
      subcategory = subcategories.find((c) => c.slug === categorySlug + '/' + subcategorySlug);
      if (subcategory) {
        breadcrumbs[1].active = false;
        breadcrumbs.push({
          active: subsubcategorySlug ? false : true,
          content: subcategory.name,
          key: subcategory.slug,
          onClick: subcategory.slug,
        });

        if (subsubcategorySlug === 'top') {
          breadcrumbs.push({
            active: true,
            content: 'Top Products',
            key: categorySlug + '/top',
            onClick: categorySlug + '/top',
          });
        } else {
          const subsubcategories = (get(subcategory, 'categories.edges') || []).map((v) => v.node);
          subsubcategory = subsubcategories.find((c) => c.slug === categorySlug + '/' + subcategorySlug + '/' + subsubcategorySlug);
          if (subsubcategory) {
            breadcrumbs[1].active = false;
            breadcrumbs.push({
              active: true,
              content: subsubcategory.name,
              key: subsubcategory.slug,
              onClick: subsubcategory.slug,
            });
          }
        }
      }
    }

    return {
      breadcrumbs,
    };
  }

  handleCopyProductId = () => {
    const productId = get(this.props, 'viewer.product.productId') || null;

    if (!productId) {
      return console.error('No product to copy');
    }

    const {productIdCopiedToClipboardTimeout} = this.state;
    copyToClipboard(productId);

    if (productIdCopiedToClipboardTimeout) {
      window.clearTimeout(productIdCopiedToClipboardTimeout);
    }

    const timeoutId = window.setTimeout(() => this.setState({productIdCopiedToClipboardTimeout: null}), 3000);

    this.setState({productIdCopiedToClipboardTimeout: timeoutId});
  };

  handleFieldChange = (e, {name, type, value, ...rest}) => {
    if (type === 'checkbox') {
      value = rest.checked;
    }

    this.setState({[name]: value});
  };

  handleCommentsDialogOpen = (e) => {
    e.preventDefault();
    this.setState({
      comment: DEFAULT_STATE['comment'],
      isCommentsDialogOpen: true,
      rating: DEFAULT_STATE['rating'],
    });
  };

  handleCommentsDialogClose = (e) => {
    e.preventDefault();
    this.setState({isCommentsDialogOpen: false});
  };

  handleQuestionsDialogOpen = (e) => {
    e.preventDefault();
    this.setState({
      isQuestionsDialogOpen: true,
      question: DEFAULT_STATE['question'],
    });
  };

  handleQuestionsDialogClose = (e) => {
    e.preventDefault();
    this.setState({isQuestionsDialogOpen: false});
  };

  handleAnswersDialogOpen = (question) => (e) => {
    // e.preventDefault();
    this.setState({
      answer: DEFAULT_STATE['answer'],
      isAnswersDialogOpen: true,
      questionContent: question.content,
      questionId: question.id,
    });
  };

  handleAnswersDialogClose = (e) => {
    e.preventDefault();
    window.history.pushState({}, '', window.location.origin + window.location.pathname);
    this.setState({isAnswersDialogOpen: false});
  };

  getCartItem() {
    const {cart, clearanceItem, product, specialItem} = this.props.viewer;
    const cartItems = (cart?.items?.edges || []).map((v) => v.node);
    const cartItem = getCartItemForProduct(cartItems, product.id, {clearanceItemId: clearanceItem?.id, specialItemId: specialItem?.id});
    return cartItem;
  }

  handleIncrease = () => (e) => {
    e.stopPropagation();

    const role = get(this.props, 'viewer.user.role') || 'guest';
    if (role === 'guest') {
      return this.props.router.push(`/login?redirect=${window.location.href}`);
    }

    const {cart, clearanceItem, product, specialItem} = this.props.viewer;
    const cartItem = this.getCartItem();

    const onSuccess = () => {};
    const onFailure = () => {};

    if (cartItem && cartItem.qty > 0) {
      const payload = {
        input: {
          id: cartItem.id,
          qty: cartItem.qty + 1,
        },
      };

      UpdateCartItemMutation(payload, onSuccess, onFailure);
    } else {
      // Availability warnings
      if (product.availabilityStatus === 'New') {
        if (
          !window.confirm(
            // eslint-disable-next-line max-len
            'This is a product SKU we have just started to get within the past three months so we do not have enough data yet to show the fulfillment percentage of this product. Typically new products have over 90% fulfillment',
          )
        ) {
          return;
        }
      } else if (product.availabilityStatus === 'Varies') {
        if (
          !window.confirm(
            // eslint-disable-next-line max-len
            'This product is not often ordered making fulfillment lower than other products. We are pointing this out to try to give our customers higher visibility into which products we have more trouble getting our suppliers to fulfill. We continue to list the product because our fulfillment on uncommon products is still over 60%',
          )
        ) {
          return;
        }
      }

      const payload = {
        input: {
          clearanceItemId: clearanceItem?.id,
          productId: product.productId,
          qty: 1,
          specialItemId: specialItem?.id,
          userId: cart.id,
        },
      };
      const onSuccess = () => {};
      const onFailure = () => {};

      AddCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  handleDecrease = () => (e) => {
    e.stopPropagation();

    const cartItem = this.getCartItem();

    const onSuccess = () => {};
    const onFailure = () => {};

    if (cartItem.qty > 1) {
      const payload = {
        input: {
          id: cartItem.id,
          qty: cartItem.qty - 1,
        },
      };

      UpdateCartItemMutation(payload, onSuccess, onFailure);
    } else {
      const payload = {
        input: {
          id: cartItem.id,
        },
      };

      DeleteCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  handleSplitRequest = (e) => {
    e.preventDefault();

    const {clearanceItem, id, product, specialItem} = this.props.viewer;

    const payload = {
      input: {
        clearanceItemId: clearanceItem?.id,
        productId: product.id,
        specialItemId: specialItem?.id,
        viewerId: id || null,
      },
    };
    const onSuccess = (response) => this.props.router.push(`/splits`);
    const onFailure = () => {};

    AddSplitRequestMutation(payload, onSuccess, onFailure);
  };

  handleAddComment = (e) => {
    e.preventDefault();

    if (String(this.state.comment).length === 0) {
      return alert('You cannot submit an empty Review.');
    }

    this.setState({isCommentsFormSubmitting: true});

    const payload = {
      input: {
        comment: this.state.comment,
        productId: get(this, 'props.viewer.product.id') || null,
        rating: this.state.rating,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        ...DEFAULT_STATE,
      });
    };

    const onFailure = () => {
      this.setState({
        ...DEFAULT_STATE,
      });
    };

    AddCommentMutation(payload, onSuccess, onFailure);
  };

  handleDeleteComment = (commentId) => (e) => {
    e.preventDefault();

    if (!window.confirm('Are you sure?')) {
      return;
    }

    const payload = {
      input: {
        commentId,
      },
    };

    const onSuccess = (response) => this.setState({...DEFAULT_STATE});
    const onFailure = () => this.setState({...DEFAULT_STATE});

    DeleteCommentMutation(payload, onSuccess, onFailure);
  };

  handleAddQuestion = (e) => {
    e.preventDefault();

    const payload = {
      input: {
        content: this.state.question,
        productId: get(this, 'props.viewer.product.id') || null,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        ...DEFAULT_STATE,
      });
    };

    const onFailure = () => {
      this.setState({
        ...DEFAULT_STATE,
      });
    };

    AddQuestionMutation(payload, onSuccess, onFailure);
  };

  handleAddAnswer = (e) => {
    e.preventDefault();

    this.setState({isAnswersFormSubmitting: true});

    const payload = {
      input: {
        content: this.state.answer,
        questionId: this.state.questionId,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        ...DEFAULT_STATE,
      });
    };

    const onFailure = () => {
      this.setState({
        ...DEFAULT_STATE,
      });
    };

    AddAnswerMutation(payload, onSuccess, onFailure);
  };

  handleImageOut = (e) => {
    e.preventDefault();
    // const images = (get(this.props, 'viewer.product.images.edges') || []).map(v => v.node).filter(Boolean);
    // const image = images.length ? images[0].url : null;
    // this.setState({image});
  };

  handleImageOver = (image) => (e) => {
    e.preventDefault();
    this.setState({image});
  };

  handleAddImageOpen = (e) => {
    e.preventDefault();
    this.file.click();
  };

  handleAddImage = (e) => {
    e.preventDefault();

    const input = {
      file: e.target.files[0],
      productId: get(this.props, 'viewer.product.id') || null,
    };

    const onSuccess = (response) => {};

    const onFailure = () => {};

    AddImageMutation(input, onSuccess, onFailure);
  };

  handleCategoryClick = (url) => (e) => {
    this.props.onClose(e, false);
    this.props.router.push(url);
  };

  handleSetUnsetThumb = (id, isPrimary) => (e) => {
    e.preventDefault();

    const payload = {input: {id, isPrimary}};
    const onSuccess = () => {};
    const onFailure = () => {};

    UpdateImageMutation(payload, onSuccess, onFailure);
  };

  handleDeleteImage = (imageId) => (e) => {
    e.preventDefault();

    if (!window.confirm('Are you sure?')) {
      return;
    }

    const payload = {
      input: {
        imageId,
      },
    };

    const onSuccess = (response) => {};

    const onFailure = () => {};

    DeleteImageMutation(payload, onSuccess, onFailure);
  };

  renderCommentsDialog = () => {
    if (!this.state.isCommentsDialogOpen) {
      return null;
    }

    return (
      <Modal onClose={this.handleCommentsDialogClose} open={true} size="small">
        <Modal.Header>Write a Review</Modal.Header>
        <Modal.Content>
          <p>
            Your experience with this product is the most valuable contribution to member's trying to order it! If you have a good recipe
            (or how to prepare) related to this product, that is also greatly appreciated by the Co-op community.
          </p>
          <p>
            Please keep your review objective and clean and note that your name will be published with your review once approved by the
            administrator.
          </p>
          <Form>
            <Form.Dropdown
              label="Product Rating"
              name="rating"
              onChange={this.handleFieldChange}
              options={[
                {text: '5 Stars', value: 5},
                {text: '4 Stars', value: 4},
                {text: '3 Stars', value: 3},
                {text: '2 Stars', value: 2},
                {text: '1 Stars', value: 1},
              ]}
              selection
              value={this.state.rating}
              width={4}
            />
            <Form.TextArea label="Comment" name="comment" onChange={this.handleFieldChange} rows={10} value={this.state.comment} />
            <Button disabled={this.state.isCommentsFormSubmitting} onClick={this.handleAddComment} primary>
              Post Review
            </Button>{' '}
            <a href="#void" onClick={this.handleCommentsDialogClose}>
              Cancel
            </a>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  renderQuestionsDialog = () => {
    if (!this.state.isQuestionsDialogOpen) {
      return null;
    }

    return (
      <Modal onClose={this.handleQuestionsDialogClose} open={true} size="small">
        <Modal.Header>Ask a Question</Modal.Header>
        <Modal.Content>
          <p>Please provide enough detail for other Co-op Members to effectively answer your question.</p>
          <Form>
            <Form.TextArea name="question" onChange={this.handleFieldChange} rows={10} value={this.state.question} />
            <Button disabled={this.state.isQuestionsFormSubmitting} onClick={this.handleAddQuestion} primary>
              Ask Question
            </Button>{' '}
            <a href="#void" onClick={this.handleQuestionsDialogClose}>
              Cancel
            </a>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  renderAnswersDialog = () => {
    if (!this.state.isAnswersDialogOpen) {
      return null;
    }

    return (
      <Modal onClose={this.handleAnswersDialogClose} open={true} size="small">
        <Modal.Header>Submit an Answer</Modal.Header>
        <Modal.Content>
          <p>
            Your answer is greatly appreciated! Please keep your response objective and clean and note the response will not show until
            approved by the administrator.
          </p>
          <p>
            <strong>Question:</strong> {this.state.questionContent}
          </p>
          <Form>
            <Form.TextArea name="answer" onChange={this.handleFieldChange} rows={10} value={this.state.answer} />
            <Button disabled={this.state.isAnswersFormSubmitting} onClick={this.handleAddAnswer} primary>
              Submit Answer
            </Button>{' '}
            <a href="#void" onClick={this.handleAnswersDialogClose}>
              Cancel
            </a>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  renderImageDialog() {
    if (!this.state.isImageDialogOpen) {
      return null;
    }

    return (
      <Modal onClose={this.handleImageDialogClose} open={true} size="small">
        <Modal.Header>Submit an Answer</Modal.Header>
        <Modal.Content>
          KOR
          <Button disabled={this.state.isAnswersFormSubmitting} onClick={this.handleAddAnswer} primary>
            Submit Answer
          </Button>{' '}
          <a href="#void" onClick={this.handleImageDialogClose}>
            Cancel
          </a>
        </Modal.Content>
      </Modal>
    );
  }

  renderProduct() {
    const {router, viewer} = this.props;
    const {clearanceItem, product, specialItem, user} = viewer;

    const comments = (get(this.props, 'viewer.product.comments.edges') || []).map((v) => v.node).filter(Boolean);
    const images = (get(this.props, 'viewer.product.images.edges') || []).map((v) => v.node).filter(Boolean);
    const questions = (get(this.props, 'viewer.product.questions.edges') || []).map((v) => v.node).filter(Boolean);

    const coverImage = this.state.image || (images.length ? images[0].url : null);

    const cartItem = this.getCartItem();
    const hasCartItemQty = get(cartItem, 'qty') > 0;

    const availabilityStatus = product.availabilityStatus;

    const isMobile = this.props.isMobile;

    const canAddToCart = product.canAddToCart || clearanceItem;

    const cost = clearanceItem?.cost || specialItem?.cost || product.cost;
    const unit = clearanceItem?.unit || specialItem?.unit || product.unit;

    return (
      <React.Fragment>
        {this.renderCommentsDialog()}
        {this.renderQuestionsDialog()}
        {this.renderAnswersDialog()}

        {/* <Breadcrumb>
            <Breadcrumb.Section link href="/gooogle">
              Baked Goods
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link>Breadsticks &amp; Croutons</Breadcrumb.Section>
          </Breadcrumb> */}

        {!clearanceItem && (!product.isActive || !product.isAvailable) && (
          <Message negative>
            <Message.Header>Unavailable Product</Message.Header>
            <p>
              You have accessed a product that is currently inactive, probably by using a direct link. While you can order this product,
              please be aware that it is likely unavailable and the price may be out of date.
            </p>
          </Message>
        )}

        <Grid divided="vertically" stackable>
          <Grid.Row columns={2}>
            <Grid.Column width={10}>
              <ProductImageContainer>
                <ProductImage size={isMobile ? 200 : 500} src={coverImage} />
              </ProductImageContainer>
            </Grid.Column>
            <Grid.Column width={6}>
              {this.props.onClose && product.category && (
                <Category>
                  {product.categories
                    .map((c) => (
                      <a href={`/categories/${c.slug}`} key={c.rowId} onClick={this.handleCategoryClick(`/categories/${c.slug}`)}>
                        {c.name}
                      </a>
                    ))
                    .reverse()}
                </Category>
              )}
              <Name2 {...(user.isEditor ? {onClick: this.handleCopyProductId, style: {cursor: 'copy'}} : {})}>
                {clearanceItem && (
                  <Label size="medium" style={{background: '#eb5f00', color: 'white', marginRight: 6, position: 'relative', top: -4}}>
                    CLEARANCE
                  </Label>
                )}
                {specialItem && (
                  <Label size="medium" style={{background: '#ff9800', color: 'white', marginRight: 6, position: 'relative', top: -4}}>
                    ON SALE
                  </Label>
                )}
                {product.state === 'Frozen' && <em>Frozen </em>}
                {product.fullName}
              </Name2>
              <Size2>{product.package}</Size2>
              <Copied visible={this.state.productIdCopiedToClipboardTimeout}>Copied!</Copied>
              <Price2>
                ${decimal(cost)}{' '}
                <span>
                  ($
                  {decimal(unit)} / {!product.isVariable && product.size + ' '}
                  {product.sizeUnit}){' '}
                  {product.isVariable && (
                    <>
                      &nbsp;
                      <CustomIcon
                        name="far-balance-scale"
                        onClick={go('/help#q1-6', {router})}
                        style={{color: '#2196f3', cursor: 'pointer'}}
                        title="Variable Weight Product"
                      />
                    </>
                  )}
                </span>
              </Price2>
              <Availability>
                Availability: <span className={kebabCase(availabilityStatus.toLowerCase())}>{availabilityStatus}</span>
              </Availability>
              {clearanceItem && <Availability>Clearance: {clearanceItem.qty} left</Availability>}

              {canAddToCart && (
                <div>
                  <Actions>
                    <IncreaseButton onClick={this.handleIncrease()} primary>
                      Add to Cart
                    </IncreaseButton>
                    {hasCartItemQty && (
                      <DecreaseButton onClick={this.handleDecrease()}>
                        <CustomIcon name="fas-minus" />
                      </DecreaseButton>
                    )}
                  </Actions>
                  {hasCartItemQty && (
                    <Subnote>
                      You have <span style={{color: '#4183c4'}}>{cartItem.qty}</span> in your Cart
                    </Subnote>
                  )}
                  {/* <Subnote>* Includes free shipping to delivery location</Subnote> */}
                </div>
              )}

              <Divider style={{marginBottom: 20, marginTop: 20}} />

              {user.role === 'guest' ? (
                <Subactions>
                  {product.canAddToCart && (
                    <Link to={`/login?redirect=/products/${product.id}/${product.productId}`}>
                      <CustomIcon name="fas-tasks" /> Log in to Split with someone
                    </Link>
                  )}
                  <Link to={`/login?redirect=/products/${product.id}/${product.productId}`}>
                    <CustomIcon name="fas-star" /> Log in to Write a review
                  </Link>
                  <Link to={`/login?redirect=/products/${product.id}/${product.productId}`}>
                    <CustomIcon name="fas-question" /> Log in to Ask a question
                  </Link>
                </Subactions>
              ) : (
                <Subactions>
                  {product.canAddToCart && (
                    <a href="#void" onClick={this.handleSplitRequest}>
                      <CustomIcon name="fas-tasks" /> Split with someone
                    </a>
                  )}
                  <a href="#void" onClick={this.handleCommentsDialogOpen}>
                    <CustomIcon name="fas-star" /> Write a review
                  </a>
                  <a href="#void" onClick={this.handleQuestionsDialogOpen}>
                    <CustomIcon name="fas-question" /> Ask a question
                  </a>
                </Subactions>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {(images.length > 0 || user.isEditor) && (
          <ImagesList>
            {images.map((image) => (
              <ImageItem key={image.id}>
                <img
                  alt={product.fullName}
                  onMouseOut={this.handleImageOut}
                  onMouseOver={this.handleImageOver(image.url)}
                  src={image.url.replace('.jpg', '_small.jpg').replace('-raw', '-images')}
                  style={{cursor: 'pointer'}}
                />
                {user.isEditor && (
                  <div>
                    {image.isPrimary && (
                      <a href="#void" onClick={this.handleSetUnsetThumb(image.id, false)}>
                        Regular
                      </a>
                    )}
                    {!image.isPrimary && (
                      <a href="#void" onClick={this.handleSetUnsetThumb(image.id, true)}>
                        Primary
                      </a>
                    )}
                    <a href="#void" onClick={this.handleDeleteImage(image.id)}>
                      Delete
                    </a>
                  </div>
                )}
              </ImageItem>
            ))}
            {user.role !== 'guest' && (
              <input
                name="addImage"
                onChange={this.handleAddImage}
                ref={(file) => (this.file = file)}
                style={{display: 'none'}}
                type="file"
              />
            )}
            {user.role !== 'guest' && (
              <Button basic color="blue" onClick={this.handleAddImageOpen} size="large">
                Add Image
              </Button>
            )}
          </ImagesList>
        )}

        <QuestionsList>
          <h5>Questions &amp; Answers</h5>
          {questions.map((question) => (
            <QuestionsItem key={question.id}>
              <strong>Question:</strong> {question.content}
              {(get(question, 'answers.edges') || [])
                .map((v) => v.node)
                .filter(Boolean)
                .map((answer) => (
                  <AnswersItem key={answer.id}>
                    <strong>Answer:</strong> {answer.content}
                  </AnswersItem>
                ))}
              <AnswersItem key="0">
                <a href={`#question/${question.rowId}`} onClick={this.handleAnswersDialogOpen(question)}>
                  Submit an answer
                </a>
              </AnswersItem>
            </QuestionsItem>
          ))}
        </QuestionsList>
        <CommentsList>
          <h5>Customer Reviews</h5>
          {comments.length === 0 && (
            <p>
              <strong>No one posted any review yet.</strong> Why not say a Shehechiyanu and be the first!
            </p>
          )}
          {comments.map((comment) => (
            <CommentsItem key={comment.id}>
              <p>{comment.comment}</p>
              <p style={{color: '#666'}}>
                <img
                  alt={`${comment.rating} Star Rating`}
                  src={`/next/stars/${comment.rating}.gif`}
                  style={{position: 'relative', top: 5}}
                />
                Posted by <em>{getCommentAuthorName(comment.name)}</em> on{' '}
                {DateTime.fromISO(comment.createdAt).toLocaleString(DateTime.DATE_MED)}
              </p>
              {/* {user.isEditor && <p><a href="#" onClick={this.handleDeleteComment(comment.id)}>Delete</a></p>} */}
            </CommentsItem>
          ))}
        </CommentsList>
      </React.Fragment>
    );
  }

  renderModal() {
    return (
      <Modal2 closeIcon="close" id="product" onClose={this.props.onClose} open={true} size="large">
        <Modal.Content style={{paddingBottom: 0, paddingTop: 0}}>{this.renderProduct()}</Modal.Content>
      </Modal2>
    );
  }

  handleBreadcrumbClick = (slug) => (e) => {
    this.props.router.push(`/categories/${slug}`);
  };

  renderHeader() {
    const {breadcrumbs} = this.state;
    const isMobile = this.props.isMobile;

    if (isMobile) {
      return (
        <ProductFilters>
          <Breadcrumb icon="right angle" sections={breadcrumbs.map((v) => ({...v, onClick: this.handleBreadcrumbClick(v.onClick)}))} />
        </ProductFilters>
      );
    } else {
      return <ProductFilters>&nbsp;</ProductFilters>;
    }
  }

  renderPage() {
    const slug = get(this.props.viewer, 'product.category.slug') || null;

    const isMobile = this.props.isMobile;

    return (
      <Container>
        {!isMobile && <StoreCategories category={slug} viewer={this.props.viewer} />}
        <div>
          {this.renderHeader()}
          <div>{this.renderProduct()}</div>
        </div>
      </Container>
    );
  }

  render() {
    const product = get(this.props, 'viewer.product') || null;
    if (!product) {
      return null;
    }

    return this.props.onClose ? this.renderModal() : this.renderPage();
  }
}

const ProductContainer = createFragmentContainer(withRouter(withWindowSize(Product)), {
  viewer: graphql`
    fragment Product_viewer on Viewer {
      ...StoreCategories_viewer
      id
      user {
        role
        isEditor
      }
      cart {
        id
        items(first: 10000) {
          edges {
            node {
              clearanceItem {
                id
              }
              id
              qty
              product {
                id
                productId
              }
            }
          }
        }
      }
      clearanceItem(clearanceItemId: $clearanceItemId) {
        cost
        id
        qty
        unit
      }
      categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
        edges {
          node {
            id
            name
            slug
            categories(filterBy: {isActive: true}) {
              edges {
                node {
                  id
                  name
                  slug
                  categories(filterBy: {isActive: true}) {
                    edges {
                      node {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      product(productId: $productId) {
        id
        productId
        canAddToCart
        fullName
        package
        pack
        size
        sizeUnit
        state
        unit
        cost
        availabilityStatus
        createdAt
        isVariable
        isActive
        isAvailable
        category {
          slug
        }
        categories {
          rowId
          name
          slug
        }
        comments(first: 100, orderBy: [["created", "DESC"]]) @connection(key: "Product_comments") {
          edges {
            node {
              id
              name
              comment
              rating
              createdAt
            }
          }
        }
        images(first: 100) @connection(key: "Product_images") {
          edges {
            node {
              id
              isPrimary
              previewUrl
              url
            }
          }
        }
        questions(first: 100) @connection(key: "Product_questions") {
          edges {
            node {
              id
              rowId
              content
              answers(first: 100) @connection(key: "Question_answers") {
                edges {
                  node {
                    id
                    content
                  }
                }
              }
            }
          }
        }
      }
      specialItem(specialItemId: $specialItemId) {
        cost
        id
        unit
      }
    }
  `,
});

ProductContainer.getInitialVariables = (params) => {
  const {id, type} = fromGlobalId(params.variantId || '');
  const variables = {
    clearanceItemId: type === 'ClearanceItem' ? id : undefined,
    productId: params.productId,
    specialItemId: type === 'SpecialItem' ? id : undefined,
  };
  return variables;
};

export default ProductContainer;
