import {withRouter} from 'found';
import React from 'react';
import styled from 'styled-components';
import {go, withWindowSize} from '../helpers';
import {OrangeButton} from './Common/Button';
import {CustomIcon} from './CustomIcon/CustomIcon';

const Container = styled.div`
  padding: ${(p) => (p.isMobile ? '0' : '20px 0 40px 0')};
  min-height: 100vh;
  background: #f7f7f7;

  & .icon {
    color: #2196f3;
  }

  & h3 {
    font-size: 1.75rem;
  }
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 25px;
  max-width: 960px;
  background: white;
  border: ${(p) => (p.isMobile ? '0' : '1px solid #e5edec')};
  border-radius: ${(p) => (p.isMobile ? '0' : '5px')};

  & > div:first-child {
    display: flex;
    align-items: center;

    & p {
      margin: 0 1em 0 0;
    }
  }

  & hr {
    margin: 3em 0;
    height: 0;
    border: 0;
    border-top: 1px solid #ccc;
  }

  & table {
    margin: 0 0 1em 0;
    border-collapse: collapse;
  }

  & table td {
    padding: 10px;
    background: #f2f2f2;
    border: 1px solid #fff;
    vertical-align: top;
  }
`;

const QuestionItem = styled.div`
  margin: 0 0 3em 0;
`;

const Question = styled.div`
  padding: 0 0 0.5em 0;
  font-weight: bold;
`;

const Answer = styled.div`
  padding: 0 0 1em 0;
`;

const Top = styled.a``;

class Help extends React.Component {
  componentDidMount() {
    const hash = window.location.hash || '';
    if (hash.length > 1) {
      const element = document.querySelector(hash);
      const top = element.getBoundingClientRect().top - 136;
      window.scrollTo({top});
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const [, hash] = e.target.href.split('#');
    const element = document.querySelector('#' + hash);
    if (element) {
      const top = element.getBoundingClientRect().top - 136;
      window.scrollTo({top});
      window.history.pushState({}, `KC Kosher Co-op - Help`, `/help/#${hash}`);
    }
  };

  render() {
    return (
      <Container isMobile={this.props.isMobile}>
        <Content id="top" isMobile={this.props.isMobile}>
          <div>
            <p>If you can not find an answer to your question, please do not hesitate to contact us.</p>
            <OrangeButton onClick={go('/contact', {router: this.props.router})}>Contact Us</OrangeButton>
          </div>

          {/* <a onClick={this.handleClick} href="https://kckoshercoop.com/faq/video" className="video">
          Video Tutorials
        </a> */}

          <h3>About Our Products</h3>
          <ul>
            <li>
              <a href="#q1-1" onClick={this.handleClick}>
                How big is the product selection of the Co-op?
              </a>
            </li>
            <li>
              <a href="#q1-2" onClick={this.handleClick}>
                Are all the items on the Co-op website kosher?
              </a>
            </li>
            <li>
              <a href="#q1-3" onClick={this.handleClick}>
                How can these products be so cheap?
              </a>
            </li>
            <li>
              <a href="#q1-4" onClick={this.handleClick}>
                Are these the same prices that a store would pay for these items?
              </a>
            </li>
            <li>
              <a href="#q1-5" onClick={this.handleClick}>
                Is the cheese in blocks, shredded, sliced, or individually wrapped?
              </a>
            </li>
            <li>
              <a href="#q1-6" onClick={this.handleClick}>
                What do the products marked with an <CustomIcon name="far-balance-scale" title="Variable Weight Product" /> mean?
              </a>
            </li>
            <li>
              <a href="#q1-7" onClick={this.handleClick}>
                Glossary of Terms
              </a>{' '}
            </li>
            <li>
              <a href="#q1-8" onClick={this.handleClick}>
                Are products that are Chalav Yisrael marked as such?
              </a>
            </li>
            <li>
              <a href="#q1-9" onClick={this.handleClick}>
                I want a specific product, can you get it?
              </a>
            </li>
            <li>
              <a href="#q1-10" onClick={this.handleClick}>
                Where can I find hechsher, nutrition, and ingredient information that is not available on the Co-op website?
              </a>
            </li>
            <li>
              <a href="#q1-11" onClick={this.handleClick}>
                Are prices guaranteed?
              </a>
            </li>
            <li>
              <a href="#q1-12" onClick={this.handleClick}>
                Are products shipped fresh or frozen?
              </a>
            </li>
          </ul>

          <h3>Order Logistics</h3>
          <ul>
            <li>
              <a href="#q2-1" onClick={this.handleClick}>
                How does paying online work and is it secure?
              </a>
            </li>
            <li>
              <a href="#q2-2" onClick={this.handleClick}>
                Can I split my order with someone else?
              </a>
            </li>
            <li>
              <a href="#q2-3" onClick={this.handleClick}>
                How much is delivery?
              </a>
            </li>
            <li>
              <a href="#q2-4" onClick={this.handleClick}>
                Can I place multiple orders for the same Co-op delivery?
              </a>
            </li>
            <li>
              <a href="#q2-5" onClick={this.handleClick}>
                Once I place an order, can I change it?
              </a>
            </li>
            <li>
              <a href="#q2-6" onClick={this.handleClick}>
                I received my items and something is wrong or damaged, what do I do?
              </a>
            </li>
            <li>
              <a href="#q2-7" onClick={this.handleClick}>
                How do I get my money back if certain items from my order do not arrive (an item is out of stock)?
              </a>
            </li>
            <li>
              <a href="#q2-8" onClick={this.handleClick}>
                I need a lot of food soon, is there some way I can get a special delivery?
              </a>
            </li>
            <li>
              <a href="#q2-9" onClick={this.handleClick}>
                How many food deliveries are there per year?
              </a>
            </li>
            <li>
              <a href="#q2-10" onClick={this.handleClick}>
                When is the next food delivery?
              </a>
            </li>
            <li>
              <a href="#q2-11" onClick={this.handleClick}>
                How do I pay?
              </a>
            </li>
            <li>
              <a href="#q2-12" onClick={this.handleClick}>
                Can I see my order history?
              </a>
            </li>
            <li>
              <a href="#q2-13" onClick={this.handleClick}>
                I received meat with a date stamped on it that has already passed.
              </a>
            </li>
            <li>
              <a href="#q2-14" onClick={this.handleClick}>
                I received a bunch of poultry frozen together, what do I do?
              </a>
            </li>
          </ul>

          <h3>Pick Up Logistics</h3>
          <ul>
            <li>
              <a href="#q3-1" onClick={this.handleClick}>
                How will I be notified of the delivery?
              </a>
            </li>
            <li>
              <a href="#q3-2" onClick={this.handleClick}>
                My order contains refrigerated and/or frozen food. Will it thaw before I pick it up?
              </a>
            </li>
            <li>
              <a href="#q3-3" onClick={this.handleClick}>
                Where do I pick up my order?
              </a>
            </li>
            <li>
              <a href="#q3-4" onClick={this.handleClick}>
                Something came up and I will not be able to pick up the items. Can I arrange a different time?
              </a>
            </li>
            <li>
              <a href="#q3-5" onClick={this.handleClick}>
                It is difficult for me to load/unload the items, can you bring them to my house?
              </a>
            </li>
            <li>
              <a href="#q3-6" onClick={this.handleClick}>
                Do you accept returns?{' '}
              </a>
            </li>
          </ul>

          <h3>Passover Products and Logistics</h3>
          <ul>
            <li>
              <a href="#q4-1" onClick={this.handleClick}>
                How do I know what products on the website are Kosher for Passover?
              </a>
            </li>
            <li>
              <a href="#q4-2" onClick={this.handleClick}>
                I see noodles and bagels in the Passover listing, I thought everything was Kosher for Passover?
              </a>
            </li>
            <li>
              <a href="#q4-3" onClick={this.handleClick}>
                Is there any way for me to order a non-Kosher for Passover product?
              </a>
            </li>
            <li>
              <a href="#q4-4" onClick={this.handleClick}>
                I got a gefilte fish I liked last order, can I order it again for Passover?
              </a>
            </li>
            <li>
              <a href="#q4-5" onClick={this.handleClick}>
                Why are there two deliveries?
              </a>
            </li>
            <li>
              <a href="#q4-6" onClick={this.handleClick}>
                When do you process my payment?
              </a>
            </li>
          </ul>

          <h3>Contact</h3>
          <ul>
            <li>
              <a href="#q5-1" onClick={this.handleClick}>
                How do I contact you?
              </a>
            </li>
            <li>
              <a href="#q5-2" onClick={this.handleClick}>
                What is your mailing address
              </a>
            </li>
          </ul>

          <h3>Miscellaneous</h3>
          <ul>
            <li>
              <a href="#q6-1" onClick={this.handleClick}>
                Is this your full time job?
              </a>
            </li>
            <li>
              <a href="#q6-2" onClick={this.handleClick}>
                Now that I'm buying kosher food in bulk, I need a freezer, any recommendations?
              </a>
            </li>
            <li>
              <a href="#q6-3" onClick={this.handleClick}>
                How do I remove my membership?
              </a>
            </li>
            <li>
              <a href="#q6-4" onClick={this.handleClick}>
                You don't deliver to my city, can you start?
              </a>
            </li>
            <li>
              <a href="#q6-5" onClick={this.handleClick}>
                What is the substitution feature?
              </a>
            </li>
          </ul>

          <hr />

          <h3>About our Products</h3>
          <QuestionItem id="q1-1">
            <Question>Q. How big is the product selection of the Co-op?</Question>
            <Answer>
              A. The Co-op product catalog contains thousands of items, generally many more than you could possibly find locally. We tried
              to design this website with intuitive product categories and an excellent search feature to make identifying products simple.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-2">
            <Question>Q. Are all the items on the Co-op website kosher?</Question>
            <Answer>
              A. All the items carry kosher certification, though some may or may not be acceptable to you, depending on your level of
              kashrut. Unless marked otherwise, all meat is Glatt Kosher. If you have a question about a specific product, please contact
              your local Va'ad or Kashrut Authority.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-3">
            <Question>Q. How can these products be so cheap?</Question>
            <Answer>
              A. You are buying in bulk which yields lower prices. Additionally, our overhead is dramatically lower than a grocery store.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-4">
            <Question>Q. Are these the same prices that a store would pay for these items?</Question>
            <Answer>
              A. Not quite. We still have a certain amount of overhead--delivery charges, fuel surcharges, freezer rental space, website
              development, check cashing fees, labor hired on delivery date, etc. To cover this, we add a small mark up.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-5">
            <Question>Q. Is the cheese in blocks, shredded, sliced, or individually wrapped?</Question>
            <Answer>
              A. We have tried to mark all shredded, sliced, or individually wrapped cheeses. Generally if it is not marked otherwise, the
              cheese is in blocks--but to be sure please{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>{' '}
              if you have a specific question.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-6">
            <Question>
              Q. What do the products marked with an <CustomIcon name="far-balance-scale" title="Variable Weight Product" /> mean?
            </Question>
            <Answer>
              A. The <CustomIcon name="far-balance-scale" title="Variable Weight Product" />
              &nbsp; indicates that the weight and number of packages listed for this product is approximate. You will be responsible for
              paying for the actual weight shipped, whether it is less or more than the weight listed on the website. In general, the weight
              listed is within 25% accuracy, often much more accurate than even that. You only pay for the actual weight shipped. If you
              paid online, your payment will automatically be adjusted before your account is debited. If you paid with a physical check, we
              will ask that you mail us a check for the difference or if we owe you money, we will give you a choice of a credit on your
              account or a check that we mail to you. We are sorry for this complication, but this is the nature of this type of product and
              is necessary if you would like to purchase our variable weight products.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-8">
            <Question>Q. Are products that are Chalav Yisrael marked as such?</Question>
            <Answer>
              A. We have put in the product description "Chalav Yisrael" for some products. However, there are products that are Chalav
              Yisrael that are not yet marked as such. If you have a question about a specific product, please{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>
              .
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-9">
            <Question>Q. I want a specific product, can you get it?</Question>
            <Answer>
              A. Please{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>{' '}
              and we can see if we can obtain the product you are looking for.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-10">
            <Question>
              Q. Where can I find hechsher, nutrition, and ingredient information that is not available on the Co-op website?{' '}
            </Question>
            <Answer>
              A. Usually putting the brand and product description into a search engine (like{' '}
              <a href="http://google.com" onClick={this.handleClick} rel="noopener noreferrer" target="_blank">
                Google
              </a>{' '}
              or{' '}
              <a href="http://calorieking.com" onClick={this.handleClick} rel="noopener noreferrer" target="_blank">
                Calorie King
              </a>
              ) will bring up information about that specific product. As effort allows we add photos of ingredient and nutrition
              information.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-11">
            <Question>Q. Are prices guaranteed?</Question>
            <Answer>
              A. Prices constantly fluctuate, especially with the rising energy costs. We absorb real-time price changes unless it is
              significant in which case the Co-op member will have to pay the difference. As of the writing of this question we have never
              had to ask a Co-op member to pay the difference.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q1-12">
            <Question>Q. Are products shipped fresh or frozen?</Question>
            <Answer>
              A. Products are generally shipped fresh unless marked otherwise. But in order to maintain fulfillment, KC Kosher must maintain
              backstock of key items in our freezer (which is about 40 deg F below freezing!) so we may ship an item frozen if the supplier
              did not send enough fresh. Product quality always follows our guarantee.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <hr />
          <h3>Order Logistics</h3>

          <QuestionItem id="q2-1">
            <Question>Q. How does paying online work and is it secure?</Question>
            <Answer>
              <p>
                A. The Co-op has purchased a secure SSL certificate for payments - this is evident because (1) the http in the browser
                changes to https and (2) a padlock will appear in the lower right of your browser window (this will vary depending on the
                browser). This is 128 bit or 256 bit encryption (also browser dependent)--either of which is computationally unfeasible to
                crack. We also have an additional layer of security that most websites do not by encrypting financial information in our
                database.
              </p>
              <p>
                <strong>Online Credit Card</strong> - Visa and MasterCard are accepted and there is a 3% surcharge added to cover the fees
                associated with taking credit cards. We do not store credit card information in any fashion in our database. Credit card
                payments are immediately submitted to the Payment Gateway and we receive a unique Transaction ID that we can use to
                reference that payment in the future. At the point the order is within 20 days of delivery, we will put a hold on your
                credit card account for the cost of the order (this is where you'd see "pending transaction" in your online statement) and
                then after delivery we will do a final charge for what is actually delivered. If you owe more than your original order (such
                as if you added a product or received an item at a higher weight than what was estimated), we'll do a second charge of how
                much over the original amount you owe. If you owe less than your original order, the pending charge will be changed to
                reflect the new, lower, order total.
              </p>
              <p>
                <strong>Online Check Payment</strong> - Online Check Payments are very similar to handing us a check except that the amount
                we debit your account will be the amount of what is actually delivered. We submit your check information to our bank shortly
                after delivery and it goes through the 7-10 day clearing process before it posts to your account. After the check is
                cleared, you will have an image of your check available just like any physical check (assuming your bank makes images of
                your checks available to you). After you submit your check information, you should document it in your register and then rip
                up that check.
              </p>
              <p>
                <strong>Mailed Check</strong> - We will deposit the check and then settle up any differences with you after delivery (such
                as a credit or amount due). Physically mailed checks may be deposited immediately upon receipt by the Co-op or may be held
                until after the delivery. Our mailing address is:
                <br />
                <br />
                KC Kosher Co-op
                <br />
                8212 W 97th Terrace
                <br />
                Overland Park, KS, 66212
              </p>
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-2">
            <Question>Q. Can I split my order with someone else?</Question>
            <Answer>
              <p>
                A. Absolutely! You can arrange a split on your own or you can use the{' '}
                <a href="/account/splits" onClick={this.handleClick}>
                  Product Splitting Page
                </a>
                . Remember that the person who places the order is responsible for paying for and picking up that order.
              </p>
              <p>
                Please make it a habit when you are shopping to also check the{' '}
                <a href="/account/splits" onClick={this.handleClick}>
                  Product Splitting Page
                </a>
                —arranging splits with other members benefits everyone.
              </p>
              <blockquote>
                <p>
                  A) If you are on the Product Splitting Page and see a product you want to split, just click “Ask to Split” and you will be
                  guided through the split process.
                </p>
                <p>B) If you are shopping and you see a product that you would like to split…</p>
                <ol>
                  <li>Click on that product’s link.</li>
                  <li>
                    Click the “Split with Someone” button. Doing so will add that product to a list on the Product Splitting Page that all
                    Co-op members can view.{' '}
                  </li>
                  <li>
                    If someone else decides they would like to split that product with you, they can contact you by clicking the link on the
                    Product Splitting Page – an email will be sent to notify you that that person is interested.
                  </li>
                </ol>
              </blockquote>
              <p>
                Please note that putting a split product request on the Product Splitting Page does not add that item to your cart. If you
                want to insure that a specific product is in your order, you will need to add it to your cart and proceed with the checkout
                process.
              </p>
              <p>
                After a split is arranged, please remember to go to your personal{' '}
                <a href="https://kckoshercoop.com/splits/my" onClick={this.handleClick}>
                  Products You Would Like to Split
                </a>{' '}
                page and remove those items.
              </p>
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-3">
            <Question>Q. How much is delivery?</Question>
            <Answer>
              A. For co-ops that place a combined community order of $7,000 or more, there is no added cost for delivery. Delivery and fuel
              surcharge fees are built in to the small mark-up we add to the products. If the all orders do not add up to $7,000, there is a
              shipping fee added:
              <ul>
                <li>$7000+ - Free Delivery!</li>
                <li>$6000 - $7000 - 3% Fee</li>
                <li>$5000 - $6000 - 5% Fee</li>
                <li>$4000 - $5000 - 7% Fee</li>
                <li>$3000 - $4000 - 10% Fee</li>
                <li>Under $3000 - Not shipping</li>
              </ul>
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-4">
            <Question>Q. Can I place multiple orders for the same Co-op delivery?</Question>
            <Answer>A. Yes, you can place as many orders as you like as long as it is before the order deadline.</Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-5">
            <Question>Q. Once I place an order, can I change it?</Question>
            <Answer>
              A. You can cancel or modify orders up until the order deadline by contacting us. After the order deadline, cancellations are
              no longer possible, but we can usually accept new orders through the Sunday after the order deadline (or later if what you
              want is in available inventory).
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-6">
            <Question>Q. I received my items and something is wrong or damaged, what do I do?</Question>
            <Answer>
              A.{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                Contact us
              </a>{' '}
              immediately with the problem so we can work it out.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-7">
            <Question>Q. How do I get my money back if certain items from my order do not arrive (an item is out of stock)?</Question>
            <Answer>
              A. For those who pay online, we debit your account for the actual items that ship, so there is no refund required. For those
              who mail in a check, you will get a credit on your account. You can request at any time for that credit to be mailed to you as
              a check.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q">
            <Question>Q. I need a lot of food soon, is there some way I can get a special delivery?</Question>
            <Answer>
              A. We have a minimum required order from our distributor. If you happen to require a particularly large quantity (well over a
              thousand pounds), we will try to arrange for a special delivery.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-8">
            <Question>Q. How many food deliveries are there per year?</Question>
            <Answer>
              A. Delivery frequency varies by location. Some cities only have four deliveries per year while other cities have a delivery
              each month. Deliveries will be as frequent in your city as the order quantity can support.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-9">
            <Question>Q. When is the next food delivery?</Question>
            <Answer>
              A. The next food delivery date is listed on the top of our website. If for some reason we do not gather the order minimum by
              that date (not expected to be an issue), we will contact everyone to let them know there will be a delay.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-10">
            <Question>Q. How do I pay?</Question>
            <Answer>
              <p>
                A. After ensuring you've placed an order, simply click the{' '}
                <a href="/pay" onClick={this.handleClick}>
                  Pay Link
                </a>{' '}
                at the top of the page. This gives the options for check, credit card, or mailed payments.
              </p>
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-11">
            <Question>Q. Can I see my order history?</Question>
            <Answer>
              A. Yes, if you have placed orders previously you can see those orders by selecting the{' '}
              <a href="https://kckoshercoop.com/orders" onClick={this.handleClick}>
                Past Orders
              </a>{' '}
              link at the top of the website.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-12">
            <Question>Q. I received meat with a date stamped on it that has already passed.</Question>
            <Answer>
              A. There is a date stamped on the meat is a "use or freeze by" date. Any frozen meat the warehouse put in their deep freezer
              well before the freeze date--red meat should be good for at least 10 months after that date and poultry should be good for at
              least 8 months. It is rare that we ship poultry with a freeze date that has passed, so if you have received frozen poultry
              with a passed date, please{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>{' '}
              so we can verify the product is acceptable.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q2-13">
            <Question>Q. I received a bunch of poultry frozen together, what do I do?</Question>
            <Answer>
              A. Unlike red meat, poultry can not be safely thawed and refrozen. If you purchased an institutional item that has several
              birds frozen together, it is safe to allow the mass to thaw just enough to break the birds apart and then immediately freeze.
              Please note that if you forget about the thawing birds and have let them go to the point there are no more ice crystals, than
              it is too late to refreeze and the birds will need to be cooked or thrown away.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <hr />
          <h3>Pick Up Logistics</h3>

          <QuestionItem id="q3-1">
            <Question>Q. How will I be notified of the delivery?</Question>
            <Answer>
              A. There will be a reminder email a few days before the delivery reminding everyone of the pick-up time and location. As
              circumstances may arise that we cannot control (eg: delivery truck breaks down), we have a hotline with up-to-the-minute
              status on the delivery time. To get the hotline number please log in to the website and you can see it on the homepage. After
              you login, delivery status will be on the homepage as well.
              <br />
              <br />
              The Co-op Hotline number is: <span style={{fontSize: 19}}>(913) 712-9205</span>.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q3-2">
            <Question>Q. My order contains refrigerated and/or frozen food. Will it thaw before I pick it up?</Question>
            <Answer>
              A. All food is delivered in a refrigerated truck with a separate freezer compartment. We recommend arriving as early as
              possible during the pick-up window so your food is exposed to heat as little as possible. The food is fine if picked up within
              a couple hours of unloading from the refrigerated truck. Keep in mind that the food will also be in boxes that help provide
              insulation. For those ordering ice cream, you should coordinate with your local "Co-op Director" to meet the truck as soon as
              it arrives (which will be before the general pick-up window).
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q3-3">
            <Question>Q. Where do I pick up my order?</Question>
            <Answer>A. Log in to the website and your deliver location will be listed in the upper right of the webpage.</Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q3-4">
            <Question>Q. Something came up and I will not be able to pick up the items. Can I arrange a different time?</Question>
            <Answer>
              A. Unfortunately for now we must request that you find an alternate person to pick up your items. You will need to email us
              that alternate person’s name or be available on the phone for us to call to verify the person is authorized to pick up the
              items for you.
              <br />
              <br />
              If there is an absolute emergency,{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>{' '}
              and we will do our best to work with you. We will actively monitor our email the day before and the day of deliveries in case
              someone contacts us with an emergency situation.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q3-5">
            <Question>Q. It is difficult for me to load/unload the items, can you bring them to my house?</Question>
            <Answer>
              A. We would like to work with you.{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                Contact us
              </a>{' '}
              and we will work with local agencies to attempt to provide this service. If this does not work out, we can arrange for a car
              service to bring the food to your house, but you will need to cover the fee of the car service.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q3-6">
            <Question>Q. Do you accept returns?</Question>
            <Answer>
              A. Each Co-op Member is responsible to check the quality of the delivered product. We can be contacted within the first two
              weeks after delivery to resolve quality issues. We are unable to resolve quality issues raised later than two weeks after
              delivery.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <hr />
          <h3>Passover Products and Logistics</h3>

          <QuestionItem id="q4-1">
            <Question>Q. How do I know what products on the website are Kosher for Passover?</Question>
            <Answer>
              A. During the Passover Order Cycle, we show only Kosher for Passover products. We also show a notice across the top of the
              website to remind our Members that the listed products are Kosher for Passover.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q4-2">
            <Question>Q. I see noodles and bagels in the Passover listing, I thought everything was Kosher for Passover?</Question>
            <Answer>
              {/* eslint-disable-next-line max-len */}
              A. Even the noodles and bagels are Kosher for Passover. Though if you have a question about a specific product, you can always{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>
              .
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q4-3">
            <Question>Q. Is there any way for me to order a non-Kosher for Passover product?</Question>
            <Answer>
              A. If you{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact us
              </a>
              , we can try, but the warehouse turns Kosher for Passover as well, so it is difficult to get any non-KFP products.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q4-4">
            <Question>Q. I got a gefilte fish I liked last order, can I order it again for Passover?</Question>
            <Answer>
              A. Be careful!! If you add an item to your cart from your order history, you are not adding a Passover product! Only products
              active on the site (browsing and searching) are Kosher for Passover.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q4-5">
            <Question>Q. Why are there two deliveries?</Question>
            <Answer>
              A. We take an extra effort to get all products ordered and for Passover this means two deliveries to some of our cities.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q4-6">
            <Question>Q. When do you process my payment?</Question>
            <Answer>
              A. If you pay online, we process payment shortly after delivery. If your city has two deliveries for Passover, we process the
              payment after the *second* delivery.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <hr />
          <h3>Contact</h3>

          <QuestionItem id="q5-1">
            <Question>Q. How do I contact you?</Question>
            <Answer>
              A. Please use the{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                “Contact Us” form
              </a>{' '}
              which will send your question to us via email. We check email frequently.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q5-2">
            <Question>Q. What is your mailing address?</Question>
            <Answer>
              A. Our mailing address is:
              <br />
              <br />
              KC Kosher Co-op
              <br />
              8212 W 97th Terrace
              <br />
              Overland Park, KS, 66212
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <hr />
          <h3>Miscellaneous</h3>

          <QuestionItem id="q6-1">
            <Question>Q. Is this your full time job?</Question>
            <Answer>
              A. No, the co-op was originally created as a service to the community with no intention to make a profit. Now it does provide
              some supplemental income, but it is limited due to our minimal mark-up from the prices we receive.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q6-2">
            <Question>Q. Now that I'm buying kosher food in bulk, I need a freezer, any recommendations?</Question>
            <Answer>
              A. There are great freezers out there that have very low annual electricity costs (under $45/year). To view freezer ratings by
              a major consumer products rating magazine, please{' '}
              <a className="ratings" href="/site/next/img/ratings.jpg" onClick={this.handleClick}>
                click here
              </a>
              .
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q6-3">
            <Question>Q. How do I remove my membership?</Question>
            <Answer>
              A. Please use our{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                contact form
              </a>{' '}
              to email us and we will remove your registration information.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q6-4">
            <Question>Q. You don't deliver to my city, can you start?</Question>
            <Answer>
              A. Getting a delivery is quite simple, but there are certain criteria that must be met before we add your city. Please{' '}
              <a href="https://kckoshercoop.com/contact" onClick={this.handleClick}>
                Contact Us
              </a>{' '}
              and we will try to work with you.
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <QuestionItem id="q6-5">
            <Question>Q. What is the substitution feature?</Question>
            <Answer>
              <p>
                A. The substitution feature is something you will see in your shopping cart. It allows you to select up to five alternates
                for each product, thus increasing our ability to fulfill your order. If the product you originally ordered is not available,
                but one of your substitutes is, we will automatically send the substitute.
              </p>
              <p>
                Unlike a typical store that can keep an inventory, much of our product arrives fresh weekly so the warehouse is at the mercy
                of product suppliers. One of the reasons we try to have the order deadline two weeks before delivery is to give the
                warehouse two weeks to obtain products.
              </p>
              <p>
                To select substitutes, you first need to add an item to your shopping cart. For each product, click on the "Select" button
                on the "Choose Substitutes" column on the righthand side of the shopping cart. A window with options for substitutes will
                come up - you can select up to five. Click on the OK button to save your selections. The substitutions will automatically be
                included when you submit your order.
              </p>
            </Answer>
            <Top href="#top" onClick={this.handleClick}>
              [back to top]
            </Top>
          </QuestionItem>

          <hr />
          <h3 id="q1-7">Glossary of Terms</h3>

          <table width="100%">
            <tbody>
              <tr>
                <td width="21%">
                  <strong>Retail Packages</strong>
                </td>
                <td width="79%">
                  These are packages that are the size one would buy in a store--generally 1-2 pounds each. Each "Retail Packages" order
                  will contain a certain number of these packages.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Family Pack or Economy Pack</strong>
                </td>
                <td>
                  Larger than a single serving package. For example, instead of a bag of chips from a vending machine, "Family Pack" would
                  be a single large bag of chips that would feed several people.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Poly Wrapped</strong>
                </td>
                <td>Individually wrapped in loose plastic (not vacuum packed)</td>
              </tr>
              <tr>
                <td>
                  <strong>Camp Cut</strong>
                </td>
                <td>TBD</td>
              </tr>
              <tr>
                <td>
                  <strong>KAJ</strong>
                </td>
                <td>Glatt kosher hechsher (K'hal Adath Jeshurun)</td>
              </tr>
              <tr>
                <td>
                  <strong>LUB</strong>
                </td>
                <td>Lubavitch Hechsher...Glatt with additional stringencies</td>
              </tr>
              <tr>
                <td>
                  <strong>Institutional</strong>
                </td>
                <td>
                  Institutional items come in a big box, not individually wrapped. So a box of chickens may come all frozen together, and
                  you will have to manually pull them apart and re-package them. You also may have to trim off the necks, etc (if they are
                  chickens). Also, items like the Back Ribs (institutional) one would need a saw to deal with this product. Please only
                  order Institutional if you know what you are ordering--this is generally a product used by hotels or butchers.
                </td>
              </tr>
            </tbody>
          </table>
          <Top href="#top" onClick={this.handleClick}>
            [back to top]
          </Top>
        </Content>
      </Container>
    );
  }
}

export default withRouter(withWindowSize(Help));
