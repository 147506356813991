import {round} from '@ivosabev/helpers';

const PAYMENT_TYPE_CARD = 'cc';
const PAYMENT_TYPE_CHECK = 'pc';
const PAYMENT_TYPE_E_CHECK = 'ec';
const CARD_PAYMENT_SURCHARGE = 0.03;

export function calcTotals({
  availableCredit = 0,
  discount = 0,
  payment = 0,
  paymentType,
  refund = 0,
  shippingRate = 0,
  subtotal,
  taxRate = 0,
}: any = {}) {
  subtotal = Number(subtotal) || 0;
  discount = Number(discount) || 0;
  shippingRate = Number(shippingRate) || 0;
  taxRate = Number(taxRate) || 0;
  payment = Number(payment) || 0;
  refund = Number(refund) || 0;

  const shipping = (subtotal * shippingRate) / 100;
  const tax = ((subtotal - discount) * taxRate) / 100;

  // Total - Payment - Credit - Owed - Refund - Leftover Credit
  let surchargeRate = 0;
  let surcharge = 0;
  let total = subtotal - discount + shipping + tax;
  let credit = 0;
  let owed = 0;
  let userCredit = 0;

  if (payment > 0) {
    if (paymentType === PAYMENT_TYPE_CHECK) {
      payment = payment + refund;
      refund = 0;

      if (payment >= total) {
        credit = 0;
        payment = total;
        refund = payment - total;
        owed = 0;
        userCredit = availableCredit;
      } else {
        if (availableCredit >= total - payment) {
          credit = total - payment;
          owed = 0;
          userCredit = availableCredit - credit;
        } else {
          credit = availableCredit;
          owed = total - payment - credit;
          userCredit = 0;
        }
      }
    } else if (paymentType === PAYMENT_TYPE_E_CHECK) {
      if (availableCredit >= total) {
        credit = total;
        payment = 0;
        owed = 0;
        userCredit = availableCredit - credit;
      } else {
        credit = availableCredit;
        payment = total - credit;
        owed = 0;
        userCredit = 0;
      }
    } else if (paymentType === PAYMENT_TYPE_CARD) {
      if (availableCredit >= total) {
        credit = total;
        payment = 0;
        owed = 0;
        userCredit = availableCredit - credit;
      } else {
        // HACK: Surcharge rate is not standardized as 3, but shows as 0.03 in orders table
        surchargeRate = CARD_PAYMENT_SURCHARGE * 100;
        surcharge = (total * surchargeRate) / 100;
        total = total + surcharge;
        credit = availableCredit;
        payment = total - credit;
        owed = 0;
        userCredit = 0;
      }
    } else {
      throw new Error('Unknown payment method');
    }
  } else {
    if (availableCredit >= total) {
      credit = total;
      owed = 0;
      userCredit = availableCredit - credit;
    } else {
      credit = availableCredit;
      owed = total - credit;
      userCredit = 0;
    }
  }

  return {
    order: {
      credit: round(credit),
      discount,
      payment: round(payment),
      refund: round(refund),
      shipping: round(shipping),
      shippingRate,
      subtotal,
      surcharge,
      surchargeRate,
      tax: round(tax),
      taxRate,
      total: round(total),
    },
    owed: round(owed),
    user: {
      credit: round(userCredit),
    },
  };
}
