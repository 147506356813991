import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation SendSplitRequestMutation($input: SendSplitRequestInput!) {
    sendSplitRequest(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export const SendSplitRequestMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['splitUserId', 'subject', 'message']),
    },
  });
};
