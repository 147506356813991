import {graphql} from 'babel-plugin-relay/macro';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import Hero from './Hero';
import HomeCategories from './HomeCategories';
import Tabs from './Tabs';

const Container = styled.div`
  width: 100%;
`;

const Home = (props) => {
  const {viewer} = props;

  return (
    <Container>
      <Hero />
      <Tabs viewer={viewer} />
      <HomeCategories viewer={viewer} />
    </Container>
  );
};

export default createFragmentContainer(Home, {
  viewer: graphql`
    fragment Home_viewer on Viewer {
      ...HomeCategories_viewer
      ...Tabs_viewer
    }
  `,
});
