/**
 * @generated SignedSource<<71e24024190de9cac3fe520f3c39e7ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Product_viewer$data = {
  readonly cart: {
    readonly id: string;
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly clearanceItem: {
            readonly id: string;
          } | null | undefined;
          readonly id: string;
          readonly product: {
            readonly id: string;
            readonly productId: string | null | undefined;
          } | null | undefined;
          readonly qty: number | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly categories: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly categories: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly categories: {
                readonly edges: ReadonlyArray<{
                  readonly node: {
                    readonly id: string;
                    readonly name: string | null | undefined;
                    readonly slug: string | null | undefined;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly name: string | null | undefined;
              readonly slug: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly slug: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly clearanceItem: {
    readonly cost: number | null | undefined;
    readonly id: string;
    readonly qty: number | null | undefined;
    readonly unit: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly product: {
    readonly availabilityStatus: string | null | undefined;
    readonly canAddToCart: boolean | null | undefined;
    readonly categories: ReadonlyArray<{
      readonly name: string | null | undefined;
      readonly rowId: number;
      readonly slug: string | null | undefined;
    } | null | undefined> | null | undefined;
    readonly category: {
      readonly slug: string | null | undefined;
    } | null | undefined;
    readonly comments: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly comment: string | null | undefined;
          readonly createdAt: any | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly rating: number | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly cost: number | null | undefined;
    readonly createdAt: any | null | undefined;
    readonly fullName: string | null | undefined;
    readonly id: string;
    readonly images: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly isPrimary: boolean | null | undefined;
          readonly previewUrl: string | null | undefined;
          readonly url: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly isActive: boolean | null | undefined;
    readonly isAvailable: boolean | null | undefined;
    readonly isVariable: boolean | null | undefined;
    readonly pack: number | null | undefined;
    readonly package: string | null | undefined;
    readonly productId: string | null | undefined;
    readonly questions: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly answers: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly content: string | null | undefined;
                readonly id: string;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly content: string | null | undefined;
          readonly id: string;
          readonly rowId: number;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly size: number | null | undefined;
    readonly sizeUnit: string | null | undefined;
    readonly state: string | null | undefined;
    readonly unit: number | null | undefined;
  } | null | undefined;
  readonly specialItem: {
    readonly cost: number | null | undefined;
    readonly id: string;
    readonly unit: number | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly isEditor: boolean | null | undefined;
    readonly role: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"StoreCategories_viewer">;
  readonly " $fragmentType": "Product_viewer";
};
export type Product_viewer$key = {
  readonly " $data"?: Product_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Product_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qty",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cost",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "filterBy",
    "value": {
      "isActive": true
    }
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "clearanceItemId"
    },
    {
      "kind": "RootArgument",
      "name": "productId"
    },
    {
      "kind": "RootArgument",
      "name": "specialItemId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "product",
          "comments"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "product",
          "images"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "product",
          "questions"
        ]
      }
    ]
  },
  "name": "Product_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StoreCategories_viewer"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEditor",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            (v1/*: any*/)
          ],
          "concreteType": "CartItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CartItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CartItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClearanceItem",
                      "kind": "LinkedField",
                      "name": "clearanceItem",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Product",
                      "kind": "LinkedField",
                      "name": "product",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:10000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "clearanceItemId",
          "variableName": "clearanceItemId"
        }
      ],
      "concreteType": "ClearanceItem",
      "kind": "LinkedField",
      "name": "clearanceItem",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filterBy",
          "value": {
            "isActive": true,
            "parentId": 0
          }
        },
        (v1/*: any*/)
      ],
      "concreteType": "CategoryConnection",
      "kind": "LinkedField",
      "name": "categories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CategoryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Category",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": (v8/*: any*/),
                  "concreteType": "CategoryConnection",
                  "kind": "LinkedField",
                  "name": "categories",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CategoryEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Category",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v6/*: any*/),
                            (v7/*: any*/),
                            {
                              "alias": null,
                              "args": (v8/*: any*/),
                              "concreteType": "CategoryConnection",
                              "kind": "LinkedField",
                              "name": "categories",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CategoryEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Category",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v6/*: any*/),
                                        (v7/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "categories(filterBy:{\"isActive\":true})"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "categories(filterBy:{\"isActive\":true})"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "categories(filterBy:{\"isActive\":true,\"parentId\":0},first:10000)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "productId",
          "variableName": "productId"
        }
      ],
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canAddToCart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "package",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pack",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sizeUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        (v5/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availabilityStatus",
          "storageKey": null
        },
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVariable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": [
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Category",
          "kind": "LinkedField",
          "name": "categories",
          "plural": true,
          "selections": [
            (v10/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "comments",
          "args": [
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": [
                [
                  "created",
                  "DESC"
                ]
              ]
            }
          ],
          "concreteType": "CommentConnection",
          "kind": "LinkedField",
          "name": "__Product_comments_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CommentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Comment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "comment",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rating",
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                },
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            (v13/*: any*/)
          ],
          "storageKey": "__Product_comments_connection(orderBy:[[\"created\",\"DESC\"]])"
        },
        {
          "alias": "images",
          "args": null,
          "concreteType": "ImageConnection",
          "kind": "LinkedField",
          "name": "__Product_images_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isPrimary",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "previewUrl",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                },
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            (v13/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "questions",
          "args": null,
          "concreteType": "QuestionConnection",
          "kind": "LinkedField",
          "name": "__Product_questions_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Question",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v10/*: any*/),
                    (v14/*: any*/),
                    {
                      "alias": "answers",
                      "args": null,
                      "concreteType": "AnswerConnection",
                      "kind": "LinkedField",
                      "name": "__Question_answers_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AnswerEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Answer",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v14/*: any*/),
                                (v11/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v12/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v13/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                },
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            (v13/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "specialItemId",
          "variableName": "specialItemId"
        }
      ],
      "concreteType": "SpecialItem",
      "kind": "LinkedField",
      "name": "specialItem",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v0/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "90b52683beac2cd6418e88765c684d51";

export default node;
