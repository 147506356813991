import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Grid, Modal, Popup} from 'semantic-ui-react';
import styled from 'styled-components';
import formatTime from '../../helpers/formatTime';

const Container = styled.div`
  margin-left: 25px;
`;

const StyledModal = styled(Modal)`
  & .current h3 {
    color: #090;
  }

  & .next h3 {
    color: #06c;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-content: center;

  padding: 0 20px;
  height: 66px;
  cursor: pointer;

  & > p {
    align-self: center;
    color: #555;
    font-size: 15px;
  }

  &:hover {
    background-color: rgb(245, 245, 245);
  }

  & > div {
    align-self: center;
  }

  & > div:first-child {
    padding-right: 10px;
    color: #090;
    font-size: 15px;
    font-weight: bold;
    text-align: right;
    text-decoration: underline;
  }

  & > div:last-child {
  }

  & > div > div {
    display: flex;
    color: #555;
    font-size: 15px;
  }

  & > div > div > div:first-child {
    width: 70px;
    font-weight: bold;
    text-align: left;
  }

  & > div > div > div:last-child {
    flex: 1;

    text-align: right;
  }
`;

class Deadlines extends React.Component {
  state = {
    isOpen: false,
  };

  handleOpen = () => {
    const isLogged = (get(this.props.viewer, 'user.role') || 'guest') !== 'guest';
    if (isLogged) {
      this.setState({isOpen: true});
    } else {
      this.props.router.push(`/login?redirect=${window.location.pathname}`);
    }
  };

  handleClose = () => this.setState({isOpen: false});

  render() {
    const isLogged = (get(this.props.viewer, 'user.role') || 'guest') !== 'guest';
    if (!isLogged) {
      return (
        <MenuItem onClick={this.handleOpen}>
          <p>
            <strong style={{color: '#2196f3'}}>Log in</strong> to see deadline <br />
            and pick up dates
          </p>
        </MenuItem>
      );
    }

    const currentCycle = get(this.props.viewer, 'coop.currentCycle') || {};
    const nextCycle = get(this.props.viewer, 'coop.nextCycle') || {};

    return (
      <Container>
        <StyledModal centered={true} onClose={this.handleClose} open={this.state.isOpen} size="small">
          <Modal.Content>
            <Grid>
              <Grid.Column className="current" width={8}>
                <h3>Current Delivery</h3>
                <h4>Order Deadline</h4>
                <p>{DateTime.fromSQL(currentCycle.deadline).toFormat('ccc, LL/dd')}, 11:59 PM</p>
                <h4>Pick Up Date </h4>
                <p>
                  {DateTime.fromISO(currentCycle.pickup).toFormat('ccc, LL/dd')}, {formatTime(currentCycle.pickupStart, null, true)} at
                  <br />
                  {currentCycle.pickupStreet}, {currentCycle.pickupZip}
                </p>
                {String(currentCycle.deliveryStatus || '').trim().length > 0 && (
                  <React.Fragment>
                    <h4>Order Status</h4>
                    <p>{currentCycle.deliveryStatus}</p>
                  </React.Fragment>
                )}
              </Grid.Column>
              <Grid.Column className="next" width={8}>
                <h3>Next Delivery</h3>
                <h4>Order Deadline</h4>
                <p>{DateTime.fromSQL(nextCycle.deadline).toFormat('ccc, LL/dd')}, 11:59 PM</p>
                <h4>Pick Up Date </h4>
                <p>
                  {DateTime.fromISO(nextCycle.pickup).toFormat('ccc, LL/dd')}, {formatTime(nextCycle.pickupStart)} at
                  <br />
                  {nextCycle.pickupStreet}, {nextCycle.pickupZip}
                </p>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </StyledModal>
        <Popup
          content="Click here for pick up locations."
          inverted
          trigger={
            <MenuItem onClick={this.handleOpen}>
              <div>
                Current
                <br />
                Order
              </div>
              <div>
                <div>
                  <div>Deadline</div>
                  <div>{DateTime.fromSQL(currentCycle.deadline).toFormat('ccc, LL/dd')}</div>
                </div>
                <div>
                  <div>Pickup</div>
                  <div>{DateTime.fromISO(currentCycle.pickup).toFormat('ccc, LL/dd')}</div>
                </div>
              </div>
            </MenuItem>
          }
        />
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Deadlines), {
  viewer: graphql`
    fragment Deadlines_viewer on Viewer {
      coop {
        currentCycle {
          deadline
          pickup
          pickupStart
          pickupStreet
          pickupZip
          deliveryStatus
        }
        nextCycle {
          deadline
          pickup
          pickupStart
          pickupStreet
          pickupZip
        }
      }
      user {
        role
      }
    }
  `,
});
