/**
 * @generated SignedSource<<41a2f8420889f7f9a85246a4d335a80b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartSubstitutions_item$data = {
  readonly substitutions: ReadonlyArray<{
    readonly id: string;
    readonly productId: string | null | undefined;
    readonly rowId: number | null | undefined;
    readonly substitutionId: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "CartSubstitutions_item";
};
export type CartSubstitutions_item$key = {
  readonly " $data"?: CartSubstitutions_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartSubstitutions_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartSubstitutions_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Substitution",
      "kind": "LinkedField",
      "name": "substitutions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "substitutionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CartItem",
  "abstractKey": null
};

(node as any).hash = "6c5c320b6621c815e17099665b834127";

export default node;
