import {withRouter} from 'found';
import styled from 'styled-components';
import {go} from '../../../helpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  h2 {
    color: #e65100;
    text-align: center;
  }

  > p {
    margin-bottom: 3em;
    text-align: center;
  }

  a {
    color: #f57c00;
    :hover {
      color: #e65100;
      text-decoration: underline;
    }
  }

  > div {
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;
      text-align: center;

      max-width: 50%;
      min-width: 300px;
      margin: 0 auto;

      :first-child {
        h3,
        h4 {
          color: #e65100;
          color: #2196f3;
        }
      }

      :last-child {
        h3,
        h4 {
          color: #f57c00;
        }
      }

      h3 {
        text-align: center;
      }

      > div {
        display: flex;
        // align-items: center;

        margin: 0 0 2em 0;
        // border: 1px solid red;

        @media (max-width: 600px) {
          flex-direction: column;

          > img {
            margin: 0 auto;
            width: 40% !important;
            height: 40% !important;
          }
        }

        > div {
          margin: 1em;
          text-align: left;

          h4 {
            margin: 0;
            color: #111;
          }

          p {
            margin: 10px 0 0 0;
          }
        }

        img {
          width: 30%;
          height: 30%;
          // border: 1px solid #0f0;
        }
      }
    }
  }
`;

const HowItWorks = (props) => (
  <Container>
    <h2 style={{width: '100%'}}>How the Co-op works</h2>
    <p>
      The easiest way to order from the Co-ops is to join one of our <a href="/#join-a-co-op">22 communities</a> across the United States.
      <br />
      If there is no community nearby, you can <a href="/#start-a-co-op">start your own Co-op</a>.
      {/* or get your order{' '}
      <a href="/#ship-to-your-home">shipped directly to your home</a>.*/}
    </p>
    <div>
      <div>
        {/* <h3>Ship to your CO-OP</h3> */}
        <div>
          <img alt="Join a Co-op" src="/next/img/infographics/1-1.jpg" />
          <div>
            <h4>Join a Co-op</h4>
            <p>
              We serve more than 20 locations across the USA. Find the one closest to you and{' '}
              <a href="/register" onClick={go('/register', {router: props.router})}>
                join for free
              </a>{' '}
              or easily start <a href="/register">your own Co-op</a>.
            </p>
          </div>
        </div>
        <div>
          <img alt="Place an Order" src="/next/img/infographics/1-2.jpg" />
          <div>
            <h4>Place an Order</h4>
            <p>Find the products you want and place your order before the next deadline.</p>
          </div>
        </div>
        <div>
          <img alt="Deliver to Central Location" src="/next/img/infographics/1-3.jpg" />
          <div>
            <h4>Delivery to a Central Location</h4>
            <p>
              We make a delivery to your Co-op location where you come and pick your items up. The times are always listed on the upper
              right of the website.
            </p>
          </div>
        </div>
        <div>
          <img alt="Save on Products + Shipment" src="/next/img/infographics/1-4.jpg" />
          <div>
            <h4>Save on Products + Shipment</h4>
            <p>Batching orders this way helps us reduce your product and shipping costs.</p>
          </div>
        </div>
      </div>

      {/* <div>
        <h3>Ship to your HOME</h3>
        <div>
          <img src="/next/img/infographics/2-1.jpg" alt="Contact Us" />
          <div>
            <h4>Contact Us</h4>
            <p>
              If there is no Co-op community nearby, you can still save on Kosher products &mdash;{' '}
              <a href="/contact" onClick={go('/contact', {router: props.router})}>
                just let us know
              </a>
              .
            </p>
          </div>
        </div>
        <div>
          <img src="/next/img/infographics/2-2.jpg" alt="Place an Order" />
          <div>
            <h4>Place an Order</h4>
            <p>Find the products you want and place your order before the next deadline.</p>
          </div>
        </div>
        <div>
          <img src="/next/img/infographics/2-3.jpg" alt="We Ship Directly to Your Home" />
          <div>
            <h4>We Ship Directly to Your Home</h4>
            <p>
              Your items will ship directly to your door at the same time the next delivery cycle ships (2 to 4 weeks
              after you place your order).
            </p>
          </div>
        </div>
        <div>
          <img src="/next/img/infographics/2-4.jpg" alt="Save on Products" />
          <div>
            <h4>Save on Products</h4>
            <p>
              You may have to pay a little extra for shipping the items, but you will still get all the savings from
              product costs.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  </Container>
);

export default withRouter(HowItWorks);
