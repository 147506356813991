import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddAnswerMutation($input: AddAnswerInput!) {
    addAnswer(input: $input) {
      answerEdge {
        __typename
        node {
          id
          content
        }
      }
    }
  }
`;

export const AddAnswerMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        connectionInfo: [
          {
            key: 'Question_answers',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'answerEdge',
        parentID: input.questionId,
        type: 'RANGE_ADD',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['questionId', 'content']),
    },
  });
};
