import {DateTime, money} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {createFragmentContainer} from 'react-relay';
import {Popup, Progress} from 'semantic-ui-react';
import styled from 'styled-components';
import formatTime from '../../../helpers/formatTime';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    flex: 1;
    padding-bottom: 3em;

    & h4 {
      color: #e65100;
    }

    & h5 {
      margin: 0 0 0.25rem 0;
    }

    & p {
      small {
        color: #999;
      }
    }

    & table {
      margin-top: -10px;
      width: 100%;

      & th,
      & td {
        padding: 5px 0;
      }

      & th {
        width: 50%;
        color: #f57c00;
        text-align: left;
      }
    }

    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: left;

      & > div:nth-child(2) {
        margin-top: 1em;
        width: 300px;
        height: 300px;
        transform: rotate(-90deg);

        & > p {
          font-size: 1.5em;
          text-align: center;

          & span {
            font-weight: bold;
          }

          & span:nth-child(1) {
            color: #2196f3 !important;
          }

          & span:nth-child(2) {
            color: #66da81 !important;
          }
        }

        & > div:nth-child(2) {
          margin-left: 40%;
          width: 60%;
          height: 40%;

          display: flex;
          flex-direction: column;
          align-items: center;
          transform: rotate(90deg);

          & h3 {
            margin: 0.5em 0;
            color: #d95c5c;
          }

          & span {
            color: #aaa;
          }

          & h3:last-child {
            color: #66da81;
          }
        }
      }

      & > :nth-child(3) {
        margin-top: 1em;
      }
    }

    &:nth-child(2) {
      flex: 0.6;
    }

    &:nth-child(3) {
      flex: 0.6;
    }

    @media (max-width: 768px) {
      & {
        flex: 1 !important;
        margin: 0 auto !important;
        min-width: 330px;
      }
    }
  }
`;

const DeliveryFeesNote = styled.div`
  max-width: 480px;
  min-width: 300px;
  width: 100%;

  h3 {
    margin: 0 0 1em 0;
    font-size: 18px;
  }

  h4 {
    margin: 1em 0 1em 0;
    font-size: 18px;
  }

  p {
    margin: 0 0 0.5em 0;
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 0 0.5em 0;
    font-size: 16px;
    list-style: none;

    & :last-child span {
      color: #f57c00;
    }

    & :first-child span {
      color: #2196f3;
    }
  }
`;

const OrderDates = (props) => {
  const coop = props && props.viewer && props.viewer.coop;
  const DEFAULT_TIMEZONE = 'America/Chicago';
  const timezone = (coop && coop.timezone) || DEFAULT_TIMEZONE;

  const currentCycle = (coop && coop.currentCycle) || null;

  const {freeShippingGoal, freeShippingValue} = currentCycle;
  let progress = Math.round((freeShippingValue / freeShippingGoal) * 100);
  progress = progress > 100 ? 100 : progress;

  const pickupStart =
    currentCycle && currentCycle.pickup && currentCycle.pickupStart ? currentCycle.pickup + ' ' + currentCycle.pickupStart : null;
  const pickupEnd =
    currentCycle && currentCycle.pickup && currentCycle.pickupEnd ? currentCycle.pickup + ' ' + currentCycle.pickupEnd : null;
  const pickupAddress = currentCycle.pickupStreet + ', ' + currentCycle.pickupZip;

  const nextCycles = (coop && coop.nextCycles) || [];

  return (
    <Container>
      <div>
        <h4>Free Delivery Indicator</h4>
        <div>
          <Progress indicating percent={progress} size="big" />
          <div>
            {progress < 100 ? (
              <>
                <h3>{money(freeShippingValue, true)}</h3>
                <span>of</span>
                <h3> {money(freeShippingGoal, true)}</h3>
              </>
            ) : (
              <p>
                <span>Congrats</span>, the community reached $7,000 so delivery is <span>FREE</span>
              </p>
            )}
          </div>
        </div>
        <div>
          <p style={{marginRight: '1em'}}>
            90% of communities reach order minimum which removes all delivery charges. Learn more about{' '}
            <Popup
              content={
                <DeliveryFeesNote>
                  <h3>Free deliveries</h3>
                  <p>Delivery fee will only be charged if the community does not reach the combined $7,000 minimum order.</p>
                  <p>
                    If you pay via check or credit card online we will automatically adjust payment before processing for actual delivery
                    tier (which is often free).
                  </p>
                  <p>If you paid via physical check, the delivery fee will be automatically put as a credit on to your account.</p>
                  <h4>Delivery Tiers</h4>
                  <ul>
                    <li>
                      $7000+ - <span>Free Delivery!</span>
                    </li>

                    <li>
                      $6000 - $7000 - <span>3% Fee</span>
                    </li>
                    <li>
                      $5000 - $6000 - <span>5% Fee</span>
                    </li>
                    <li>
                      $4000 - $5000 - <span>7% Fee</span>
                    </li>
                    <li>
                      $3000 - $4000 - <span>10% Fee</span>
                    </li>
                    <li>
                      {/* Under $3000 - <span>Ship to Home Option</span> (coming soon) */}
                      Under $3000 - Not shipping
                    </li>
                  </ul>
                </DeliveryFeesNote>
              }
              on="click"
              position="bottom left"
              trigger={<span style={{color: '#2196f3', cursor: 'pointer'}}>free deliveries</span>}
            />
            .
          </p>
        </div>
      </div>
      <div>
        <h4>Current Delivery</h4>
        <h5>Order Deadline</h5>
        <p>{DateTime.fromSQL(`${currentCycle.deadline} 23:59:59 ${timezone}`, {setZone: true}).toFormat('ccc, LL/dd, h:mm a')}</p>
        <h5>Delivery Date</h5>
        <p>
          {DateTime.fromSQL(pickupStart).toFormat('LLL d')}, {formatTime(pickupStart, pickupEnd)}
        </p>
        <h5>Delivery Location</h5>
        <p>{pickupAddress}</p>
      </div>
      <div>
        <h4>Upcoming Deliveries</h4>
        <table>
          <tbody>
            <tr>
              <th>Order Deadline</th>
              <th>Delivery Date</th>
            </tr>
            {nextCycles.map((nextCycle) => (
              <tr key={nextCycle.id}>
                <td>{DateTime.fromSQL(`${nextCycle.deadline} 23:59:59 ${timezone}`, {setZone: true}).toFormat('DD')}</td>
                <td>{DateTime.fromSQL(nextCycle.pickup).toFormat('DD')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

export default createFragmentContainer(OrderDates, {
  viewer: graphql`
    fragment OrderDates_viewer on Viewer {
      coop {
        timezone
        currentCycle {
          freeShippingGoal
          freeShippingValue
          deadline
          pickup
          pickupStart
          pickupEnd
          pickupStreet
          pickupZip
        }
        nextCycles {
          id
          deadline
          pickup
        }
      }
      user {
        role
      }
    }
  `,
});
