import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import {createFragmentContainer} from 'react-relay';
import {Button, Dropdown, Form, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {CP_ACCESS_TOKEN} from '../constants';
import {states} from '../helpers/states';
import {RegisterMutation} from '../mutations/RegisterMutation';

const Container = styled(Form)`
  min-height: 100vh;
  padding-bottom: 50px;
  // background: #f7f7f7;
`;

const Content = styled.div`
  margin: 20px auto;
  max-width: 700px;
`;

const Column = styled.div`
  margin-bottom: 20px;
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Heading = styled.h3``;

const Title = styled.h1`
  font-weight: normal;
  font-size: 21px;
`;

const Section = styled.div`
  padding: 0 0 ${(props) => (props.last ? 0 : 1)}em 0;
`;

const Hr = styled.div`
  margin: 30px 0 50px 0;
  line-height: 16px;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
  & span {
    position: relative;
    bottom: -8px;
    background: #fff;
    text-align: center;
    padding: 0 15px;
    font-weight: bold;
  }
`;

const Disclaimer = styled.p`
  margin-bottom: 40px;
  font-size: 17px;
`;

function formatPhoneNumber(s) {
  const s2 = ('' + s).replace(/\D/g, '').substr(0, 10).padEnd(10, '_');
  const m = s2.match(/^([0-9_]{3})([0-9_]{3})([0-9_]{4})$/);
  return !m ? null : '(' + m[1] + ') ' + m[2] + '-' + m[3];
}

function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line max-len
  return re.test(email);
}

class Register extends React.Component {
  cityIdOptions = [];
  recaptchaRef = React.createRef();

  state = {
    captcha: null,
    cityId: {error: false, value: 0},
    city: {error: false, value: ''},
    email: {error: false, value: ''},
    firstName: {error: false, value: ''},
    isSubmitting: false,
    lastName: {error: false, value: ''},
    messages: [],
    optoutSms: {error: false, value: true},
    password: {error: false, value: ''},
    password2: {error: false, value: ''},
    phone: {error: false, value: ''},
    phone2: {error: false, value: ''},
    state: {error: false, value: ''},
    street: {error: false, value: ''},
    zip: {error: false, value: ''},
  };

  constructor(props) {
    super(props);

    this.cityIdOptions = props.viewer.coops.edges.map(({node}) => ({
      key: node.id,
      text: node.name,
      value: node.rowId,
    }));

    const coop = window.location.search.replace('?coop=', '').replace('%20', ' ').trim();
    if (coop.length) {
      this.state.cityId = this.cityIdOptions.reduce(
        (p, c) => (c.text.indexOf(coop) === 0 ? {error: false, value: c.value} : p),
        this.state.cityId,
      );
    }
  }

  go = (link) => (e) => {
    e.preventDefault();
    this.props.router.push(link);
  };

  handleAddressChange =
    (suffix) =>
    (e, {name, value}) =>
      this.setState({[`${suffix}${name.substr(0, 1).toUpperCase()}${name.substr(1)}`]: value});

  handleCaptcha = (captcha) => {
    this.setState({captcha});
  };

  handleChange = (e, {checked, name, type, value}) => {
    if (type === 'checkbox') {
      value = checked;
    }

    if (typeof value === String) {
      value = value.trim();
    }

    if (name === 'phone' || name === 'phone2') {
      value = value.replace(/[()_\-a-zA-Z]/g, '').trim();
      value = value ? formatPhoneNumber(value) : '';
    }

    //
    let error = false;
    if (['cityId', 'firstName', 'lastName', 'street', 'city', 'state', 'zip'].indexOf(name) !== -1) {
      if (value === '') {
        error = true;
      }
    } else if (name === 'email') {
      if (!isValidEmail(value)) {
        error = true;
      }
    } else if (name === 'password2') {
      if (value !== this.state.password.value) {
        error = true;
      }
    } else if (name === 'phone') {
      if (value === '' || value.indexOf('_') !== -1) {
        error = true;
      }
    }

    this.setState((prevState) => ({[name]: {...prevState[name], error, value}}));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    window.scrollTo(0, 0);

    this.setState({isSubmitting: true});

    const captcha = this.recaptchaRef.current.getValue() || null;

    const {city, cityId, email, firstName, lastName, optoutSms, password, password2, phone, phone2, state, street, zip} = this.state;

    // Validations
    let error = false;
    const errors = {
      city: {...city, error: false},
      cityId: {...cityId, error: false},
      email: {...email, error: false},
      firstName: {...firstName, error: false},
      lastName: {...lastName, error: false},
      optoutSms: {...optoutSms, error: false},
      password: {...password, error: false},
      password2: {...password2, error: false},
      phone: {...phone, error: false},
      phone2: {...phone2, error: false},
      state: {...state, error: false},
      street: {...street, error: false},
      zip: {...zip, error: false},
    };
    let messages = [];

    if (!cityId.value) {
      error = true;
      errors.cityId = {...cityId, error: true};
      messages.push('Please select a Co-op.');
    }

    if (!isValidEmail(email.value)) {
      error = true;
      errors.email = {...email, error: true};
      messages.push('Please use a valid email address.');
    }

    if (password.value.length < 6 || password2.value.length < 6) {
      if (password.value.length < 6) {
        error = true;
        errors.password = {...password, error: true};
      }

      if (password2.value.length < 6) {
        error = true;
        errors.password2 = {...password2, error: true};
      }

      messages.push('Please use a password longer than 6 characters.');
    }

    if (password.value !== password2.value) {
      error = true;
      errors.password = {...password, error: true};
      errors.password2 = {...password2, error: true};
      messages.push('Entered passwords do not match.');
    }

    if (phone.value === '' || phone.value.indexOf('_') !== -1) {
      error = true;
      errors.phone = {...phone, error: true};
      messages.push('Please provide your phone number.');
    }

    if (firstName.value === '') {
      error = true;
      errors.firstName = {...firstName, error: true};
      messages.push('Please provide your first name.');
    }

    if (lastName.value === '') {
      error = true;
      errors.lastName = {...lastName, error: true};
      messages.push('Please provide your last name.');
    }

    if (street.value === '') {
      error = true;
      errors.street = {...street, error: true};
      messages.push('Please provide your street address.');
    }

    if (city.value === '') {
      error = true;
      errors.city = {...city, error: true};
      messages.push('Please provide your city.');
    }

    if (state.value === '') {
      error = true;
      errors.state = {...state, error: true};
      messages.push('Please provide your state.');
    }

    if (zip.value === '') {
      error = true;
      errors.zip = {...zip, error: true};
      messages.push('Please provide your Zip.');
    }

    if (!String(captcha || '').trim().length) {
      error = true;
      errors.captcha = {...captcha, error: true};
      messages.push('Please click the Captcha verification');
    }

    if (error) {
      console.log('error', errors);
      this.setState({isSubmitting: false, messages, ...errors});
      return;
    }

    //
    const allowed = [
      'cityId',
      'email',
      'password',
      'password2',
      'firstName',
      'lastName',
      'street',
      'city',
      'state',
      'zip',
      'phone',
      'phone2',
      'optoutSms',
    ];

    //
    const payload = {
      input: {
        ...Object.keys(this.state)
          .filter((f) => allowed.indexOf(f) >= 0)
          .reduce((p, key) => ({...p, [key]: this.state[key].value}), {}),
        captcha,
        optoutSms: !optoutSms.value, // Reverse the value
      },
    };

    const onSuccess = (response) => {
      if (response.register.errors) {
        const messages = [];
        const errors = response.register.errors.reduce((p, c) => {
          if (c.field === 'captcha') {
            this.recaptchaRef.current.reset();
          }
          messages.push(c.message);
          return {...p, [c.field]: {...this.state[c.field], error: true}};
        }, {});
        this.setState({isSubmitting: false, messages, ...errors});
      } else {
        const {
          register: {accessToken},
        } = response;
        Cookies.set(CP_ACCESS_TOKEN, accessToken, {expires: 7});
        this.props.router.push('/');
        // TODO: Change the environment without reload ?
        window.location.reload();
      }
    };

    const onFailure = (response) => {
      this.setState({isSubmitting: false});
      console.log('Fail', response);
    };

    RegisterMutation(payload, onSuccess, onFailure);
  };

  render() {
    const {city, cityId, email, firstName, lastName, messages, optoutSms, password, password2, phone, phone2, state, street, zip} =
      this.state;

    return (
      <Container error={!isEmpty(messages)} onSubmit={this.handleSubmit}>
        <Content>
          <Heading style={{textAlign: 'center'}}>Create your account</Heading>
          <Message
            content={<div dangerouslySetInnerHTML={{__html: messages.join('<br />')}} />}
            error
            header="Please fix the following problems"
            visible={Boolean(messages.length)}
          />
          <Column>
            <Inner>
              <Section>
                <Title>Co-op information</Title>
                <Form.Dropdown
                  error={cityId.error}
                  label="Select your Co-op"
                  name="cityId"
                  onChange={this.handleChange}
                  options={this.cityIdOptions}
                  search
                  selection
                  value={cityId.value}
                />
              </Section>
              <br />

              <Section>
                <Title>Account information</Title>
                <Form.Group widths="equal">
                  <Form.Input
                    error={email.error}
                    label="Email"
                    name="email"
                    onChange={this.handleChange}
                    placeholder=""
                    value={email.value}
                  />
                  <Form.Input
                    error={password.error}
                    label="Password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="At least 6 characters"
                    type="password"
                    value={password.value}
                  />
                  <Form.Input
                    error={password2.error}
                    label="Re-enter password"
                    name="password2"
                    onChange={this.handleChange}
                    placeholder=""
                    type="password"
                    value={password2.value}
                  />
                </Form.Group>
              </Section>
              <br />

              <Section>
                <div>
                  <Title>Address Informaton</Title>
                </div>
                <br />
                <Form.Group widths="equal">
                  <Form.Input
                    error={firstName.error}
                    label="First Name"
                    name="firstName"
                    onChange={this.handleChange}
                    placeholder="First Name"
                    size="small"
                    value={firstName.value}
                  />
                  <Form.Input
                    error={lastName.error}
                    label="Last Name"
                    name="lastName"
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    size="small"
                    value={lastName.value}
                  />
                </Form.Group>
                <Form.Input
                  error={street.error}
                  label="Address"
                  name="street"
                  onChange={this.handleChange}
                  placeholder="Address"
                  size="small"
                  value={street.value}
                />
                <Form.Group>
                  <Form.Input
                    error={city.error}
                    label="City"
                    name="city"
                    onChange={this.handleChange}
                    placeholder="City"
                    size="small"
                    value={city.value}
                    width={6}
                  />
                  <Form.Field
                    control={Dropdown}
                    error={state.error}
                    label="State"
                    name="state"
                    onChange={this.handleChange}
                    options={states}
                    search
                    selection
                    size="small"
                    value={state.value}
                    width={6}
                  />
                  <Form.Input
                    error={zip.error}
                    label="Zip"
                    name="zip"
                    onChange={this.handleChange}
                    placeholder="Zip"
                    size="small"
                    value={zip.value}
                    width={4}
                  />
                </Form.Group>
              </Section>
              <br />

              <Section last>
                <Title>Contact information</Title>
                <Form.Group widths="equal">
                  <Form.Input
                    error={phone.error}
                    label="Main phone"
                    name="phone"
                    onChange={this.handleChange}
                    placeholder="(XXX) XXX-XXXX"
                    value={phone.value}
                  />
                  <Form.Input
                    error={phone2.error}
                    label="Mobile phone"
                    name="phone2"
                    onChange={this.handleChange}
                    placeholder="Used for text notifications"
                    value={phone2.value}
                  />
                </Form.Group>
                {String(phone2.value).trim().length > 0 && (
                  <Form.Checkbox
                    checked={optoutSms.value}
                    label={{
                      children: <p>I would like to be notified by a text message when order is ready for pick up.</p>,
                    }}
                    name="optoutSms"
                    onChange={this.handleChange}
                  />
                )}
              </Section>
            </Inner>

            <Inner>
              <Recaptcha ref={this.recaptchaRef} sitekey="6Lc2SGgUAAAAANXem9d4AIJ3U06spInb1URGhTJv" />
              <br />
            </Inner>

            <div style={{padding: '20px 20px'}}>
              <Disclaimer>
                By clicking <strong>Create Account</strong>, you acknowledge that you have read and agreed to our{' '}
                <a href="/terms" target="_blank">
                  Terms of Use
                </a>{' '}
                {/* and{' '}
                <a href="/privacy" target="_blank">
                  Privacy Policy
                </a> */}
                .
              </Disclaimer>
              <Form.Button disabled={this.state.isSubmitting} fluid primary size="large" type="submit">
                Create Account
              </Form.Button>
              <Hr>
                <span>Already have an account?</span>
              </Hr>
              <Button basic color="blue" fluid onClick={this.go('/login')}>
                Sign In
              </Button>
            </div>
          </Column>
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Register), {
  viewer: graphql`
    fragment Register_viewer on Viewer {
      coops(first: 9999) {
        edges {
          node {
            id
            rowId
            name
          }
        }
      }
    }
  `,
});
