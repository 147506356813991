import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import getCartItemForProduct from '../../../helpers/getCartItemForProduct';
import ProductItem from '../../Products/ProductItem';

const Container = styled.div`
  & .heading {
    display: flex;
    justify-contnet: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 1em;
    }

    width: 100%;
    min-height: 3rem;

    & h4 {
      margin: 0;
      flex: 1;
      color: #e65100;
    }

    & a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & > div {
  }
`;

const WhatsOnSale = (props) => {
  const viewer = (props && props.viewer) || {};
  const cart = viewer.cart || {};
  const cartItems = (cart?.items?.edges || []).map(({node}) => node);

  const clearanceItems = (viewer.clearanceItems?.edges || []).map(({node}) => node);
  const specialItems = (viewer.specialItems?.edges || []).map(({node}) => node);

  return (
    <Container>
      <div className="heading">
        <h4>Specials Products</h4>
        {specialItems.length > 6 && <Link to="/categories/specials">View more ›</Link>}
      </div>
      <ProductList>
        {specialItems.length === 0 && <p>We don't have any products on Special at the moment, please check later.</p>}
        {specialItems.slice(0, 6).map((specialItem) => (
          <ProductItem
            cart={cart}
            cartItem={getCartItemForProduct(cartItems, specialItem.product.id, {specialItemId: specialItem.id})}
            key={specialItem.id}
            product={specialItem.product}
            specialItem={specialItem}
            viewer={viewer}
          />
        ))}
      </ProductList>

      <br />
      <br />

      <div className="heading">
        <h4>Clearance Products</h4>
        {clearanceItems.length > 6 && <Link to="/categories/clearance">View more ›</Link>}
      </div>
      <ProductList>
        {clearanceItems.length === 0 && <p>We don't have any products on Clearance at the moment, please check later.</p>}
        {clearanceItems.slice(0, 6).map((clearanceItem) => (
          <ProductItem
            cart={cart}
            cartItem={getCartItemForProduct(cartItems, clearanceItem.product.id, {clearanceItemId: clearanceItem.id})}
            clearanceItem={clearanceItem}
            key={clearanceItem.id}
            product={clearanceItem.product}
            viewer={viewer}
          />
        ))}
      </ProductList>
    </Container>
  );
};

export default createFragmentContainer(withRouter(WhatsOnSale), {
  viewer: graphql`
    fragment WhatsOnSale_viewer on Viewer {
      ...ProductItem_viewer
      cart {
        ...ProductItem_cart
        items(first: 10000) {
          edges {
            node {
              ...ProductItem_cartItem
              clearanceItemId
              productId
              specialItemId
            }
          }
        }
      }
      clearanceItems(limit: 8, offset: 0, orderBy: [["products.rank", "ASC"]]) {
        edges {
          node {
            cost
            id
            product {
              id
              ...ProductItem_product
            }
            qty
            unit
          }
        }
      }
      specialItems(limit: 8, offset: 0, orderBy: [["products.rank", "ASC"]]) {
        edges {
          node {
            cost
            id
            product {
              id
              ...ProductItem_product
            }
            unit
          }
        }
      }
    }
  `,
});
