import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation RegisterMutation($input: RegisterInput!) {
    register(input: $input) {
      node {
        __typename
        id
        email
        firstName
        lastName
      }
      accessToken
      errors {
        field
        message
      }
    }
  }
`;

export const RegisterMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, [
        'cityId',
        'email',
        'password',
        'firstName',
        'lastName',
        'street',
        'city',
        'state',
        'zip',
        'phone',
        'phone2',
        'captcha',
        'optoutSms',
      ]),
    },
  });
};
