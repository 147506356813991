import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {QueryRenderer, createFragmentContainer} from 'react-relay';
import {Button, Form, Label, Modal} from 'semantic-ui-react';
import styled from 'styled-components';
import environment from '../../environment';
import {decimal, go} from '../../helpers';
import {AddCartItemMutation} from '../../mutations/AddCartItemMutation';
import {DeleteCartItemMutation} from '../../mutations/DeleteCartItemMutation';
import {DeleteSplitRequestMutation} from '../../mutations/DeleteSplitRequestMutation';
import {SendSplitRequestMutation} from '../../mutations/SendSplitRequestMutation';
import {UpdateCartItemMutation} from '../../mutations/UpdateCartItemMutation';
import ProductQuery from '../../queries/ProductQuery';
import {Button2, OrangeButton2, RedButton2} from '../Common/Button';
import {CustomIcon} from '../CustomIcon/CustomIcon';
import Product from '../Product';
import ProductImage from './ProductImage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  margin: -1px 0 0 -1px;
  max-width: 202px;
  min-height: 410px;
  border: 1px solid ${(props) => (props.hover ? '#2196F3' : '#e5edec')};
  position: ${(props) => (props.hover ? 'relative' : 'static')};
  z-index: ${(props) => (props.hover ? '86' : '1')};
  position: relative;
  font-weight: 400;
  cursor: pointer;

  @media (max-width: 1680px) {
    min-width: 12.5%;
    min-width: 16.66%;
  }

  @media (max-width: 1440px) {
    min-width: 16.66%;
    min-width: 20%;
  }

  @media (max-width: 1280px) {
    min-width: 20%;
    min-width: 25%;
  }

  @media (max-width: 1024px) {
    min-width: 25%;
    min-width: 33%;
  }

  @media (max-width: 768px) {
    min-width: 33%;
  }

  @media (max-width: 640px) {
    min-width: 50%;
  }

  @media (max-width: 480px) {
    min-width: 50%;
  }
`;

const ProductInfo = styled.div`
  flex: 1;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;

  & > .ui.floating.label {
    top: 0.5em;
    margin-left: -2.5em !important;

    min-width: 35px;

    color: #fff;
    background-color: #2196f3 !important;
    background-color: #2196f3 !important;
    border-radius: 20px;

    font-size: 18px;
    text-align: center;
    font-weight: normal;
    text-rendering: optimizeLegibility;
  }
`;

const Media = styled.div`
  display: flex;
  justify-content: center;
`;

const Info = styled.div`
  margin-top: 15px;
  height: 88px;
`;

const Price = styled.div`
	color: #212121
	font-size: 19px;
	font-weight: bold;
  & > span {
		color: #9E9E9E;
		font-size: 0.8em;
		font-weight: normal;
  }
  & .icon {
    color: #2196f3;
  }
`;

const Name = styled.div`
  margin-top: 5px;
  color: #616161;
  font-size: 0.85rem;
  line-height: 1.33em;

  & > em {
    color: rgb(33, 150, 243);
  }
`;

const Package = styled.div`
  margin-top: 5px;
  color: #9e9e9e;
  font-size: 0.85rem;
  & .icon {
    color: #2196f3;
  }
`;

const Actions = styled.div`
  display: flex;

  width: 100%;
  height: 55px;
  padding: 10px 0;
  box-sizing: border-box;

  & > div {
    padding: 0 10px;
  }

  & > div:first-child {
    flex: 1;
  }

  & > div:nth-child(2) {
    padding-left: 0;
  }

  & > div:nth-child(2) button {
    color: #333;
    background-color: #e0e1e2;
  }

  & > div:nth-child(2) button:hover {
    background: #cacbcd;
  }

  & > div:nth-child(2) button:active {
    background: #bbb;
  }
`;

const ClearanceInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  color: #999;
  font-size: 14px;
`;

const OutOfStock = styled.div`
  color: red;
  font-size: 14px;
  text-align: center;
`;

//

// const Divider2 = styled(Divider)`
// 	margin: 2.5rem 0 !important;
// `;

const abbr = (str, max = 60, abbr = '…') => {
  return str.length > max ? str.substr(0, str.substr(0, max).lastIndexOf('')) + abbr : str;
};

const STATE_DEFAULTS = {
  errors: {
    messages: [],
  },
  from: '',
  isSubmitting: false,
  message: '',
  splitProduct: null,
  splitUser: null,
  subject: '',
  success: '',
  to: '',
};

class ProductItem extends React.Component {
  state = {
    isLoading: false,
    isModalOpen: false,
    isMouseOver: false,
    isProductModalOpen: false,
  };

  //

  handleSplit = (splitProduct, splitUser) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    const to = splitUser.fullName;
    const from = get(this, 'props.viewer.user.email') || null;
    const subject = `KC Kosher Co-op - ${splitProduct.fullName}`;

    /* eslint-disable max-len */
    const message = `Hi ${splitUser.firstName},
I am interested in arranging a split of ${
      splitProduct.fullName
    } on the kckoshercoop.com website. Please contact me so we can arrange who will purchase the product and then after (or at) delivery how we will meet to split the product and cost.

Thanks,
${get(this, 'props.viewer.user.fullName')}`;
    /* eslint-enable max-len */

    this.setState({
      from,
      message,
      splitProduct,
      splitUser,
      subject,
      to,
    });
  };

  handleFieldChange = (e, {name, type, value, ...rest}) => {
    if (type === 'checkbox') {
      value = rest.checked;
    }

    this.setState({[name]: value});
  };

  handleModalClose = (e) => {
    e && e.preventDefault();
    e && e.stopPropagation();

    this.setState({...STATE_DEFAULTS});
  };

  handleDeleteSplitRequest = (splitRequestId) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!window.confirm('Do you really want to delete your split request?')) {
      return;
    }

    const payload = {
      input: {
        splitRequestId,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        success: 'The Split Request has been deleted.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        error: '',
      });
    };

    DeleteSplitRequestMutation(payload, onSuccess, onFailure);
  };

  handleSendSplitRequest = (e) => {
    e.preventDefault();

    const payload = {
      input: {
        message: this.state.message,
        splitUserId: this.state.splitUser.id,
        subject: this.state.subject,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        ...STATE_DEFAULTS,
        success: 'Your split request has been sent.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        ...STATE_DEFAULTS,
        errors: {
          messages: ['There was a problem sending your Split'],
        },
      });
    };

    SendSplitRequestMutation(payload, onSuccess, onFailure);
  };

  renderModal = () => {
    const {from, isSubmitting, message, splitUser, subject, to} = this.state;

    if (!splitUser) {
      return null;
    }

    return (
      <Modal onClick={(e) => e.stopPropagation()} onClose={this.handleModalClose} open={true} size="small">
        <Modal.Header>Contact {splitUser.fullName}</Modal.Header>
        <Modal.Content>
          <Form>
            <p style={{marginTop: 0}}>
              <strong>To:</strong> {to}
            </p>
            <p>
              <strong>From:</strong> {from}
            </p>
            <p>
              <strong>Subject:</strong> {subject}
            </p>
            <Form.TextArea label="Message" name="message" onChange={this.handleFieldChange} rows={10} value={message} />
            <Button disabled={isSubmitting} onClick={this.handleSendSplitRequest} primary>
              Send Split Request
            </Button>{' '}
            <a href="#void" onClick={this.handleModalClose}>
              Cancel
            </a>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  renderProductModal(variables) {
    return (
      <QueryRenderer
        environment={environment}
        query={ProductQuery}
        render={({error, props}) => {
          if (error) {
            return null;
          } else if (props) {
            return <Product onClose={this.closeProductModal} viewer={props.viewer} />;
          }
          return null;
        }}
        variables={variables}
      />
    );
  }

  //

  openProductModal = () => {
    const {clearanceItem, product, specialItem} = this.props;
    const variantId = clearanceItem?.id || specialItem?.id || '';
    const url = `/products/${product.productId}/${variantId}`;
    this.props.router.push(url);
    // window.history.pushState({}, `KC Kosher Co-op - ${product.fullName}`, url);
    // this.setState({isProductModalOpen: true});
  };

  closeProductModal = (e, goBack = true) => {
    e.preventDefault();
    e.stopPropagation();

    if (goBack) {
      window.history.back();
    }

    this.setState({isProductModalOpen: false});
  };

  handleMouseEnter = () => {
    this.setState({
      isMouseOver: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isMouseOver: false,
    });
  };

  handleIncrease = () => (e) => {
    e.stopPropagation();

    this.setState({isLoading: true});

    const role = get(this.props, 'viewer.user.role') || 'guest';
    if (role === 'guest') {
      return this.props.router.push(`/login?redirect=${window.location.href}`);
    }

    const {cart, cartItem, clearanceItem, product, specialItem} = this.props;

    const onSuccess = () => this.setState({isLoading: false});
    const onFailure = () => this.setState({isLoading: false});

    if (cartItem && cartItem.qty > 0) {
      const payload = {
        input: {
          id: cartItem.id,
          qty: cartItem.qty + 1,
        },
      };

      UpdateCartItemMutation(payload, onSuccess, onFailure);
    } else {
      const payload = {
        input: {
          clearanceItemId: clearanceItem?.id,
          productId: product.productId,
          qty: 1,
          specialItemId: specialItem?.id,
          userId: cart.id,
        },
      };

      AddCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  handleDecrease = () => (e) => {
    e.stopPropagation();

    this.setState({isLoading: true});

    const {cartItem} = this.props;

    const onSuccess = () => this.setState({isLoading: false});
    const onFailure = () => this.setState({isLoading: false});

    if (cartItem.qty > 1) {
      const payload = {
        input: {
          id: cartItem.id,
          qty: cartItem.qty - 1,
        },
      };

      UpdateCartItemMutation(payload, onSuccess, onFailure);
    } else {
      const payload = {
        input: {
          id: cartItem.id,
        },
      };

      DeleteCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  render() {
    const {cartItem, clearanceItem, product, router, specialItem, splitRequest, viewer} = this.props;

    if (!product) {
      return null;
    }

    let coverImage = get(product, 'images.edges[0].node.url') || null;
    if (coverImage) {
      coverImage = coverImage.replace('-raw', '-images');
      coverImage = coverImage.replace('.jpg', '_small.jpg');
    }

    const viewerId = (viewer && viewer.user && viewer.user.id) || 'guest';
    const isMySplitRequest = splitRequest && splitRequest.user && splitRequest.user.id === viewerId;

    const isOnSale = clearanceItem || specialItem;

    const canAddToCart = product.canAddToCart || clearanceItem;

    const cost = clearanceItem?.cost || specialItem?.cost || product.cost;
    const unit = clearanceItem?.unit || specialItem?.unit || product.unit;

    return (
      <Container
        hover={this.state.isMouseOver}
        onClick={this.openProductModal}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        {splitRequest && this.renderModal()}
        {this.state.isProductModalOpen &&
          this.renderProductModal({
            clearanceItemId: clearanceItem?.id,
            productId: product.productId,
            specialItemId: specialItem?.id,
          })}
        <ProductInfo>
          {cartItem && (
            <Label circular color="blue" floating>
              {cartItem.qty}
            </Label>
          )}
          <Media>
            <ProductImage alt={product.fullName} size="160" src={coverImage} />
            {/* {cartItem && <QtySmall>{cartItem.qty}</QtySmall>} */}
          </Media>
          <Info>
            <Price>
              ${decimal(cost)}
              &nbsp;
              <span>
                ($
                {decimal(unit)} / {!product.isVariable && product.size + ' '}
                {product.sizeUnit})
                {product.isVariable && (
                  <>
                    &nbsp;
                    <CustomIcon
                      name="far-balance-scale"
                      onClick={go('/help#q1-6', {router})}
                      style={{color: '#2196f3', cursor: 'pointer'}}
                      title="Variable Weight Product"
                    />
                  </>
                )}
              </span>
            </Price>
            <Name>
              {isOnSale && (
                <CustomIcon
                  name={clearanceItem ? 'fas-badge-dollar' : 'fas-badge-percent'}
                  style={{color: clearanceItem ? '#eb5f00' : '#ff9800', fontSize: 20}}
                />
              )}{' '}
              {product.state === 'Frozen' && <em>Frozen</em>} {abbr(product.fullName)}
            </Name>
            <Package>{product.package} </Package>
          </Info>
        </ProductInfo>
        <Actions style={{height: 'auto', paddingBottom: 0}}>
          {splitRequest && !isMySplitRequest && (
            <div>
              <OrangeButton2 onClick={this.handleSplit(splitRequest.product, splitRequest.user)}>SPLIT WITH MEMBER</OrangeButton2>
            </div>
          )}
          {splitRequest && isMySplitRequest && (
            <div>
              <RedButton2 onClick={this.handleDeleteSplitRequest(splitRequest.id)}>CANCEL SPLIT REQUEST</RedButton2>
            </div>
          )}
        </Actions>
        <Actions>
          {canAddToCart && (
            <div>
              <Button2 onClick={this.handleIncrease()}>ADD TO CART</Button2>
            </div>
          )}
          {!canAddToCart && product.isOutOfStock && <OutOfStock>Out of stock</OutOfStock>}
          {cartItem && (
            <div>
              <Button2 onClick={this.handleDecrease()}>
                <CustomIcon name="fas-minus" />
              </Button2>
            </div>
          )}
        </Actions>
        {clearanceItem && <ClearanceInfo>Only {clearanceItem.qty} left.</ClearanceInfo>}
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(ProductItem), {
  cart: graphql`
    fragment ProductItem_cart on Cart {
      id
    }
  `,
  cartItem: graphql`
    fragment ProductItem_cartItem on CartItem {
      id
      qty
    }
  `,
  product: graphql`
    fragment ProductItem_product on Product {
      id
      productId
      canAddToCart
      isOutOfStock
      isVariable
      fullName
      package
      pack
      size
      sizeUnit
      state
      unit
      cost
      availability
      images(first: 100) {
        edges {
          node {
            url
          }
        }
      }
    }
  `,
  viewer: graphql`
    fragment ProductItem_viewer on Viewer {
      user {
        id
        role
        email
        firstName
        fullName
      }
    }
  `,
});
