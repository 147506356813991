import {money} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import {go, withWindowSize} from '../../helpers';
import {Button} from '../Common/Button';
import {CustomIcon} from '../CustomIcon/CustomIcon';
import Badge from './Badge';
import Deadlines from './Deadlines';
import HeaderCategories from './HeaderCategories';
import Logo from './Logo';
import Navigation from './Navigation';
import SearchBar from './SearchBar';

const Container2 = styled.div`
  position: relative;
  z-index: 3000;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1665px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.975);
`;

function handleCartClick() {
  window.history.pushState({cart: true}, '', '/cart');
}

const DesktopHeader = (props) => {
  const {router, viewer} = props;
  const cart = (viewer && viewer.cart) || {};
  const cartQty = cart.qty || 0;
  const isLogged = ((viewer && viewer.user && viewer.user.role) || 'guest') !== 'guest';

  // const isProductOpen = window.location.pathname.indexOf('/products/') === 0;

  return (
    <React.Fragment>
      {viewer.user.isBanned ? (
        <div style={{alignItems: 'baseline', background: 'rgb(222, 53, 11)', color: 'white', display: 'flex', padding: 20}}>
          <h4 style={{display: 'inline', margin: 0, padding: 0, paddingRight: 10}}>Account Locked:</h4>
          <p style={{display: 'inlne', margin: 0, padding: 0}}>
            Please{' '}
            <Link style={{color: 'white', textDecoration: 'underline'}} to="/pay/owed">
              PAY HERE
            </Link>{' '}
            or email{' '}
            <a href="mailto:pastdues@kckoshercoop.com" style={{color: 'white', textDecoration: 'underline'}}>
              pastdues@kckoshercoop.com
            </a>{' '}
            to resolve your outstanding balance of {money(viewer.user.owed)} due to "{viewer.user.owedReason}", before placing your next
            order.
          </p>
        </div>
      ) : null}
      <Container2>
        <Logo onClick={go('/', {router})} />
        <SearchBar viewer={viewer} />
        <Deadlines viewer={viewer} />
        <Navigation viewer={viewer} />
        {isLogged && (
          <Button className="cart-button" onClick={handleCartClick}>
            <CustomIcon name="fas-shopping-cart" style={{fontSize: 20}} />
            &nbsp;&nbsp;
            {!props.isMobile && 'Cart'}
            <Badge>{cartQty}</Badge>
          </Button>
        )}
      </Container2>
      <HeaderCategories viewer={viewer} />
    </React.Fragment>
  );
};

export default createFragmentContainer(withRouter(withWindowSize(DesktopHeader)), {
  viewer: graphql`
    fragment DesktopHeader_viewer on Viewer {
      ...HeaderCategories_viewer
      ...Deadlines_viewer
      ...Navigation_viewer
      ...SearchBar_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy, page: $page, limit: $limit)
      user {
        firstName
        role
        isBanned
        owed
        owedReason
      }
      cart {
        qty
      }
    }
  `,
});
