import {icon, library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import './library';

export const CustomIcon = ({name, ...rest}: any) => {
  const tmpName = name || '';
  const p = tmpName.indexOf('-');
  const prefix = p >= 0 ? tmpName.substr(0, p) : 'far';
  const iconName = p >= 0 ? tmpName.substr(p + 1) : tmpName;

  // @ts-ignore
  const iconDefinition = (library.definitions[prefix] && library.definitions[prefix][iconName]) || null;
  // @ts-ignore
  const faIcon = iconDefinition && icon({icon: iconDefinition});
  if (!faIcon) {
    console.error(`Icon '${prefix} ${iconName}' is missing.`);
    return null;
  }

  const faPropsList = ['fixedWidth', 'flip', 'inverse', 'onClick', 'rotation', 'size', 'style'];
  const faProps = Object.keys(rest).reduce((p, c) => (faPropsList.indexOf(c) >= 0 ? {...p, [c]: rest[c]} : p), {});
  const containerProps: any = Object.keys(rest).reduce((p, c) => (faPropsList.indexOf(c) === -1 ? {...p, [c]: rest[c]} : p), {});

  containerProps.style = {
    display: 'inline-block',
    ...containerProps.style,
  };

  return (
    <div {...containerProps}>
      <FontAwesomeIcon {...faProps} icon={[prefix, iconName]} />
    </div>
  );
};

export default CustomIcon;
