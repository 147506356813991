import React from 'react';
import {Icon, List} from 'semantic-ui-react';
import styles from './Banner.module.css';

// TODO: Handle custom actions (connection dropped with reload action)
// TODO: Handle HTML garbage (joins done)

function hasNewMessages(newMessages, prevMessage) {
  if (newMessages.length !== prevMessage.length) {
    return true;
  }

  for (const i in newMessages) {
    if (newMessages[i] !== prevMessage[i]) {
      return true;
    }
  }

  return false;
}

export class Banner extends React.Component {
  state = {
    current: Infinity,
    expanded: false,
    isOpen: false,
  };

  constructor(props) {
    super(props);

    if (props.messages && props.messages.length > 0) {
      this.state = {
        ...this.state,
        current: props.messages.length - 1,
        isOpen: true,
      };
    }

    this.closeTimeout = 5000;
    this.autoCloseTimer = 0;
  }

  componentDidUpdate(prevProps, prevState) {
    const {messages} = this.props;
    const lastIx = (messages || []).length - 1;

    // Show the latest message when the props update

    if (messages !== prevProps.messages) {
      const firstOpen = !isFinite(prevState.current);
      const hasNewMsgs = hasNewMessages(messages, prevProps.messages);

      this.setState({
        current: Math.max(0, lastIx),
        isOpen: prevState.isOpen || firstOpen || hasNewMsgs,
      });

      // Schedule banner to close automatically if the last message is temporary

      this.scheduleAutoClose(lastIx >= 0 && messages[lastIx].persist === false);
    }
  }

  scheduleAutoClose = (reschedule = true) => {
    // Always clear timer

    if (this.autoCloseTimer > 0) {
      this.autoCloseTimer = clearTimeout(this.autoCloseTimer) || 0;
      // console.debug('Clearing previous banner auto close timeout');
    }

    // Reschedule if necessary

    if (reschedule) {
      this.autoCloseTimer = setTimeout(this.onClose, this.closeTimeout);
      // console.debug('Setting banner to close automatically');
    }
  };

  navigateErrors = (e) => {
    e.preventDefault();

    // Clear auto close timeout if user clicks nav arrows

    this.scheduleAutoClose(false);

    const {messages} = this.props;
    const {current} = this.state;

    const errorCount = messages.length - 1;
    const dir = Number(e.target.getAttribute('data-direction'));

    if (false === ((current === 0 && dir === -1) || (current === errorCount && dir === +1))) {
      this.setState({
        current: current + dir,
      });
    }
  };

  toggleExpandedView = (e) => {
    e.preventDefault();

    this.setState({
      expanded: !this.state.expanded,
    });
  };

  formatPartialError = (err) => {
    const message = err.message;
    const path = err.path ? `[ ${err.path.join(' > ')} ]` : null;
    const ref = err.id && `(${err.id})`;

    return [message, path, ref].map((v, i) => <span key={i}>{v}</span>);
  };

  onClose = (e) => {
    e && e.preventDefault();

    this.scheduleAutoClose(false);
    this.setState({expanded: false, isOpen: false}, this.delayedCloseNotify);
  };

  delayedCloseNotify = (delay = 1000) => {
    return setTimeout(() => {
      if (typeof this.props.onClose === 'function' && this.state.isOpen === false) {
        this.props.onClose();
      }
    }, delay);
  };

  renderExpandedDetails = (currentError) => {
    const details = (currentError && currentError.details && JSON.parse(currentError.details)) || [];

    if (!details && details.length < 1) {
      return null;
    }

    // Formatter works with structure of partials errors returned by relay

    return (
      <List>
        {details.map((e) => (
          <List.Item className={styles.ErrorItem} key={e.id || Math.random()}>
            {e.message} ({e.id}) <br /> [ {e.path.join(' > ')} ]
          </List.Item>
        ))}
      </List>
    );
  };

  render() {
    const {messages} = this.props;
    const {current, expanded, isOpen} = this.state;

    // console.debug('Banner render', this.props, this.state);

    if (!messages || messages.length < 1) {
      return null;
    }

    const errorCount = messages.length;
    const hasMultipleErrors = errorCount > 1;

    const _current = Math.min(current, errorCount - 1);

    const currentError = messages[_current] || {};
    const currentN = _current + 1;

    const canClose = true;
    const canExpand = currentError.details && currentError.details.length > 0;

    const bannerClass = [styles.Banner, styles[currentError.type] || '', (!isOpen && styles.hidden) || ''].join(' ');

    return (
      <div className={bannerClass}>
        {hasMultipleErrors && (
          <div className={styles.ErrorNav}>
            <Icon data-direction={-1} disabled={currentN === 1} link name="triangle left" onClick={this.navigateErrors} size="large" />
            <Icon
              data-direction={+1}
              disabled={currentN === errorCount}
              link
              name="triangle right"
              onClick={this.navigateErrors}
              size="large"
            />
            <div className={styles.NavNumbers}>
              {currentN} / {errorCount}
            </div>
          </div>
        )}

        <div className={styles.MessageContent}>{currentError.body}</div>

        {canExpand && (
          <div className={styles.ActionButton}>
            <Icon link name="unordered list" onClick={this.toggleExpandedView} size="large" />
          </div>
        )}
        {canClose && (
          <div className={styles.ActionButton}>
            <Icon link name="close" onClick={this.onClose} size="large" />
          </div>
        )}

        {canExpand && expanded && <div className={styles.ExpandedView}>{this.renderExpandedDetails(currentError)}</div>}
      </div>
    );
  }
}

export default Banner;
