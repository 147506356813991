import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import get from 'lodash/get';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Breadcrumb, Dropdown, Input} from 'semantic-ui-react';
import styled from 'styled-components';
import {withWindowSize} from '../../helpers';
import getCartItemForProduct from '../../helpers/getCartItemForProduct';
import StoreCategories from '../Store/StoreCategories';
import ProductItem from './ProductItem';

const PER_PAGE = 30;

const Container = styled.div`
  display: flex;
  background: #fff;
`;

const ProductsList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & .ui.breadcrumb {
    font-size: 16px;
  }
  & .ui.breadcrumb .active.section {
    color: black;
    cursor: default;
  }
`;

const ProductFilters = styled.div`
  display: flex;
  padding: 14px 25px 13px 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5edec;

  & .ui.dropdown .menu {
    left: -100px;
  }

  & .ui.breadcrumb .section {
    line-height: 1.5em;
  }
`;

const ProductList = styled.div`
  padding: 30px 25px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: top;
  align-items: center;
  padding: 1em 0.5em;

  & > p {
    flex: 1;
  }
`;

const Search = styled.div`
  padding: 1em;
  width: 100%;

  & input {
    background-color: #f5f5f5 !important;
  }

  & input:focus {
    background-color: transparent !important;
  }
`;

const Loading = styled.div`
  padding: 25px 0 50px 0;
  font-size: 1.25em;
  text-align: center;
`;

function getQueryStringParams(query) {
  return query
    ? String(
        (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
          return params;
        }, {}).query,
      ).trim()
    : '';
}

const sortOptions = [
  {text: 'Sort by Popularity', value: 'rank:ASC'},
  {text: 'Sort by Brand & Name', value: 'name:ASC'},
  {text: 'Sort by Price: Lowest First', value: 'cost:ASC'},
  {text: 'Sort by Price: Highest First', value: 'cost:DESC'},
  {text: 'Sort by Unit Price: Lowest First', value: 'unit:ASC'},
  {text: 'Sort by Unit Price: Highest First', value: 'unit:DESC'},
];

class Products extends React.Component {
  state = {
    breadcrumbs: [],
    href: window.location.href,
    isLoading: true,
    pathname: '',
    products: null,
    sort: 'rank:ASC',
  };

  searchRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    let href = window.location.href;
    href = href.indexOf('/products/') === -1 && href.indexOf('/cart') === -1 ? href : state.href;

    let isLoading = state.isLoading;
    let products = state.products;

    // First pass on search change, wait for the second pass to change the href and products
    if (!isLoading && state.href !== href) {
      isLoading = true;
      ProductsContainer.refetchVariables.offset = 0;

      if (products != null) {
        // Values for the 2nd pass when searching
        products = null;
        href = state.href;
      }
    } else {
      // Initialize the products on initial load or search from search bar 3rd pass
      if (state.products == null) {
        isLoading = false;
        products = (get(props.viewer, 'products.edges') || []).map((v) => v.node);
      }
    }

    // Scroll to Top
    if (href !== state.href) {
      window.scrollTo(0, 0);
    }

    return {
      href,
      isLoading,
      products,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  //

  getBreadcrumbs = () => {
    let breadcrumbs = [];

    if (this.props.match.location.pathname === '/search') {
      const total = get(this.props.viewer, 'products.total') || '0';
      breadcrumbs.push({
        active: true,
        content: !this.state.isLoading && (
          <React.Fragment>
            <span style={{fontWeight: 'normal'}}>{total} results for</span> "{this.props.match.location.query.query}"
          </React.Fragment>
        ),
        key: 'categories',
        onClick: '',
      });
    } else {
      breadcrumbs.push({
        content: 'Categories',
        key: 'categories',
        link: true,
        onClick: '',
      });

      const {category: categorySlug, subcategory: subcategorySlug, subsubcategory: subsubcategorySlug} = this.props.match.params;

      let category;
      let subcategory;
      let subsubcategory;

      if (this.props.match.location.pathname.includes('clearance')) {
        breadcrumbs.push({
          active: true,
          content: 'Clearance',
          key: 'clearance',
          link: false,
          onClick: 'clearance',
        });
      } else if (this.props.match.location.pathname.includes('specials')) {
        breadcrumbs.push({
          active: true,
          content: 'Specials',
          key: 'specials',
          link: false,
          onClick: 'specials',
        });
      } else if (categorySlug === 'new') {
        breadcrumbs.push({
          active: true,
          content: 'New Products',
          key: 'new',
          link: false,
          onClick: 'new',
        });
      } else {
        const categories = (get(this.props.viewer, 'categories.edges') || []).map((v) => v.node);
        category = categories.find((c) => c.slug === categorySlug);
        if (category) {
          breadcrumbs.push({
            active: subcategorySlug ? false : true,
            content: category.name,
            key: category.slug,
            link: true,
            onClick: category.slug,
          });
        }
      }

      if (subcategorySlug === 'top') {
        breadcrumbs.push({
          active: true,
          content: 'Top Products',
          key: categorySlug + '/top',
          onClick: categorySlug + '/top',
        });
      } else {
        const subcategories = (get(category, 'categories.edges') || []).map((v) => v.node);
        subcategory = subcategories.find((c) => c.slug === categorySlug + '/' + subcategorySlug);
        if (subcategory) {
          breadcrumbs[1].active = false;
          breadcrumbs.push({
            active: subsubcategorySlug ? false : true,
            content: subcategory.name,
            key: subcategory.slug,
            onClick: subcategory.slug,
          });

          if (subsubcategorySlug === 'top') {
            breadcrumbs.push({
              active: true,
              content: 'Top Products',
              key: categorySlug + '/top',
              onClick: categorySlug + '/top',
            });
          } else {
            const subsubcategories = (get(subcategory, 'categories.edges') || []).map((v) => v.node);
            subsubcategory = subsubcategories.find((c) => c.slug === categorySlug + '/' + subcategorySlug + '/' + subsubcategorySlug);
            if (subsubcategory) {
              breadcrumbs[1].active = false;
              breadcrumbs.push({
                active: true,
                content: subsubcategory.name,
                key: subsubcategory.slug,
                onClick: subsubcategory.slug,
              });
            }
          }
        }
      }
    }

    return breadcrumbs;
  };

  hasMore = () => {
    const total = get(this.props.viewer, 'products.total');
    const loaded = (this.state.products || []).length;
    return total !== loaded;
  };

  //

  handleScroll = () => {
    if (!this.hasMore() || this.state.isLoading) {
      return;
    }

    const productList = document.getElementById('productsList');
    const contentHeight = (productList && productList.clientHeight) || window.innerHeight;
    if (window.innerHeight + window.scrollY <= contentHeight) {
      return;
    }

    this.setState({isLoading: true});

    ProductsContainer.refetchVariables.offset = ProductsContainer.refetchVariables.offset + PER_PAGE;

    this.props.relay.refetch(ProductsContainer.refetchVariables, null, (err) => {
      this.setState((prevState) => ({
        isLoading: false,
        products: [...prevState.products, ...(get(this.props.viewer, 'products.edges') || []).map((v) => v.node)],
      }));
    });
  };

  handleSort = (e, {value, ...rest}) => {
    if (this.state.sort === value) {
      return;
    }

    if (this.hasMore()) {
      this.setState({isLoading: true});

      ProductsContainer.refetchVariables.offset = 0;
      ProductsContainer.refetchVariables.orderBy = [value.split(':')];

      this.props.relay.refetch(ProductsContainer.refetchVariables, null, (e) => {
        this.setState({
          isLoading: false,
          products: (get(this.props.viewer, 'products.edges') || []).map((v) => v.node),
          sort: value,
        });
      });
    } else {
      // Sort in place
      const products = (this.state.products || []).sort((a, b) => {
        const [column, direction] = value.split(':');

        let comparisson = 0;

        if (column === 'cost') {
          comparisson = (direction === 'ASC' ? 1 : -1) * (Number(a.cost) - Number(b.cost));
        } else if (column === 'unit') {
          comparisson = (direction === 'ASC' ? 1 : -1) * (Number(a.unit) - Number(b.unit));
        } else if (column === 'rank') {
          comparisson = a.rank - b.rank;
        }

        if (comparisson === 0) {
          return a.fullName.localeCompare(b.fullName);
        }

        return comparisson;
      });

      this.setState({products, sort: value});
    }
  };

  //

  handleBreadcrumbClick = (slug) => (e) => {
    this.props.router.push(`/categories/${slug}`);
  };

  handleSearch = (type) => (e) => {
    if (type === 'press' && e.which !== 13) {
      return;
    }

    const newQuery = get(this.searchRef.current, 'inputRef.current.value') || '';
    const oldQuery = get(this.props.match, 'location.query.query') || '';
    if (newQuery !== oldQuery) {
      this.props.router.push(`/search?query=${this.searchRef.current.inputRef.current.value}`);
    }
  };

  handleSearchFocus = (e) => {
    console.log('a');
    window.scrollTo(0, 100);
  };

  //

  renderSearchBar() {
    return (
      <Search>
        <Input
          action={{color: 'blue', content: 'Search', icon: 'search', onClick: this.handleSearch('click')}}
          defaultValue={get(this.props.match, 'location.query.query') || ''}
          fluid
          onFocus={this.handleSearchFocus}
          onKeyPress={this.handleSearch('press')}
          placeholder="Search..."
          ref={this.searchRef}
        />
      </Search>
    );
  }

  render() {
    const {viewer} = this.props;
    const {cart} = viewer;
    const cartItems = (cart?.items?.edges || []).map(({node}) => node);

    const products = this.state.products || [];

    const isMobile = this.props.isMobile;

    const breadcrumbs = this.getBreadcrumbs();

    // Empty search
    const isSearch = this.props.match.location.pathname === '/search';
    const query = this.props.match.location.query.query || '';
    if (isSearch && query.length === 0) {
      return <Container>{this.props.isMobile ? this.renderSearchBar() : null}</Container>;
    }

    return (
      <Container>
        {!isMobile && !isSearch && <StoreCategories viewer={viewer} />}
        <ProductsList>
          {this.props.isMobile && isSearch ? this.renderSearchBar() : null}
          <ProductFilters>
            <Breadcrumb icon="right angle" sections={breadcrumbs.map((v) => ({...v, onClick: this.handleBreadcrumbClick(v.onClick)}))} />
            <Dropdown inline name="sort" onChange={this.handleSort} options={sortOptions} value={this.state.sort} />
          </ProductFilters>
          <ProductList id="productsList">
            {!this.state.isLoading && products.length === 0 && (
              <EmptyState>
                <p>No Products found.</p>
              </EmptyState>
            )}
            {products.map((product) => (
              <ProductItem
                cart={cart}
                cartItem={getCartItemForProduct(cartItems, product.id)}
                clearanceItem={product.UNSAFE_clearanceItem}
                key={product.id}
                product={product}
                specialItem={product.UNSAFE_specialItem}
                viewer={viewer}
              />
            ))}
          </ProductList>
          {this.state.isLoading && <Loading>Loading...</Loading>}

          {this.props.children}
        </ProductsList>
      </Container>
    );
  }
}

const ProductsContainer = createRefetchContainer(
  withRouter(withWindowSize(Products)),
  {
    viewer: graphql`
      fragment Products_viewer on Viewer
      @argumentDefinitions(
        filterBy: {type: "ProductFilterInput"}
        orderBy: {type: "[[String]]"}
        offset: {type: "Int"}
        limit: {type: "Int"}
      ) {
        ...ProductItem_viewer
        categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
          edges {
            node {
              id
              name
              slug
              categories(filterBy: {isActive: true}) {
                edges {
                  node {
                    id
                    name
                    slug
                    categories(filterBy: {isActive: true}) {
                      edges {
                        node {
                          id
                          name
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        products(limit: $limit, offset: $offset, filterBy: $filterBy, orderBy: $orderBy) {
          edges {
            node {
              ...ProductItem_product
              cost
              fullName
              id
              rank
              unit
              UNSAFE_clearanceItem {
                cost
                id
                qty
                unit
              }
              UNSAFE_specialItem {
                cost
                id
                unit
              }
            }
          }
          total
        }
        cart {
          ...ProductItem_cart
          items(first: 10000) {
            edges {
              node {
                ...ProductItem_cartItem
                clearanceItemId
                productId
                specialItemId
              }
            }
          }
        }
        ...StoreCategories_viewer
      }
    `,
  },
  graphql`
    query Products_Refetch_Query($filterBy: ProductFilterInput, $orderBy: [[String]], $offset: Int, $limit: Int) {
      viewer {
        ...Products_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy, offset: $offset, limit: $limit)
      }
    }
  `,
);

//
ProductsContainer.refetchVariables = {};

ProductsContainer.getInitialVariables = (params) => {
  const variables = cloneDeep(
    defaultsDeep(ProductsContainer.refetchVariables, {
      filterBy: {
        isActive: true,
        isAvailable: true,
        query: undefined,
        slug: undefined,
      },
      limit: PER_PAGE,
      offset: 0,
      orderBy: [['rank', 'ASC']],
    }),
  );

  variables.offset = 0;
  variables.limit = PER_PAGE;

  if (window.location.pathname === '/search') {
    const query = getQueryStringParams(window.location.search);
    variables.filterBy.query = query;
    variables.filterBy.slug = undefined;
  } else {
    variables.filterBy.query = undefined;
    variables.filterBy.slug = window.location.pathname.replace('/categories/', '');
  }

  ProductsContainer.refetchVariables = variables;

  return ProductsContainer.refetchVariables;
};

export default ProductsContainer;
