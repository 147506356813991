import {graphql} from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddImageMutation($input: AddImageInput!) {
    addImage(input: $input) {
      imageEdge {
        __typename
        node {
          id
          isPrimary
          url
        }
      }
    }
  }
`;

export const AddImageMutation = (input, success, failure) =>
  commitMutation(environment, {
    configs: [
      {
        connectionInfo: [
          {
            key: 'Product_images',
            rangeBehavior: 'append',
          },
        ],
        edgeName: 'imageEdge',
        parentID: input.productId,
        type: 'RANGE_ADD',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {input},
  });
