// If viewer is passed check the permissions
export default function go(link, {router = null, viewer = null} = {}) {
  return function handler(e) {
    e.preventDefault();
    e.stopPropagation();

    if (link.indexOf('#') === -1) {
      window.scrollTo(0, 0);
    }

    if (viewer) {
      const role = (viewer && viewer.user && viewer.user.role) || 'guest';
      if (role === 'guest') {
        link = `/login?redirect=${link}`;
      }
    }

    if (router) {
      router.push(link);
    } else {
      window.location = link;
    }
  };
}
