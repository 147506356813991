import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation DeleteCartItemMutation($input: DeleteCartItemInput!) {
    deleteCartItem(input: $input) {
      deletedCartItemId
      deletedCartItem {
        clearanceItem {
          id
          qty
        }
        product {
          id
        }
      }
      cart {
        id
        qty
        subtotal
        shipping
        shippingRate
        tax
        taxRate
        total
        credit
        owed
        items(first: 10000) {
          edges {
            node {
              id
              total
              qty
              product {
                id
                brand {
                  name
                }
                name
                unit
                cost
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const DeleteCartItemMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        fieldIDs: {
          cart: input.userId,
        },
        type: 'FIELDS_CHANGE',
      },
      {
        connectionName: 'items',
        deletedIDFieldName: 'deletedCartItemId',
        parentID: input.userId,
        parentName: 'cart',
        type: 'NODE_DELETE',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['id']),
    },
  });
};
