import styled from 'styled-components';

const Container = styled.span`
  background-color: #e8104a;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
  height: 26px;
  line-height: 26px;
  position: absolute;
  right: -10px;
  top: -8px;
  width: 26px;
`;

const Badge = (props) => <Container className="badge">{props.children}</Container>;

export default Badge;
