import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddSplitRequestMutation($input: AddSplitRequestInput!) {
    addSplitRequest(input: $input) {
      splitRequestEdge {
        __typename
        node {
          id
        }
      }
    }
  }
`;

export const AddSplitRequestMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        connectionName: 'splitRequests',
        edgeName: 'splitRequestEdge',
        parentID: input.viewerId,
        parentName: 'viewer',
        rangeBehaviors: {
          '': 'append',
        },
        type: 'RANGE_ADD',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['clearanceItemId', 'productId', 'specialItemId']),
    },
  });
};
