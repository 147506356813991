import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Dropdown} from 'semantic-ui-react';
import styled from 'styled-components';
import {go} from '../../helpers';
import {Button2} from '../Common/Button';
import {CustomIcon} from '../CustomIcon/CustomIcon';

const HeaderItem = styled.div`
  cursor: pointer;
  display: block;
  padding: 0 20px;
  height: 66px;
  color: #2196f3;
  font-size: 18px;
  line-height: 66px;
  vertical-align: center;
  text-decoration: none;
  &:hover {
    background: #f5f5f5;
  }
  & > i.icon {
    margin: 0;
    width: 0.9em;
    font-weight: bold;
    text-align: right;
  }
`;

const LogInButton = styled(Button2)`
  display: block;
  margin-top: 10px;
  font-size: 16px;
`;

const DropdownHeader = styled(Dropdown.Header)`
  padding: 10px 0;
  text-align: center;
  font-size: 1.4rem !important;
  font-weight: normal !important;
  text-transform: none !important;
`;

Dropdown.Submenu = styled(Dropdown.Item)`
  margin-bottom: 8px;
  text-align: center !important;
  &:hover {
    background: transparent !important;
  }
  & > a {
    color: #9e9e9e;
    font-size: 0.9rem;
  }
  & > a:hover {
    color: #616161;
  }
`;

const menuRef = React.createRef();

const Navigation = (props) => {
  const {router, viewer} = props;

  const user = viewer && viewer.user;
  const name = (user && user.firstName) || 'Guest';
  const isLogged = ((user && user.role) || 'guest') !== 'guest';

  const handleLoginClick = (e) => {
    props.router.push('/login');
  };

  const go2 = (a, b) => {
    menuRef.current && menuRef.current.close();

    return go(a, b);
  };

  return (
    <React.Fragment>
      <Dropdown
        icon={null}
        ref={menuRef}
        trigger={
          <HeaderItem>
            Account
            <CustomIcon name="fas-angle-down" style={{marginLeft: 4}} />
          </HeaderItem>
        }>
        <Dropdown.Menu style={{left: -12, marginTop: -8, width: 200}}>
          <Dropdown.Header disabled>
            <DropdownHeader>
              <span>
                Shalom, <strong>{name}</strong>!
              </span>
              {!isLogged && [
                <LogInButton key="login" onClick={handleLoginClick} primary>
                  Log In
                </LogInButton>,
                <p key="signin" style={{fontSize: 14, marginTop: 5}}>
                  or{' '}
                  <Link style={{fontWeight: 'bold'}} to="/register">
                    Create an account
                  </Link>
                </p>,
              ]}
            </DropdownHeader>
          </Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item
            icon={<CustomIcon name="fas-user" style={{color: '#2185d0', marginRight: 14}} />}
            onClick={go2('/account', {router, viewer})}
            text="Your Account"
          />
          <Dropdown.Item
            icon={<CustomIcon name="fas-archive" style={{color: '#2185d0', marginRight: 14}} />}
            onClick={go2('/account/orders', {router, viewer})}
            text="Your Orders"
          />
          <Dropdown.Item
            icon={<CustomIcon name="fas-star" style={{color: '#2185d0', marginRight: 14}} />}
            onClick={go2('/account/ratings', {router, viewer})}
            text="Your Ratings"
          />
          <Dropdown.Divider />
          <Dropdown.Item
            icon={<CustomIcon name="fas-credit-card" style={{color: '#2185d0', marginRight: 14}} />}
            onClick={go2('/pay', {router, viewer})}
            text="Make a Payment"
          />
          <Dropdown.Item
            icon={<CustomIcon name="far-handshake" style={{color: '#2185d0', marginRight: 14}} />}
            onClick={go2('/splits', {router, viewer})}
            text="Split Products"
          />
          <Dropdown.Divider />
          <Dropdown.Item
            icon={<CustomIcon name="fas-envelope" style={{color: '#2185d0', marginRight: 14}} />}
            onClick={go2('/contact', {router, viewer})}
            text="Contact Us"
          />
          <Dropdown.Divider />
          {isLogged && [
            <Dropdown.Item
              icon={<CustomIcon name="fas-sign-out-alt" style={{color: '#2185d0', marginRight: 14}} />}
              key="logout"
              onClick={go2('/logout', {router})}
              text="Log Out"
            />,
            <Dropdown.Divider key="logoutDivider" />,
          ]}
          <Dropdown.Submenu>
            <Link to="/about">About</Link>
            &nbsp;·&nbsp;
            <Link to="/terms">Terms</Link>
            {/* &nbsp;·&nbsp; */}
            {/* <Link to="/privacy">Privacy</Link> */}
          </Dropdown.Submenu>
        </Dropdown.Menu>
      </Dropdown>
      <HeaderItem onClick={go2('/help', {router})}>Help</HeaderItem>
    </React.Fragment>
  );
};

export default createFragmentContainer(withRouter(Navigation), {
  viewer: graphql`
    fragment Navigation_viewer on Viewer {
      user {
        firstName
        role
      }
      cart {
        qty
      }
    }
  `,
});
