import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import {go} from '../../helpers';

const Container = styled.div`
  position: relative;
  z-index: 2000;
  width: 100%;
  height: 50px;
  background: #2196f3;
  box-shadow: 0px 0 5px 1px rgba(0, 0, 0, 0.16);
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  max-width: 1360px;
`;

const CategoryItem = styled.div`
  flex: 1;

  &:hover {
    & > div:nth-child(1) {
      background: #1976d2;
      color: #2196f3;
      background: #fff;
    }

    & > div:nth-child(2) {
      visibility: visible;
      opacity: 1;
      // transition: visibility 0s linear, opacity 0s linear;
      transition-delay: 0.2s;
    }
  }

  & > div:nth-child(2) {
    visibility: hidden;
    opacity: 0;
    // transition: visibility 0.3s linear, opacity 0.3s linear;
    transition-delay: 0.4s;
  }
`;

const CategoryItemTitle = styled.div`
  display: block;
  position: relative;
  z-index: 3000;
  text-align: center;
  vertical-align: middle;
  font-size: 19px;
  line-height: 50px;
  height: 100%;
  color: #fff;
  cursor: pointer;
`;

const CategoryItemContent = styled.div`
  position: absolute;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: 16vw;
  background: rgba(255, 255, 255, 0.975);
  box-shadow: 0px 0 5px 1px rgba(0, 0, 0, 0.16);
`;

const SubCategoryItem = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 16vw;
  height: 16vw;
  cursor: pointer;

  & :hover {
    background: #ededed;

    & > span {
      color: #f57c00;
    }
  }
`;

const SubCategoryItemImage = styled.img`
  max-width: 70%;
  max-height: 70%;
`;

const SubCategoryItemTitle = styled.div`
  display: block;
  color: #2196f3;
  font-size: 18px;
  text-align: center;
`;

export const saleCategory = {
  node: {
    categories: {
      edges: [
        {
          node: {
            id: 'clearance',
            name: 'Clearance',
            slug: 'clearance',
            withoutIcon: true,
          },
        },
        {
          node: {
            id: 'specials',
            name: 'Specials',
            slug: 'specials',
            withoutIcon: true,
          },
        },
      ],
    },
    id: 'sale',
    name: 'Sale',
    skipRedirect: true,
    slug: 'sale',
  },
};

const HeaderCategories = (props) => {
  const [hidden, setHidden] = React.useState(false);

  // HACK: Due to limitations of the css hover menu
  // it will be best if we can rewrite the menu with state and mouseOver/Out events
  const go2 = (a, b, noRedirect) => (e) => {
    if (noRedirect) {
      return;
    }

    setHidden(true);
    window.setTimeout(() => setHidden(false), 100);
    return go(a, b)(e);
  };

  const viewerCategories = props?.viewer?.categories?.edges;

  return (
    <Container>
      <Inner>
        {[...(viewerCategories || []), saleCategory].map(({node: category}) => (
          <CategoryItem key={category.slug}>
            <CategoryItemTitle onClick={go2(`/categories/${category.slug}`, {router: props.router}, category.skipRedirect)}>
              {category.name}
            </CategoryItemTitle>
            <CategoryItemContent hidden={hidden}>
              <Inner>
                {((category && category.categories && category.categories.edges) || []).map(({node: subcategory}) => (
                  <SubCategoryItem key={subcategory.slug} onClick={go2(`/categories/${subcategory.slug}`, {router: props.router})}>
                    <SubCategoryItemImage
                      alt={subcategory.name}
                      src={`/next/img/categories2/${subcategory.slug.replace(/\//g, '-')}.png`}
                    />
                    <SubCategoryItemTitle>{subcategory.name}</SubCategoryItemTitle>
                  </SubCategoryItem>
                ))}
              </Inner>
            </CategoryItemContent>
          </CategoryItem>
        ))}
      </Inner>
    </Container>
  );
};

export default createFragmentContainer(withRouter(HeaderCategories), {
  viewer: graphql`
    fragment HeaderCategories_viewer on Viewer {
      categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
        edges {
          node {
            id
            name
            slug
            categories(filterBy: {isActive: true}) {
              edges {
                node {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `,
});
