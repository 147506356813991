export const fromGlobalId = (globalId) => {
  try {
    const unbasedGlobalId = atob(globalId);
    const delimiterPos = unbasedGlobalId.indexOf(':');

    const id = unbasedGlobalId.substring(delimiterPos + 1);
    const type = unbasedGlobalId.substring(0, delimiterPos);

    if (type) {
      return {id, type};
    } else {
      return {id: globalId, type: ''};
    }
  } catch (e) {
    return {
      id: globalId,
      type: '',
    };
  }
};

export default fromGlobalId;
