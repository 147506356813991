import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation ForgotMutation($input: ForgotInput!) {
    forgot(input: $input) {
      _empty
    }
  }
`;

export const ForgotMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['email']),
    },
  });
};
