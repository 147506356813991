/**
 * @generated SignedSource<<a9b44af4df81128c6c11e1c98721b316>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MySplits_viewer$data = {
  readonly cart: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly clearanceItemId: string | null | undefined;
          readonly productId: string | null | undefined;
          readonly specialItemId: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cartItem">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cart">;
  } | null | undefined;
  readonly splitRequests: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly clearanceItemId: string | null | undefined;
        readonly id: string;
        readonly product: {
          readonly fullName: string | null | undefined;
          readonly id: string;
          readonly productId: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ProductItem_product">;
        } | null | undefined;
        readonly rowId: number | null | undefined;
        readonly specialItemId: string | null | undefined;
        readonly user: {
          readonly firstName: string | null | undefined;
          readonly fullName: string | null | undefined;
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProductItem_viewer">;
  readonly " $fragmentType": "MySplits_viewer";
};
export type MySplits_viewer$key = {
  readonly " $data"?: MySplits_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"MySplits_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clearanceItemId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialItemId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MySplits_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductItem_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductItem_cart"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "CartItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CartItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CartItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProductItem_cartItem"
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:10000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "SplitRequestConnection",
      "kind": "LinkedField",
      "name": "splitRequests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SplitRequestEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SplitRequest",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProductItem_product"
                    },
                    (v5/*: any*/),
                    (v4/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    (v5/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "splitRequests(first:10000)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "32b4f936312c2dfd647948b7b04e82fd";

export default node;
