/**
 * @generated SignedSource<<daf14449f87f5ab040bd9fa3547ea034>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddQuestionInput = {
  content?: string | null | undefined;
  productId?: string | null | undefined;
};
export type AddQuestionMutation$variables = {
  input: AddQuestionInput;
};
export type AddQuestionMutation$data = {
  readonly addQuestion: {
    readonly questionEdge: {
      readonly __typename: "QuestionEdge";
      readonly node: {
        readonly content: string | null | undefined;
        readonly id: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AddQuestionMutation = {
  response: AddQuestionMutation$data;
  variables: AddQuestionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddQuestionPayload",
    "kind": "LinkedField",
    "name": "addQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuestionEdge",
        "kind": "LinkedField",
        "name": "questionEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Question",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddQuestionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddQuestionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0680e97272fb5560387e411bead549d9",
    "id": null,
    "metadata": {},
    "name": "AddQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation AddQuestionMutation(\n  $input: AddQuestionInput!\n) {\n  addQuestion(input: $input) {\n    questionEdge {\n      __typename\n      node {\n        id\n        content\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79284d9add80aec5c704fa625b49011c";

export default node;
