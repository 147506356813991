/**
 * @generated SignedSource<<a5ff5d2af98dc1365eed50925e6becd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DesktopHeader_viewer$data = {
  readonly cart: {
    readonly qty: number | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly firstName: string | null | undefined;
    readonly isBanned: boolean | null | undefined;
    readonly owed: number | null | undefined;
    readonly owedReason: string | null | undefined;
    readonly role: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Deadlines_viewer" | "HeaderCategories_viewer" | "Navigation_viewer" | "SearchBar_viewer">;
  readonly " $fragmentType": "DesktopHeader_viewer";
};
export type DesktopHeader_viewer$key = {
  readonly " $data"?: DesktopHeader_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DesktopHeader_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "filterBy"
    },
    {
      "kind": "RootArgument",
      "name": "limit"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    },
    {
      "kind": "RootArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DesktopHeader_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderCategories_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Deadlines_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Navigation_viewer"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filterBy",
          "variableName": "filterBy"
        },
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "limit"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SearchBar_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBanned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owedReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qty",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c81b6e104681766a5095fd2f6d3153a9";

export default node;
