import {withRouter} from 'found';
import styled from 'styled-components';
import {go} from '../../../helpers';
import {OrangeButton} from '../../Common/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(1) > div:first-child {
    background: #efefef;
  }
  > div:nth-child(2) > div:first-child {
    background: #e7e7e7;
  }
  > div:nth-child(3) > div:first-child {
    background: #dfdfdf;
  }
  > div:nth-child(4) > div:first-child {
    background: #d8d8d8;
  }
  > div:nth-child(5) > div:first-child {
    background: #d0d0d0;
  }
  > div:nth-child(6) > div:first-child {
    background: #c8c8c8;
  }
  > div:nth-child(7) > div:first-child {
    background: #c1c1c1;
  }
  > div:nth-child(8) > div:first-child {
    background: #b9b9b9;
  }
  > div:nth-child(9) > div:first-child {
    background: #b1b1b1;
  }

  > div:nth-child(10) > div:first-child {
    background: #aaa;
  }

  & > div {
    display: flex;
    margin-bottom: 2em;
    flex-direction: row;
    // align-items: center;

    @media (max-width: 600px) {
      flex-direction: column;

      & > div:first-child {
        margin 0 auto;
      }

      & > div:last-child {
        padding: 1em 0 !important;
      }
    }

    > div:first-child {
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      font-size: 5em;
      font-weight: bold;
      border-radius: 1.5em;
      font-family: sans-serif;
      color: #fff;
      background: #aaa;
    }

    > div:last-child {
      flex: 1;
      min-height: 12em;
      padding-left: 2em;

      h4 {
        margin: 0 0 0.25em 0;
        color: #e65100;
      }
      h5 {
        // color: #2196f3;
        margin: 0 0 0.5em 0;
      }
    }
  }
`;

const CoopBenefits = (props) => (
  <Container>
    <div>
      <div>1</div>
      <div>
        <h4>Easy</h4>
        <h5>KC Kosher Co-op worked to streamline each step in the process.</h5>
        <p>
          Something we consistently hear from our Members is just how easy using the Co-op is. This is why we encourage folks to try the
          Co-op once. As our experience shows, this is all that is needed to demonstrate how smooth our process is -- from browsing products
          at home, to multiple paying options, to a centrally located delivery point, to our labor quickly loading your order into your car
          for you. We did everything to make it a great and easy experience for you!
        </p>
      </div>
    </div>
    <div>
      <div>2</div>
      <div>
        <h4>Price</h4>
        <h5>The Co-op is consistently over 30% cheaper than other sources—and guarantees it!</h5>
        <p>
          Kosher food is more expensive. When families create budgets, it can be difficult to find places to trim. KC Kosher Co-op helps
          families save money on food in two substantive ways:
        </p>
        <p>
          <strong>Affordability</strong> – KC Kosher Co-op is cheaper than any other source of kosher food. That’s a gutsy claim! But if you
          fill up your cart at your local store or any other online kosher store and compare it to your cart on www.kckoshercoop.com, you
          will find that while one or two products may be cheaper at the other source, KC Kosher Co-op averages about 30% cheaper cart to
          cart. And keep in mind that there are no delivery fees (assuming nominal community order minimum is met) for our Co-op Communities
          beyond the product prices -- if you’re in a city that taxes food, that is another 4% to 11% savings the Co-op can offer you.
        </p>
        <p>
          <strong>Planned Purchases</strong> – For many people, walking into a local store creates impulse buying (that’s what the store
          wants). But purchasing from the Co-op allows you to strategize about what you are buying.
        </p>
      </div>
    </div>
    <div>
      <div>3</div>
      <div>
        <h4>Variety</h4>
        <h5>The Co-op offers 3,000+ kosher certified products</h5>
        <p>
          At any given time, KC Kosher Co-op offers over 3,000 kosher products (and another 3,000 for Passover) and we add new products
          every month. A local store cannot sustain this kind of variety so we are able to give our customers access to products that have
          never been available locally before. And even with such an enormous number of Kosher products, the browsing and ordering process
          is still so simple!
        </p>
      </div>
    </div>
    <div>
      <div>4</div>
      <div>
        <h4>Local Charity</h4>
        <h5>For almost a decade the Co-op has maintained a 1:1 match to kosher food pantries.</h5>
        <p>
          When you buy from a typical online store (or often even local store), you get your product, but your community does not benefit
          financially. KC Kosher Co-op heavily donates to the cities we service—both through one-time opportunities (such as supporting
          events at local synagogues and/or dayschools), and on-going programs. Our current focus is our partnerships with ten (and growing)
          kosher food pantries (or Tomchei Shabbos) programs: organizations where we not only help get kosher food to those in need, but we
          also accept donations on behalf of those organizations, cover the payment processing fees, and on top of all that, we match every
          donation that comes in.
        </p>
      </div>
    </div>
    <div>
      <div>5</div>
      <div>
        <h4>Customer Service</h4>
        <h5>KC Kosher Co-op takes customer service very seriously.</h5>
        <p>
          Stores do not expect the level of customer service that Consumers do. KC Kosher Co-op allows people to organize in such a way they
          have the buying power of a store—but at the same time giving the customer service consumers expect. We consistently score high in
          Customer Satisfaction and use surveys to continuously improve based on feedback. We also monitor our email response times against
          our goal of replying to email (7am to 7pm) within an hour. Additionally, we stand behind every product we sell even if the
          supplier will not.
        </p>
      </div>
    </div>
    <div>
      <div>6</div>
      <div>
        <h4>Help Local Kosher Stores</h4>
        <h5>Wait, organizing a Co-op can help my local store?</h5>
        <p>
          This one is difficult to understand, but nonetheless if your city has local kosher stores, the Co-op has been shown to actually
          help them! While kosher stores have closed around the country, as of the writing of this (2019), not a single kosher store has
          closed in a Co-op city since we started in it. Often quite the opposite, in many cities the stores have adjusted expanding their
          kosher sections, lowering prices, and offering more prepared foods. The irony of the Co-op is that not only do we bring lower
          prices, but we also cause positive change in local stores. All around the consumer ends up benefiting. As a related note, the
          Co-op does an analysis of each city before entering to ensure we are introducing healthy competition. If your city has only one
          kosher store, it is unusual that we will consider a Co-op (unless the city is large with clear room for healthy competition) as we
          value the presence of a local store. But if your city has none or multiple, please contact us and we will happily analyze your
          city for starting a Co-op.
        </p>
      </div>
    </div>
    <div>
      <div>7</div>
      <div>
        <h4>Jewish Owned</h4>
        <h5>Owned and run by Observant Jews</h5>
        <p>
          While this may seem insignificant to many, to some it is relevant that KC Kosher Co-op is owned and run by Observant Jews. Most
          local kosher offerings in America are no longer Jewish-owned.
        </p>
      </div>
    </div>

    <div>
      <div>8</div>
      <div>
        <h4>Spreading Kosher</h4>
        <h5>More affordable food yields other benefits</h5>
        <p>
          We have accounts from members that they have gone to a kosher kitchen because they feel they can now afford it. But more often we
          receive sweet notes from members such as Kollel families who say they can afford more guests at their Shabbat table due to a
          new-found ability to purchase more food.
        </p>
      </div>
    </div>

    <div>
      <div>9</div>
      <div>
        <h4>Social</h4>
        <h5>The Co-op is ultimately the power of a community to lower the cost of kosher food.</h5>
        <p>
          An accidental effect of KC Kosher Co-op facilitating Co-op Members splitting products with each other was the creation of a social
          scene. The Co-op brings together people who may have never met, often from different denominations, who want to split a certain
          Co-op item. Then there is the meeting at delivery to split the product/payment. We have been told of friends made over this
          unexpected social aspect of the Co-op!
        </p>
      </div>
    </div>

    <div>
      <div>10</div>
      <div>
        <h4>References</h4>
        <h5>We service over 20 cities, call a friend in one and ask about the Co-op!</h5>
        <p>
          Of course, the best recommendation would come from our Members! Call someone you know who keeps kosher in one of our cities and if
          they have used the Co-op, we are confident you will receive a glowing report.
        </p>
      </div>
    </div>
    <OrangeButton onClick={go('/register', {router: props.router})} size="big">
      Start Saving
    </OrangeButton>
  </Container>
);

export default withRouter(CoopBenefits);
