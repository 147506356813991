import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 16vw;
  background: url('/next/img/hero.jpg') no-repeat center;
  background-size: cover;

  & > div {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.66);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Inner = styled.div`
  margin: 0 auto;
  padding: 1em;
  max-width: 1615px;
  width: 100%;

  color: #fff;
  font-size: 3rem;
  line-height: 120%;
  text-align: center;

  & strong {
    color: #00b0ff;
  }

  @media (max-width: 768px) {
    padding: 2em 1em;
    font-size: 1.25rem;
  }
`;

const Hero = (props) => (
  <Container id="hero">
    <div>
      <Inner>
        <p>
          Affordable <strong>Kosher</strong> products delivered to <strong>you</strong>
        </p>
      </Inner>
    </div>
  </Container>
);

export default Hero;
