import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 305px;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px 0 15px 15px;
`;

const Category = styled.div`
  flex: 1 1 auto;
  // flex: 1 0 auto;
  margin: 15px 15px 0 0;
  padding: 20px;
  background: #fff;
  border: 1px solid #e5edec;

  width: 250px;
  // flex-grow: 1;

  &:hover {
    border-color: #1976d2;
  }
`;

const Title = styled(({children, icon, selected, ...rest}) => <a {...rest}>{children}</a>)`
  padding: 10px 10px 10px 35px;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  color: ${(props) => (props.selected ? '#1976D2' : '#222')};
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  &.selected {
    color: #1976d2;
  }
  &::after {
    position: absolute;
    content: '';
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    width: 24px;
    right: 0;
    z-index: -1;
    background-image: url('${(props) => props.icon}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;
  }
`;

const SubCategories = styled.div`
  padding: 5px 0 0 35px;
  line-height: 1.5em;
`;

const SubCategory = styled.div`
  text-decoration: none;
  color: ${(props) => (props.selected ? '#1976D2' : '#999')};
  cursor: pointer;
  font-size: 16px;
  &:hover {
    color: #1976d2;
  }
`;

class Categories extends React.Component {
  handleCategoryClick = (slug) => (e) => {
    this.props.router.push(`/categories/${slug}`);
  };

  render() {
    const {currentCategory, openCategory} = this;
    const {viewer} = this.props;
    const {categories} = viewer;

    return (
      <Container>
        <Content>
          <Category key="specials">
            <Title
              icon={`/next/img/categories/specials.png`}
              onClick={this.handleCategoryClick('specials')}
              selected={currentCategory === 'specials'}>
              Promotions
            </Title>
            <SubCategories>
              <SubCategory key="specials" onClick={this.handleCategoryClick('specials')}>
                Specials
              </SubCategory>
              <SubCategory key="clearance" onClick={this.handleCategoryClick('clearance')}>
                Clearance
              </SubCategory>
              <SubCategory key="new-products" onClick={this.handleCategoryClick('new')}>
                New Products
              </SubCategory>
            </SubCategories>
          </Category>
          {categories.edges.map(({node: category}) => (
            <Category key={category.id}>
              <Title
                icon={`/next/img/categories/${category.slug}.png`}
                onClick={this.handleCategoryClick(category.slug)}
                selected={currentCategory === category.slug}>
                {category.name}
              </Title>
              <SubCategories show={openCategory === category.slug}>
                <SubCategory
                  key={`top-${category.id}`}
                  onClick={this.handleCategoryClick(`${category.slug}/top`)}
                  selected={currentCategory === `${category.slug}/top`}>
                  Top Products
                </SubCategory>
                {category.categories.edges.map(({node: subCategory}) => (
                  <SubCategory
                    key={subCategory.id}
                    onClick={this.handleCategoryClick(subCategory.slug)}
                    selected={currentCategory === subCategory.slug}>
                    {subCategory.name}
                  </SubCategory>
                ))}
              </SubCategories>
            </Category>
          ))}
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Categories), {
  viewer: graphql`
    fragment Categories_viewer on Viewer {
      categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
        edges {
          node {
            id
            name
            slug
            categories(filterBy: {isActive: true}) {
              edges {
                node {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `,
});
