import React from 'react';
import {Dropdown, Form} from 'semantic-ui-react';
// import styled from 'styled-components';
import {states} from '../../helpers/states';

// const Title = styled.h1`
// 	font-weight: normal;
// 	font-size: 21px;
// `;

// const Section = styled.div`
// 	padding: 0 0 ${props => props.last ? 0 : 1}em 0;
// `;

// TODO: A button to use the existing or clear to a blank address
// useExisting: true,
// handleClick = (e) => {
//   e.preventDefault();
//   this.setState((prevState) => ({useExisting: !prevState.useExisting}));
// }
// const {address: existingAddress, title, ...rest} = this.props;
// const {useExisting} = this.state;
// const address = useExisting ? existingAddress : {street: ''};
// {/*<AddressButton onClick={this.handleClick}>{useExisting ? 'Use new address' : 'Use existing address'}
// </AddressButton>*/}

class Address extends React.Component {
  render() {
    return (
      <div>
        <Form.Group widths="equal">
          <Form.Input
            error={this.props.value.firstName.errors.length > 0}
            label="First Name"
            name="firstName"
            onChange={this.props.onChange}
            placeholder="First Name"
            size="small"
            value={this.props.value.firstName.value || ''}
          />
          <Form.Input
            error={this.props.value.lastName.errors.length > 0}
            label="Last Name"
            name="lastName"
            onChange={this.props.onChange}
            placeholder="Last Name"
            size="small"
            value={this.props.value.lastName.value || ''}
          />
        </Form.Group>
        <Form.Input
          error={this.props.value.street.errors.length > 0}
          label="Address"
          name="street"
          onChange={this.props.onChange}
          placeholder="Address"
          size="small"
          value={this.props.value.street.value || ''}
        />
        <Form.Group>
          <Form.Input
            error={this.props.value.city.errors.length > 0}
            label="City"
            name="city"
            onChange={this.props.onChange}
            placeholder="City"
            size="small"
            value={this.props.value.city.value || ''}
            width={6}
          />
          <Form.Field
            control={Dropdown}
            error={this.props.value.state.errors.length > 0}
            label="State"
            name="state"
            onChange={this.props.onChange}
            options={states}
            search
            selection
            size="small"
            value={this.props.value.state.value || ''}
            width={6}
          />
          <Form.Input
            error={this.props.value.zip.errors.length > 0}
            label="Zip"
            name="zip"
            onChange={this.props.onChange}
            placeholder="Zip"
            size="small"
            value={this.props.value.zip.value || ''}
            width={4}
          />
        </Form.Group>
      </div>
    );
  }
}

export default Address;
