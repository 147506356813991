import isFunction from 'lodash/isFunction';

export function validate(data, rules = null, field = null) {
  return (field ? [field] : Object.keys(data)).reduce((result, key) => {
    // If null reset validation
    if (rules === null) {
      const value = data[key].value;
      const errors = [];
      return field ? {errors, value} : {...result, [key]: {errors, value}};
    } else {
      const fieldRules = rules[key] || [];
      if (fieldRules.length) {
        const value = data[key].value;
        const errors = fieldRules.reduce((errors, rule) => {
          const check = isFunction(rule[0]) ? rule[0](value, data) : rule[0];
          if (check) {
            errors.push(rule[1] || 'Unknown error');
          }
          return errors;
        }, []);

        return field ? {errors, value} : {...result, [key]: {errors, value}};
      } else {
        return field ? data[field] : {...result, [key]: data[key]};
      }
    }
  }, {});
}
