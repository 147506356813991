import {Button as _Button} from 'semantic-ui-react';
import styled from 'styled-components';

export const BlueButton = styled(_Button)`
  &.ui.button {
    color: #fff;
    background: #2196f3;
  }

  &.ui.button:hover {
    color: #fff;
    background: #1976d2;
  }

  &.ui.button:active {
    background: #0d47a1;
  }
`;

export const OrangeButton = styled(_Button)`
  &.ui.button {
    color: #fff;
    background: #f57c00;
  }

  &.ui.button:hover {
    color: #fff;
    background: #ef6c00;
  }

  &.ui.button:active {
    background: #e65100;
  }
`;

export const RedButton = styled(_Button)`
  &.ui.button {
    color: #fff;
    background: #f44336;
  }

  &.ui.button:hover {
    color: #fff;
    background: #d32f2f;
  }

  &.ui.button:active {
    background: #b71c1c;
  }
`;

const BaseButton = styled.button`
  outline: none;

  display: inline-block;

  font-family: 'PT Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;

  line-height: 1.42857;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  box-shadow: none;
  background-color: #2196f3;
  color: white;
  line-height: 22px;

  &:hover {
    background: #1976d2;
  }

  &:active {
    background: #1565c0;
  }
`;

export const Button = styled(BaseButton)`
  position: relative;
  margin: 0 30px 2px 16px;
  padding: 6px 14px 6px 12px;
  vertical-align: inherit;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;

  & > .icon {
    margin-right: 0.5rem;
    font-size: 20px;
  }
`;

export const Button2 = styled(BaseButton)`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 10px;
  vertical-align: inherit;

  font-size: 13px;
  font-weight: bold;
  line-height: 22px;

  & > .icon {
    margin: 0;
    font-size: 11px;
  }

  &:active {
    background: #1565c0;
  }
`;

export const RedButton2 = styled(Button2)`
  color: #fff;
  background: #f44336;

  &:hover {
    color: #fff;
    background: #d32f2f;
  }

  &:active {
    background: #b71c1c;
  }
`;

export const OrangeButton2 = styled(Button2)`
  color: #fff;
  background: #fb8c00;

  &:hover {
    color: #fff;
    background: #ef6c00;
  }

  &:active {
    background: #e65100;
  }
`;
