import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation DeleteSplitRequestMutation($input: DeleteSplitRequestInput!) {
    deleteSplitRequest(input: $input) {
      deletedSplitRequestId
    }
  }
`;

export const DeleteSplitRequestMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        deletedIDFieldName: 'deletedSplitRequestId',
        type: 'NODE_DELETE',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['splitRequestId']),
    },
  });
};
