import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation RateProductMutation($input: RateProductInput!) {
    rateProduct(input: $input) {
      viewer {
        id
      }
    }
  }
`;

export const RateProductMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['productId', 'rating', 'comment', 'isAnonymous']),
    },
  });
};
