import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Breadcrumb, Dropdown} from 'semantic-ui-react';
import styled from 'styled-components';
import {withWindowSize} from '../../helpers';
import getCartItemForProduct from '../../helpers/getCartItemForProduct';
import StoreCategories from '../Store/StoreCategories';
import ProductItem from './ProductItem';

const PER_PAGE = 30;

const Container = styled.div`
  display: flex;
  background: #fff;
`;

const ProductsList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  & .ui.breadcrumb {
    font-size: 16px;
  }
  & .ui.breadcrumb .active.section {
    color: black;
    cursor: default;
  }
`;

const ProductFilters = styled.div`
  display: flex;
  padding: 14px 25px 13px 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5edec;

  & .ui.dropdown .menu {
    left: -100px;
  }

  & .ui.breadcrumb .section {
    line-height: 1.5em;
  }
`;

const ProductList = styled.div`
  padding: 30px 25px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    padding: 10px;
  }
`;

const EmptyState = styled.div`
  display: flex;
  justify-content: top;
  align-items: center;
  padding: 1em 0.5em;

  & > p {
    flex: 1;
  }
`;

const Loading = styled.div`
  padding: 25px 0 50px 0;
  font-size: 1.25em;
  text-align: center;
`;

const sortOptions = [
  {text: 'Sort by Popularity', value: 'products.rank:ASC'},
  {text: 'Sort by Brand & Name', value: 'products.name:ASC'},
  {text: 'Sort by Price: Lowest First', value: 'cost:ASC'},
  {text: 'Sort by Price: Highest First', value: 'cost:DESC'},
  {text: 'Sort by Unit Price: Lowest First', value: 'unit:ASC'},
  {text: 'Sort by Unit Price: Highest First', value: 'unit:DESC'},
];

class ClearanceItems extends React.Component {
  state = {
    breadcrumbs: [],
    href: window.location.href,
    isLoading: true,
    pathname: '',
    products: null,
    sort: 'products.rank:ASC',
  };

  static getDerivedStateFromProps(props, state) {
    let href = window.location.href;
    href = href.indexOf('/products/') === -1 && href.indexOf('/cart') === -1 ? href : state.href;

    let isLoading = state.isLoading;
    let products = state.products;

    // First pass on search change, wait for the second pass to change the href and products
    if (state.href !== href) {
      isLoading = true;
      ClearanceItemsContainer.refetchVariables.offset = 0;

      if (products !== null) {
        // Values for the 2nd pass when searching
        products = null;
        href = state.href;
      }
    } else {
      // Initialize the products on initial load or search from search bar 3rd pass
      if (state.products === null) {
        isLoading = false;
        products = (props.viewer?.clearanceItems?.edges || []).map((v) => v.node);
      }
    }

    // Scroll to Top
    if (href !== state.href) {
      window.scrollTo(0, 0);
    }

    return {
      href,
      isLoading,
      products,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  //

  hasMore = () => {
    const total = this.props.viewer?.clearanceItems?.total || 0;
    const loaded = (this.state.products || []).length;
    return total !== loaded;
  };

  //

  handleScroll = () => {
    if (!this.hasMore() || this.state.isLoading) {
      return;
    }

    const productList = document.getElementById('productsList');
    const contentHeight = (productList && productList.clientHeight) || window.innerHeight;
    if (window.innerHeight + window.scrollY <= contentHeight) {
      return;
    }

    this.setState({isLoading: true});

    ClearanceItemsContainer.refetchVariables.offset = ClearanceItemsContainer.refetchVariables.offset + PER_PAGE;

    this.props.relay.refetch(ClearanceItemsContainer.refetchVariables, null, (err) => {
      this.setState((prevState) => ({
        isLoading: false,
        products: [...prevState.products, ...(this.props.viewer?.clearanceItems?.edges || []).map((v) => v.node)],
      }));
    });
  };

  handleSort = (e, {value, ...rest}) => {
    if (this.state.sort === value) {
      return;
    }

    if (this.hasMore()) {
      this.setState({isLoading: true});

      ClearanceItemsContainer.refetchVariables.offset = 0;
      ClearanceItemsContainer.refetchVariables.orderBy = [value.split(':')];

      this.props.relay.refetch(ClearanceItemsContainer.refetchVariables, null, (e) => {
        this.setState({
          isLoading: false,
          products: (this.props.viewer?.clearanceItems?.edges || []).map((v) => v.node),
          sort: value,
        });
      });
    } else {
      // Sort in place
      const products = (this.state.products || []).sort((a, b) => {
        const [column, direction] = value.split(':');

        let comparisson = 0;

        if (column === 'cost') {
          comparisson = (direction === 'ASC' ? 1 : -1) * (Number(a.cost) - Number(b.cost));
        } else if (column === 'unit') {
          comparisson = (direction === 'ASC' ? 1 : -1) * (Number(a.unit) - Number(b.unit));
        } else if (column === 'products.rank') {
          comparisson = a.product.rank - b.product.rank;
        }

        if (comparisson === 0) {
          return a.product.fullName.localeCompare(b.product.fullName);
        }

        return comparisson;
      });

      this.setState({products, sort: value});
    }
  };

  //

  handleBreadcrumbClick = (slug) => (e) => {
    this.props.router.push(`/categories/${slug}`);
  };

  //

  render() {
    const {viewer} = this.props;
    const {cart} = viewer;
    const cartItems = (cart?.items?.edges || []).map(({node}) => node);

    const products = this.state.products || [];

    const isMobile = this.props.isMobile;

    return (
      <Container>
        {!isMobile && <StoreCategories viewer={viewer} />}
        <ProductsList>
          <ProductFilters>
            <Breadcrumb
              icon="right angle"
              sections={[
                {
                  active: true,
                  content: 'Clearance',
                  key: 'clearance',
                  link: false,
                  onClick: this.handleBreadcrumbClick('clearance'),
                },
              ]}
            />
            <Dropdown inline name="sort" onChange={this.handleSort} options={sortOptions} value={this.state.sort} />
          </ProductFilters>
          <ProductList id="productsList">
            {!this.state.isLoading && products.length === 0 && (
              <EmptyState>
                <p>No Products found.</p>
              </EmptyState>
            )}
            {products.map((clearanceItem) => (
              <ProductItem
                cart={cart}
                cartItem={getCartItemForProduct(cartItems, clearanceItem.product.id, {clearanceItemId: clearanceItem.id})}
                clearanceItem={clearanceItem}
                key={clearanceItem.id}
                product={clearanceItem.product}
                viewer={viewer}
              />
            ))}
          </ProductList>
          {this.state.isLoading && <Loading>Loading...</Loading>}

          {this.props.children}
        </ProductsList>
      </Container>
    );
  }
}

const ClearanceItemsContainer = createRefetchContainer(
  withRouter(withWindowSize(ClearanceItems)),
  {
    viewer: graphql`
      fragment ClearanceItems_viewer on Viewer
      @argumentDefinitions(orderBy: {type: "[[String]]"}, offset: {type: "Int"}, limit: {type: "Int"}) {
        ...ProductItem_viewer
        ...StoreCategories_viewer
        clearanceItems(limit: $limit, offset: $offset, orderBy: $orderBy) {
          edges {
            node {
              cost
              id
              product {
                ...ProductItem_product
                fullName
                id
                productId
                rank
              }
              qty
              unit
            }
          }
          total
        }
        cart {
          ...ProductItem_cart
          items(first: 10000) {
            edges {
              node {
                ...ProductItem_cartItem
                clearanceItemId
                productId
                specialItemId
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query ClearanceItems_Refetch_Query($orderBy: [[String]], $offset: Int, $limit: Int) {
      viewer {
        ...ClearanceItems_viewer @arguments(orderBy: $orderBy, offset: $offset, limit: $limit)
      }
    }
  `,
);

//
ClearanceItemsContainer.refetchVariables = {};

ClearanceItemsContainer.getInitialVariables = (params) => {
  const variables = cloneDeep(
    defaultsDeep(ClearanceItemsContainer.refetchVariables, {
      limit: PER_PAGE,
      offset: 0,
      orderBy: [['products.rank', 'ASC']],
    }),
  );

  variables.offset = 0;
  variables.limit = PER_PAGE;

  ClearanceItemsContainer.refetchVariables = variables;

  return ClearanceItemsContainer.refetchVariables;
};

export default ClearanceItemsContainer;
