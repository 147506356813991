/**
 * @generated SignedSource<<bb0a39129ae4f767d6c49919fa87b7c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Reorder_viewer$data = {
  readonly cart: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly clearanceItemId: string | null | undefined;
          readonly productId: string | null | undefined;
          readonly specialItemId: string | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cartItem">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cart">;
  } | null | undefined;
  readonly products: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cost: number | null | undefined;
        readonly fullName: string | null | undefined;
        readonly id: string;
        readonly rank: number | null | undefined;
        readonly unit: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"ProductItem_product">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProductItem_viewer">;
  readonly " $fragmentType": "Reorder_viewer";
};
export type Reorder_viewer$key = {
  readonly " $data"?: Reorder_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Reorder_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Reorder_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductItem_viewer"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductItem_cart"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10000
            }
          ],
          "concreteType": "CartItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CartItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CartItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProductItem_cartItem"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "clearanceItemId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "productId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "specialItemId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:10000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filterBy",
          "value": {
            "isActive": true,
            "isAvailable": true,
            "slug": "reorder"
          }
        },
        {
          "kind": "Literal",
          "name": "limit",
          "value": 12
        },
        {
          "kind": "Literal",
          "name": "offset",
          "value": 0
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            [
              "rank",
              "ASC"
            ]
          ]
        }
      ],
      "concreteType": "ProductConnection",
      "kind": "LinkedField",
      "name": "products",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProductItem_product"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rank",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "products(filterBy:{\"isActive\":true,\"isAvailable\":true,\"slug\":\"reorder\"},limit:12,offset:0,orderBy:[[\"rank\",\"ASC\"]])"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "c1ced2c626a2916134c7efb7c8b0c4f1";

export default node;
