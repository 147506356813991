import {isEmail} from '@ivosabev/helpers';
import {Link, withRouter} from 'found';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import {Button, Form, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {CP_ACCESS_TOKEN} from '../constants';
import {getQueryParams} from '../helpers';
import {LoginMutation} from '../mutations/LoginMutation';

const Container = styled(Form)`
  min-height: 100vh;
  background: #fff;
`;

const Wrapper = styled.div`
  max-width: 480px;
  margin: 0 auto 100px auto;
  padding: 20px;
`;

const Column = styled.div`
  margin-bottom: 20px;
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Hr = styled.div`
  margin: 30px 0 50px 0;
  line-height: 16px;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
  & span {
    position: relative;
    bottom: -8px;
    background: #fff;
    text-align: center;
    padding: 0 15px;
    font-weight: bold;
  }
`;

class Login extends React.Component {
  state = {
    email: {error: false, value: ''},
    messages: [],
    password: {error: false, value: ''},
  };

  go = (link) => (e) => {
    e.preventDefault();
    this.props.router.push(link);
  };

  handleChange = (e, {checked, name, type, value}) => {
    if (type === 'checkbox') {
      value = checked;
    }

    if (typeof value === String) {
      value = value.trim();
    }

    //
    let error = false;
    if (name === 'email') {
      if (!isEmail(value)) {
        error = true;
      }
    }

    this.setState((prevState) => ({[name]: {...prevState[name], error, value}}));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {email, password} = this.state;

    // Validations
    let error = false;
    const errors = {
      email: {...email, error: false},
      password: {...password, error: false},
    };

    if (!isEmail(email.value)) {
      error = true;
      errors.email = {...email, error: true};
    }

    if (password.length < 6) {
      error = true;
      errors.password = {...password, error: true};
    }

    if (error) {
      this.setState({form: errors});
      return;
    }

    //
    const allowed = ['email', 'password'];

    //
    const payload = {
      input: {
        ...Object.keys(this.state)
          .filter((f) => allowed.indexOf(f) >= 0)
          .reduce((p, key) => ({...p, [key]: this.state[key].value}), {}),
      },
    };

    const onSuccess = (response) => {
      if (get(response, 'login.errors')) {
        const messages = [];
        const errors = (get(response, 'register.errors') || []).reduce((p, c) => {
          messages.push(c.message);
          return {...p, [c.field]: {...this.state[c.field], error: true}};
        }, {});
        this.setState({messages, ...errors});
      } else {
        const {
          login: {accessToken},
        } = response;
        Cookies.set(CP_ACCESS_TOKEN, accessToken, {expires: 7});

        const {redirect = null} = getQueryParams(window.location.search);
        this.props.router.push(redirect || '/');

        // TODO: Change the environment without reload ?
        window.location.reload();
      }
    };

    const onFailure = (response) => {
      console.log('Fail', response);

      const messages = [];
      const errors = response.reduce((p, c) => {
        messages.push(c.message);
        return {...p, [c.field]: {...this.state[c.field], error: true}};
      }, {});
      this.setState({messages, ...errors});
    };

    LoginMutation(payload, onSuccess, onFailure);
  };

  render() {
    const {email, messages, password} = this.state;

    return (
      <Container error={!isEmpty(messages)} onSubmit={this.handleSubmit}>
        <Wrapper>
          <Inner>
            <h3 style={{textAlign: 'center'}}>Sign in to your account</h3>
          </Inner>
          <Message content={messages.join('<br />')} error header="Please fix the following problems" />
          <Column>
            <Inner>
              <Form.Input
                error={email.error}
                label="Email"
                name="email"
                onChange={this.handleChange}
                placeholder=""
                required
                value={email.value}
              />
              <Form.Input
                error={password.error}
                label="Password"
                name="password"
                onChange={this.handleChange}
                placeholder="At least 6 characters"
                required
                type="password"
                value={password.value}
              />
              <div style={{textAlign: 'right'}}>
                <Link to="/forgot">Forgot password?</Link>
              </div>
            </Inner>
          </Column>
          <div style={{padding: '20px 20px'}}>
            <Form.Button fluid primary size="large" type="submit">
              Sign In
            </Form.Button>
            <Hr>
              <span>Don't have an account?</span>
            </Hr>
            <Button basic color="blue" fluid onClick={this.go('/register')}>
              Create a new account
            </Button>
          </div>
        </Wrapper>
      </Container>
    );
  }
}

export default withRouter(Login);
