/**
 * @generated SignedSource<<d1eb796dfc2eb28e30ab7aa7536f6317>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductItem_cart$data = {
  readonly id: string;
  readonly " $fragmentType": "ProductItem_cart";
};
export type ProductItem_cart$key = {
  readonly " $data"?: ProductItem_cart$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductItem_cart">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductItem_cart",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};

(node as any).hash = "2171b79a668440453fdf972210b609f4";

export default node;
