import styled from 'styled-components';

const Container = styled.div`
  padding: 20px 0 40px 0;
  min-height: 100vh;
  background: #f7f7f7;
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 25px;
  max-width: 960px;
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const About = () => {
  return (
    <Container>
      <Content>
        <h2>Co-op Objective</h2>
        <p>Our mission is to provide maximum product diversity with rock bottom prices and exceptional customer service.</p>
        <h2>About the Founders</h2>
        <p>
          Chaim and Katie White started KC Kosher Co-op to bring kosher food to Kansas City after the local kosher grocer closed down. The
          Co-op name is a play with "KC" actually standing for "Katie and Chaim", but with the pleasant double-meaning that includes the
          city where it all started--Kansas City. KC Kosher Co-op has since expanded to service a number of cities across the United States.
        </p>
        <p>
          Chaim and Katie came to Kansas City traveling for work in 2001 and in 2002 relocated to KC because we wanted to join the warm,
          vibrant Jewish community here. We are parents to six children - Matan, Shoshana, Nachman, Noam-Eliezer, Orli, and Ben-Tzion
          (Bentzi). Katie is the CEO of our household and Chaim is an Executive at a computer consulting company, Accenture
          (www.accenture.com).
        </p>
        <img alt="" src="/site/img/whites_large.jpg" />
      </Content>
    </Container>
  );
};

export default About;
