import {DateTime, money} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Button, Grid, Image, Popup} from 'semantic-ui-react';
import styled from 'styled-components';
import {decimal} from '../../helpers';
import {AddCartItemMutation} from '../../mutations/AddCartItemMutation';

const getStatus = (status) => {
  if (status === 'next') {
    return 'Scheduled for next Cycle';
  } else if (status === 'current') {
    return 'Processing';
  } else {
    return 'Dispatched';
  }
};

const Container = styled.div`
  margin-bottom: 20px;
`;

const Content = styled.div`
  padding: 20px;

  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const OrderInfo = styled(Grid)``;

const OrderItems = styled(Image.Group)`
  margin-top: 20px !important;
  min-height: 88px;
`;

const OrderInfoTitle = styled.div`
  color: #666;
  font-size: 14px;
`;

const OrderInfoContent = styled.div`
  font-size: 14px;

  &.next {
    color: #f57c00;
  }

  &.current {
    color: #2196f3;
  }

  &.archived {
    color: #090;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
`;

const OrderItem = ({orderItem, router}) => {
  const {product} = orderItem;

  const imageUrl = get(product, 'image.url', '/next/img/default-product.png').replace('-raw', '-images').replace('.jpg', '_small.jpg');

  return (
    <Popup
      content={`
				${product.package}
				\n$${decimal(product.cost)}
			`}
      header={product.fullName}
      size="mini"
      trigger={
        <Image
          onClick={() => router.push('/products/' + product.productId)}
          src={imageUrl}
          style={{cursor: 'pointer', height: 'auto', maxHeight: 80, maxWidth: 80, opacity: 0.8, width: 'auto'}}
        />
      }
      wide
    />
  );
};

class OrderRow extends React.Component {
  handleAddToCart = (e) => {
    e.preventDefault();

    const payload = {
      input: {
        orderId: this.props.order.id,
        productId: null,
        userId: this.props.userId,
      },
    };
    const onSuccess = () => {};
    const onFailure = () => {};

    AddCartItemMutation(payload, onSuccess, onFailure);
  };

  render() {
    const {createdAt, items, rowId: orderId, status, total} = this.props.order;

    const uniqueProductIds = [];
    const filterUniqueProducts = ({node: item}) => {
      const productId = (item && item.product && item.product.productId) || null;
      if (productId && uniqueProductIds.indexOf(productId) === -1) {
        uniqueProductIds.push(productId);
        return true;
      }
      return false;
    };

    const NA = 'N/A';
    const pickupDate = get(this.props.order, 'cycle.pickup') || null;

    return (
      <Container>
        <Content>
          <Grid>
            <Grid.Column width={11}>
              <OrderInfo>
                <Grid.Column width={4}>
                  <OrderInfoTitle>Placed on:</OrderInfoTitle>
                  <OrderInfoContent>{createdAt ? DateTime.fromISO(createdAt).toFormat('MMM d, y') : NA}</OrderInfoContent>
                </Grid.Column>
                <Grid.Column width={4}>
                  <OrderInfoTitle>Pickup on:</OrderInfoTitle>
                  <OrderInfoContent>{pickupDate ? DateTime.fromISO(pickupDate).toFormat('MMM d, y') : NA}</OrderInfoContent>
                </Grid.Column>
                <Grid.Column width={4}>
                  <OrderInfoTitle>Total:</OrderInfoTitle>
                  <OrderInfoContent>{money(total)}</OrderInfoContent>
                </Grid.Column>
                <Grid.Column width={3}>&nbsp;</Grid.Column>
              </OrderInfo>
              <OrderItems>
                {items.edges.filter(filterUniqueProducts).map(({node: orderItem}) => (
                  <OrderItem key={orderItem.id} orderItem={orderItem} router={this.props.router} />
                ))}
              </OrderItems>
            </Grid.Column>
            <Right width={4}>
              <div>
                <OrderInfoTitle>Order #: {orderId}</OrderInfoTitle>
                <OrderInfoContent>
                  <Link to={`/orders/${orderId}`}>Order Details</Link>
                  {/*
										&nbsp;|&nbsp;
										<Link to={`/ordesr/${orderId}/invoice`}>Invoice</Link>
									*/}
                </OrderInfoContent>

                <OrderInfoTitle style={{marginTop: 5}}>Status:</OrderInfoTitle>
                <OrderInfoContent className={status}>{getStatus(status)}</OrderInfoContent>
              </div>
              <Button color="green" disabled={!items.edges.length} onClick={this.handleAddToCart} size="tiny">
                Add all items to Cart
              </Button>
            </Right>
          </Grid>
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(OrderRow), {
  order: graphql`
    fragment OrderRow_order on Order {
      id
      rowId
      status
      total
      createdAt
      cycle {
        pickup
      }
      items(first: 1000) {
        edges {
          node {
            id
            product {
              productId
              fullName
              package
              cost
              image {
                url
              }
            }
          }
        }
      }
    }
  `,
});
