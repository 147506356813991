import rafSchedule from 'raf-schd';
import React from 'react';

const InnerWindowSizeContext = React.createContext(null);

class WindowSizeContextProvider extends React.Component {
  state = {
    height: window.innerHeight,
    isMobile: window.innerWidth <= 1024,
    width: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = rafSchedule(() => {
    this.setState({
      height: window.innerHeight,
      isMobile: window.innerWidth <= 1024,
      width: window.innerWidth,
    });
  });

  render() {
    return <InnerWindowSizeContext.Provider value={this.state}>{this.props.children}</InnerWindowSizeContext.Provider>;
  }
}

export const withWindowSize = (WrappedComponent) => {
  return class WithWindowSize extends React.Component {
    static displayName = `withWindowSize(${WrappedComponent.displayName || WrappedComponent.name || 'UnknownComponent'})`;

    render() {
      const {forwardedRef, ...rest} = this.props;

      return (
        <WindowSizeContext.Consumer>
          {(context) => <WrappedComponent ref={forwardedRef} {...context} {...rest} />}
        </WindowSizeContext.Consumer>
      );
    }
  };

  // return React.forwardRef((props, ref) => {
  //   return <WithWindowSize {...this.props} forwardedRef={ref} />;
  // });
};

export const WindowSizeContext = {
  Consumer: InnerWindowSizeContext.Consumer,
  Provider: WindowSizeContextProvider,
};
