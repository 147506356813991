import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Button, Form, Message, Modal, Tab, Table} from 'semantic-ui-react';
import styled from 'styled-components';
import {toGlobalId} from '../helpers/toGlobalId';
import {DeleteSplitRequestMutation} from '../mutations/DeleteSplitRequestMutation';
import {SendSplitRequestMutation} from '../mutations/SendSplitRequestMutation';
import {CustomIcon} from './CustomIcon/CustomIcon';

const Container = styled.div``;
const Column = styled.div`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Heading = styled.h2``;

const STATE_DEFAULTS = {
  errors: {
    messages: [],
  },
  from: '',
  isSubmitting: false,
  message: '',
  splitProduct: null,
  splitUser: null,
  subject: '',
  success: '',
  to: '',
};

class Splits extends React.Component {
  state = {
    ...STATE_DEFAULTS,
  };

  handleSplit = (splitProduct, splitUser) => (e) => {
    const to = splitUser.fullName;
    const from = get(this, 'props.viewer.user.email') || null;
    const subject = `KC Kosher Co-op - ${splitProduct.fullName}`;

    /* eslint-disable max-len */
    const message = `Hi ${splitUser.firstName},
I am interested in arranging a split of ${
      splitProduct.fullName
    } on the kckoshercoop.com website. Please contact me so we can arrange who will purchase the product and then after (or at) delivery how we will meet to split the product and cost.

Thanks,
${get(this, 'props.viewer.user.fullName')}`;
    /* eslint-enable max-len */

    this.setState({
      from,
      message,
      splitProduct,
      splitUser,
      subject,
      to,
    });
  };

  handleFieldChange = (e, {name, type, value, ...rest}) => {
    if (type === 'checkbox') {
      value = rest.checked;
    }

    this.setState({[name]: value});
  };

  handleModalClose = (e) => {
    e && e.preventDefault();

    this.setState({...STATE_DEFAULTS});
  };

  handleDeleteSplitRequest = (splitRequestId) => (e) => {
    e.preventDefault();

    if (!window.confirm('Do you really want to delete your split request?')) {
      return;
    }

    const payload = {
      input: {
        splitRequestId,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        success: 'The Split Request has been deleted.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        error: '',
      });
    };

    DeleteSplitRequestMutation(payload, onSuccess, onFailure);
  };

  handleSendSplitRequest = (e) => {
    e.preventDefault();

    const payload = {
      input: {
        message: this.state.message,
        splitUserId: this.state.splitUser.id,
        subject: this.state.subject,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        ...STATE_DEFAULTS,
        success: 'Your split request has been sent.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        ...STATE_DEFAULTS,
        errors: {
          messages: ['There was a problem sending your Split'],
        },
      });
    };

    SendSplitRequestMutation(payload, onSuccess, onFailure);
  };

  renderModal = () => {
    const {from, isSubmitting, message, splitUser, subject, to} = this.state;

    if (!splitUser) {
      return null;
    }

    return (
      <Modal onClose={this.handleModalClose} open={true} size="small">
        <Modal.Header>Contact {splitUser.fullName}</Modal.Header>
        <Modal.Content>
          <Form>
            <p style={{marginTop: 0}}>
              <strong>To:</strong> {to}
            </p>
            <p>
              <strong>From:</strong> {from}
            </p>
            <p>
              <strong>Subject:</strong> {subject}
            </p>
            <Form.TextArea label="Message" name="message" onChange={this.handleFieldChange} rows={10} value={message} />
            <Button disabled={isSubmitting} onClick={this.handleSendSplitRequest} primary>
              Send Split Request
            </Button>{' '}
            <a href="#void" onClick={this.handleModalClose}>
              Cancel
            </a>
          </Form>
        </Modal.Content>
      </Modal>
    );
  };

  renderTable = (isMyRequests = false) => {
    const userId = get(this, 'props.viewer.user.id') || null;

    const rawSplitRequests = (get(this, 'props.viewer.splitRequests.edges') || [])
      .map((v) => v.node)
      .filter(Boolean)
      .filter((v) => (isMyRequests ? v.user.id === userId : true));

    const splitRequests = rawSplitRequests.sort((a, b) => a.product.fullName.localeCompare(b.product.fullName));

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={9}>Product</Table.HeaderCell>
            <Table.HeaderCell width={5}>Person Requesting Split</Table.HeaderCell>
            <Table.HeaderCell width={2} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {splitRequests.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={isMyRequests ? 4 : 3} textAlign="center">
                <div style={{padding: '100px 0'}}>
                  {isMyRequests ? 'You do not have any Split Requests.' : 'There are no Split Requests for this order cycle.'}
                </div>
              </Table.Cell>
            </Table.Row>
          )}
          {splitRequests.map((splitRequest) => {
            const variantId = splitRequest.clearanceItemId
              ? toGlobalId('ClearanceItem', splitRequest.clearanceItemId)
              : splitRequest.specialItemId
              ? toGlobalId('SpecialItem', splitRequest.specialItemId)
              : '';
            const url = `/products/${splitRequest.product.productId}/${variantId}`;

            return (
              <Table.Row key={splitRequest.id}>
                <Table.Cell width={10}>
                  <Link to={url}>
                    {(splitRequest.clearanceItemId || splitRequest.specialItemId) && (
                      <CustomIcon
                        name="fas-badge-percent"
                        style={{
                          color: splitRequest.clearanceItemId ? '#eb5f00' : '#ff9800',
                          fontSize: 20,
                          marginRight: 4,
                          position: 'relative',
                          top: 1,
                        }}
                      />
                    )}
                    {splitRequest.product.fullName}
                  </Link>
                </Table.Cell>
                <Table.Cell width={4}>
                  {splitRequest.user.fullName} {splitRequest.user.id === userId ? '(You)' : ''}
                </Table.Cell>
                <Table.Cell textAlign="center" verticalAlign="middle" width={2}>
                  {splitRequest.user.id === userId ? (
                    <Button color="red" onClick={this.handleDeleteSplitRequest(splitRequest.id)}>
                      Delete
                    </Button>
                  ) : (
                    <Button onClick={this.handleSplit(splitRequest.product, splitRequest.user)} primary>
                      Split
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const panes = [
      {menuItem: 'All Requests', render: () => <Tab.Pane>{this.renderTable()}</Tab.Pane>},
      {menuItem: 'View or Delete Your Requests', render: () => <Tab.Pane>{this.renderTable(true)}</Tab.Pane>},
    ];

    return (
      <Container>
        {this.renderModal()}
        {this.state.errors.messages.length > 0 && (
          <Message
            content={
              <div>
                {this.state.errors.messages.map((v, i) => (
                  <p key={i}>{v}</p>
                ))}
              </div>
            }
            error
          />
        )}
        {this.state.success.length > 0 && <Message content={<div>{this.state.success}</div>} success />}
        <Column>
          <Inner>
            <Heading>Product Splitting Page</Heading>
            <p>
              Below is a list of all the products that members have requested to split with. If you would like to contact someone on this
              list in order to split a product with them, click the <strong>Split</strong> button next to their name.
            </p>
            <p>
              After you have successfully arranged a split with another Co-op member, please remember to come back to this page and remove
              that product from this list.
            </p>
            <p>
              When splitting variable weight products, please remember that the pack size/weight is only an estimate based on experience.
              Suppliers will vary packaging, especially for institutional sized products.
            </p>
          </Inner>
        </Column>
        <br />
        <Tab panes={panes} />
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Splits), {
  viewer: graphql`
    fragment Splits_viewer on Viewer {
      id
      user {
        id
        email
        fullName
      }
      splitRequests(first: 10000) {
        edges {
          node {
            clearanceItemId
            id
            productId
            product {
              id
              productId
              fullName
            }
            specialItemId
            user {
              id
              firstName
              fullName
            }
          }
        }
      }
    }
  `,
});
