import React from 'react';
import styled from 'styled-components';

// TODO: Always load the default image as a background of the container?
const defaultImage = '/next/img/default-product.png';

const Container = styled.div`
  width: ${(props) => (props.size ? props.size : 180)}px;
  height: ${(props) => (props.size ? props.size : 180)}px;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${(props) => props.src || defaultImage}');
  background-size: ${(props) => (props.src ? 'contain' : '80%')};
  background-repeat: no-repeat;
  background-position: center;
  opacity: ${(props) => (props.src ? 1 : 0.33)};
`;

// type Props = {
//   src: string,
//   alt: string,
//   size: number,
// };

class ProductImage extends React.Component {
  // props: Props;

  render() {
    const {alt, size, src, ...rest} = this.props;

    return (
      <Container size={size} {...rest}>
        <Image src={src} title={alt} />
      </Container>
    );
  }
}

export default ProductImage;
