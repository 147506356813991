import {DateTime, money} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import {go} from '../../../helpers';
import {BlueButton} from '../../Common/Button';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    & > div {
      width: 100% !important;
    }
  }

  & > div {
    &:first-child {
      width: 49%;
      margin-bottom: 2em;
    }
    &:last-child {
      width: 49%;

      & > div > div {
        display: flex;
        margin-bottom: 1em;

        & h5 {
          margin-bottom: 1em;
          color: #2196f3;
        }

        & p {
          margin-bottom: 0.5em;
        }

        & > div {
          &:first-child {
            width: 20%;
          }

          &:last-child {
            width: 80%;
          }
        }
      }
    }

    & h4 {
      color: #e65100;
    }
  }
`;

const PayMyBill = (props) => {
  const viewer = props.viewer;

  const currentOrders = ((viewer && viewer.currentOrders && viewer.currentOrders.edges) || []).map((v) => v.node);
  const pastOrders = ((viewer && viewer.pastOrders && viewer.pastOrders.edges) || []).map((v) => v.node);

  const unpaidOrders = ((viewer && viewer.unpaidOrders && viewer.unpaidOrders.edges) || []).map((v) => v.node);
  const outstandingPayments = [];
  unpaidOrders.forEach((order) => {
    outstandingPayments.push({owed: order.owed, reason: 'Payment for Order ' + order.rowId});
  });

  const user = (viewer && viewer.user) || {};
  const credit = user.credit || 0;
  const owed = user.owed || 0;
  const reason = user.owedReason || 'No reason has been specified.';
  if (owed) {
    outstandingPayments.push({owed, reason});
  }

  return (
    <Container>
      <div>
        <h4>Current Order{currentOrders.length > 1 && 's'}</h4>
        {currentOrders.length === 0 && <p>You have not placed an order in this cycle.</p>}
        {currentOrders.map((order) => (
          <p key={order.rowId}>
            <a href={`/orders/${order.rowId}`} onClick={go(`/orders/${order.rowId}`, {router: props.router})}>
              <strong>{order.rowId}</strong> - {DateTime.fromSQL(order.createdAt).toFormat('MMMM d, yyyy')}
            </a>
          </p>
        ))}
        {pastOrders.length > 0 && (
          <>
            <h4>Last Order</h4>
            {pastOrders.map((order) => (
              <p key={order.rowId}>
                <a href={`/orders/${order.rowId}`} onClick={go(`/orders/${order.rowId}`, {router: props.router})}>
                  <strong>{order.rowId}</strong> - {DateTime.fromISO(order.createdAt).toFormat('MMMM d, yyyy')}
                </a>
              </p>
            ))}
          </>
        )}
      </div>
      <div>
        {credit > 0 && (
          <>
            <h4>Available Credit</h4>
            <p>
              <strong>{money(credit)}</strong>
            </p>
          </>
        )}
        <h4>Outstanding Payments</h4>
        {outstandingPayments.length === 0 ? (
          <div>You do not have any outstanding payments.</div>
        ) : (
          <div>
            <div>
              <div>
                <h5>Amount:</h5>
                {outstandingPayments.map(({owed}, i) => (
                  <p key={i}>
                    <strong>{money(owed)}</strong>
                  </p>
                ))}
              </div>
              <div>
                <h5>Reason:</h5>
                {outstandingPayments.map(({reason}, i) => (
                  <p key={i}>{reason}</p>
                ))}
              </div>
            </div>
            <BlueButton onClick={go('/pay', {router: props.router})} size="large">
              Pay My Bill
            </BlueButton>
          </div>
        )}
      </div>
    </Container>
  );
};
export default createFragmentContainer(PayMyBill, {
  viewer: graphql`
    fragment PayMyBill_viewer on Viewer {
      unpaidOrders: orders(filterBy: {unpaid: true}, first: 100, orderBy: [["cycleId", "DESC"]]) {
        edges {
          node {
            id
            rowId
            owed
          }
        }
      }
      currentOrders: orders(first: 10000, filterBy: {cycleId: "current"}, orderBy: [["id", "ASC"]]) {
        edges {
          node {
            id
            rowId
            createdAt
          }
        }
      }
      pastOrders: orders(first: 1, filterBy: {cycleId: "past"}, orderBy: [["id", "ASC"]]) {
        edges {
          node {
            id
            rowId
            createdAt
          }
        }
      }
      user {
        credit
        owed
        owedReason
      }
    }
  `,
});
