/**
 * @generated SignedSource<<12a6355bb2751f1d8710be1fa186274d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DesktopHeader_viewer" | "MobileHeader_viewer">;
  readonly " $fragmentType": "Header_viewer";
};
export type Header_viewer$key = {
  readonly " $data"?: Header_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DesktopHeader_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MobileHeader_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "dc2ee107e0aba0ce97a0350a0baa4a37";

export default node;
