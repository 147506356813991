import {withRouter} from 'found';
import styled from 'styled-components';
import {go} from '../../../helpers';
import {OrangeButton} from '../../Common/Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & h2 {
    text-align: center;
  }

  & ul {
    margin: 0 0 3em 0;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    column-count: 2;

    & li {
      margin: 0;
      padding: 0;
      width: 50%;
      list-style: none;
    }
  }

  & > div:first-child {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & hr {
      margin: 0 2rem;
      padding: 0;
      width: 1px;
      height: 80px;
      background: #ccc;
      border: 0;
    }

    @media (max-width: 480px) {
      & hr {
        margin: 0;
        background: #fff;
      }
    }

    & h4 {
      margin: 0;
      text-align: center;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div`
  width: 68%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Right = styled.div`
  width: 28%;

  @media (max-width: 1024px) {
    & li {
      width: 100% !important;
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 100%;

    & li {
      width: 33% !important;
    }
  }

  @media (max-width: 480px) {
    margin-top: 20px;
    width: 100%;

    & li {
      width: 50% !important;
    }
  }
`;

const OrangeLink = styled.a`
  color: #f57c00;

  &:hover {
    color: #e65100;
    text-decoration: underline;
  }
`;

const CoopLocations = (props) => (
  <Container>
    <div>
      <OrangeButton onClick={go('/register', {router: props.router})} size="big">
        Join a Co-op
      </OrangeButton>
      <hr />
      <h4>
        No Co-op in your city? <OrangeLink href="/#start-a-co-op">Start your own</OrangeLink>
      </h4>
    </div>
    <br />
    <Content>
      <Left>
        <iframe
          height="600"
          src="https://www.google.com/maps/d/embed?mid=1RcUFOOn0hkq2nP-UG7NR_lJonHDUnXHU&z=4"
          title="Co-op Communities"
          width="100%"
        />
      </Left>
      <Right>
        <h3>Join a Co-op</h3>
        <ul>
          {[
            'Albuquerque, NM',
            'Atlanta, GA',
            'Berkeley, CA',
            'Cincinnati, OH',
            'Cleveland, OH',
            'Columbus, OH',
            'Dallas, TX',
            'Denver, CO',
            'Detroit, MI',
            'El Paso, TX',
            'Flagstaff, NM',
            'Houston, TX',
            'Indianapolis, IN',
            'Lake Havasu, AZ',
            'Las Vegas, NV',
            'Long Beach, CA',
            'Memphis, TN',
            'Nashville, TN',
            'Omaha, NE',
            'Overland Park, KS',
            'Phoenix, AZ',
            'Raleigh, NC',
            'San Antonio, TX',
            'Savannah, GA',
            'Seattle, WA',
            'Silicon Valley, CA',
            'St. Louis, MO',
            'Tucson, AZ',
          ].map((coop) => {
            return (
              <li>
                <a href={'/register?coop=' + coop} onClick={go('/register?coop=' + coop, {router: props.router})}>
                  {coop}
                </a>
              </li>
            );
          })}
        </ul>
      </Right>
    </Content>
  </Container>
);

export default withRouter(CoopLocations);
