import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Button, Dropdown, Message, Modal, Table} from 'semantic-ui-react';
import styled from 'styled-components';
import {decimal} from '../helpers';
import {AddSubstitutionMutation} from '../mutations/AddSubstitutionMutation';
import {DeleteSubstitutionMutation} from '../mutations/DeleteSubstitutionMutation';
import {ResetSubstitutionsMutation} from '../mutations/ResetSubstitutionsMutation';
import {CustomIcon} from './CustomIcon/CustomIcon';

const Container = styled.div`
  padding: 5px 0 0 0;
  font-size: 14px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em 0;
  width: 100%;
`;

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  & .ui.dropdown {
    margin-right: 10px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Products = styled.div``;

function getPrimaryCategory(categories, slug) {
  return (get(categories, 'edges') || []).map((v) => v.node).find((v) => v.slug === slug) || null;
}

function getOrdinalNumber(n) {
  switch (n) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    case 4:
      return '4th';
    case 5:
      return '5th';
    default:
      return 'N/A';
  }
}

function getInitialState(props) {
  const categories = (props.category || '').split('/');

  return {
    isOpen: false,
    primaryCategorySelectedSlug: categories[0] || null,
    secondaryCategorySelectedSlug: categories[1] ? categories[0] + '/' + categories[1] : null,
  };
}

class CartSubstitutions extends React.Component {
  state = {
    isOpen: true,
    primaryCategorySelectedSlug: null,
    secondaryCategorySelectedSlug: null,
  };

  constructor(props) {
    super(props);

    this.state = getInitialState(props);
  }

  refetchVariables = {};

  componentDidMount() {
    this.refetch(this.state.secondaryCategorySelectedSlug);
  }

  refetch = (slug) => {
    this.refetchVariables.filterBy = {
      isActive: true,
      isAvailable: true,
      slug,
    };
    this.props.relay.refetch(this.refetchVariables, null, () => this.setState({isLoading: false}));
  };

  handleLink = (href) => (e) => {
    this.handleClose(e);
    this.props.router.push(href);
  };

  handleOpen = (e) => {
    e.preventDefault();
    this.setState({isOpen: true});
  };

  handleClose = () => {
    const initialState = getInitialState(this.props);
    this.setState(initialState);
    this.refetch(initialState.secondaryCategorySelectedSlug);
  };

  handleCategoryChange = (e, {name, value}) => {
    if (name === 'primaryCategory') {
      this.setState({
        primaryCategorySelectedSlug: value,
        secondaryCategorySelectedSlug: get(getPrimaryCategory(this.props.viewer.categories, value), 'edges[0].node.slug') || null,
      });
    } else if (name === 'secondaryCategory') {
      this.setState({secondaryCategorySelectedSlug: value});
      this.refetch(value);
    }
  };

  handleReset = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      input: {
        productId: this.props.product.productId,
        viewerId: this.props.viewer.id,
      },
    };

    const onSuccess = () => {};

    const onFailure = () => {};

    ResetSubstitutionsMutation(payload, onSuccess, onFailure);
  };

  handleChooseSubstitute = (substitutionId) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      input: {
        productId: this.props.product.productId,
        substitutionId: substitutionId,
        viewerId: this.props.viewer.id,
      },
    };
    const onSuccess = () => {};
    const onFailure = () => {};

    AddSubstitutionMutation(payload, onSuccess, onFailure);
  };

  handleRemoveSubstitute = (substitution) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      input: {
        productId: this.props.product.productId,
        substitutionId: substitution.rowId,
        viewerId: this.props.viewer.id,
      },
    };

    const onSuccess = () => {};
    const onFailure = () => {};

    DeleteSubstitutionMutation(payload, onSuccess, onFailure);
  };

  renderCategories() {
    const {primaryCategorySelectedSlug, secondaryCategorySelectedSlug} = this.state;

    const _map = (v) => ({key: v.node.id, text: v.node.name, value: v.node.slug});

    const primaryCategories = (get(this.props.viewer, 'categories.edges') || []).map(_map);
    const secondaryCategories = (
      get(getPrimaryCategory(this.props.viewer.categories, primaryCategorySelectedSlug), 'categories.edges') || []
    ).map(_map);

    return (
      <Categories>
        <Dropdown
          fluid
          name="primaryCategory"
          onChange={this.handleCategoryChange}
          options={primaryCategories}
          search
          selection
          value={primaryCategorySelectedSlug}
        />
        <Dropdown
          fluid
          name="secondaryCategory"
          onChange={this.handleCategoryChange}
          options={secondaryCategories}
          search
          selection
          value={secondaryCategorySelectedSlug}
        />
      </Categories>
    );
  }

  renderButtons() {
    return (
      <Buttons>
        <Button onClick={this.handleReset} size="small">
          Reset
        </Button>
      </Buttons>
    );
  }

  renderChooseButton = (productId) => {
    const substitutions = get(this.props.item, 'substitutions') || [];
    const index = substitutions.findIndex((s) => s.substitutionId === productId) + 1;

    if (index) {
      return (
        <Button fluid onClick={this.handleRemoveSubstitute(substitutions[index - 1])} primary size="small">
          {getOrdinalNumber(index)}
        </Button>
      );
    } else {
      return (
        <Button disabled={substitutions.length === 5} fluid onClick={this.handleChooseSubstitute(productId)} size="small">
          Choose
        </Button>
      );
    }
  };

  render() {
    const product = get(this.props, 'product') || {};
    const substitutions = get(this.props.item, 'substitutions') || [];

    return (
      <Container>
        <Modal onClose={this.handleClose} open={this.state.isOpen} size="large">
          <Modal.Content>
            <div>
              <Message info size="small">
                <Message.Content>
                  <CustomIcon name="fas-info-circle" size="lg" /> You are not required to select substitutions, this step is optional.
                </Message.Content>
              </Message>
              <p>
                If <strong>{product.fullName}</strong> is not available, please to tell us what would be an acceptable alternate product.
                Select below the alternate products you approve us to try to obtain in the circumstance that your ordered product is not
                available. We will maintain these preferences for future orders.
              </p>
              <p>
                At any time, you can manage your {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={this.handleLink('/account/substitutions')}>
                  previously approved substitutions
                </a>
                .
              </p>
            </div>
            <br />
            <Actions>
              {this.renderCategories()}
              {this.renderButtons()}
            </Actions>
            <Products>
              <Table celled compact size="small">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={8}>Product</Table.HeaderCell>
                    <Table.HeaderCell width={4}>Package</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Unit</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Cost</Table.HeaderCell>
                    <Table.HeaderCell width={2} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(get(this.props.viewer.products, 'edges') || []).map(({node: product}) => (
                    <Table.Row key={product.productId}>
                      <Table.Cell>{product.fullName}</Table.Cell>
                      <Table.Cell>{product.package}</Table.Cell>
                      <Table.Cell>${decimal(product.unit)}</Table.Cell>
                      <Table.Cell>${decimal(product.cost)}</Table.Cell>
                      <Table.Cell>{this.renderChooseButton(product.productId)}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Products>
          </Modal.Content>
        </Modal>
        <em>Substitutions:</em> {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={this.handleOpen}>
          {substitutions.length ? 'Modify' : 'Add'}
        </a>
      </Container>
    );
  }
}

export default createRefetchContainer(
  withRouter(CartSubstitutions),
  {
    item: graphql`
      fragment CartSubstitutions_item on CartItem {
        substitutions {
          id
          rowId
          productId
          substitutionId
        }
      }
    `,
    product: graphql`
      fragment CartSubstitutions_product on Product {
        productId
        fullName
      }
    `,
    viewer: graphql`
      fragment CartSubstitutions_viewer on Viewer
      @argumentDefinitions(
        filterBy: {type: "ProductFilterInput"}
        orderBy: {type: "[[String]]"}
        page: {type: "Int"}
        limit: {type: "Int"}
      ) {
        id
        categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
          edges {
            node {
              id
              rowId
              name
              slug
              categories(filterBy: {isActive: true}) {
                edges {
                  node {
                    id
                    rowId
                    name
                    slug
                  }
                }
              }
            }
          }
        }
        products(first: 10000, limit: $limit, page: $page, filterBy: $filterBy, orderBy: $orderBy) {
          edges {
            node {
              id
              productId
              cost
              unit
              rank
              fullName
              package
              images(first: 1) {
                edges {
                  node {
                    previewUrl
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query CartSubstitutions_Refetch_Query(
      $filterBy: ProductFilterInput
      $orderBy: [[String]] # $page: Int # $limit: Int
    ) {
      viewer {
        ...CartSubstitutions_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy)
      }
    }
  `,
);
