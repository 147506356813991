import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation DeleteSubstitutionMutation($input: DeleteSubstitutionInput!) {
    deleteSubstitution(input: $input) {
      deletedSubstitutionId
    }
  }
`;

export const DeleteSubstitutionMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    updater: (store) => {
      const viewerRecord = store.get(input.viewerId);

      // Remove Substitition from Cart items
      const cart = viewerRecord.getOrCreateLinkedRecord('cart', 'Cart');
      const itemsConnectionEdges = store.get(`client:${cart.getDataID()}:items(first:10000)`).getLinkedRecords('edges');
      const itemEdge = itemsConnectionEdges.find(
        (v) => v.getLinkedRecord('node').getLinkedRecord('product').getValue('productId') === input.productId,
      );

      if (itemEdge) {
        const itemNode = itemEdge.getLinkedRecord('node');
        const substitutions = itemNode.getLinkedRecords('substitutions') || [];
        const newSubstititions = substitutions.filter((v) => v.getValue('rowId') !== input.substitutionId);
        itemNode.setLinkedRecords(newSubstititions, 'substitutions');
      }

      // Remove Subsitution from user.substitions
      const user = viewerRecord.getOrCreateLinkedRecord('user', 'User');
      const substitutions = user.getLinkedRecords('substitutions');
      if (substitutions) {
        const newSubstititions = substitutions.filter((v) => v.getValue('rowId') !== input.substitutionId);
        user.setLinkedRecords(newSubstititions, 'substitutions');
      }
    },
    variables: {
      input: pick(input, ['substitutionId']),
    },
  });
};
