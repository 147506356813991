import {withRouter} from 'found';
import styled from 'styled-components';
import {go, withWindowSize} from '../../helpers';

const Container = styled.div`
  width: 100%;
  background: #2196f3;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 1360px;
  display: flex;
  align-items: space-around;
  flex-wrap: wrap;
`;

const Column = styled.div`
  padding: 2rem;
  flex: 1;

  & h3 {
    color: #fff;
    font-size: 18px;
  }

  & ul {
    margin: 0;
    padding: 0;
  }

  & li {
    list-style: none;
  }

  & a {
    color: #fff;

    & :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 640px) {
    min-width: 50%;
  }
`;

const Credits = styled.div`
  width: 100%;
  background: #1976d2;

  & > p {
    margin: 0 auto;
    max-width: 1360px;
    height: 60px;

    padding: 0 2rem;
    color: #fff;
    font-size: 15px;
    line-height: 60px;
    vertical-align: center;
    text-decoration: none;
  }
`;

const Footer = (props) => (
  <Container>
    <Content>
      <Column>
        <h3>About</h3>
        <ul>
          <li>
            <a href="/contact" onClick={go('/contact', {router: props.router})}>
              Contact Us
            </a>
          </li>
          <li>
            <a href="/locations" onClick={go('/locations', {router: props.router})}>
              Locations
            </a>
          </li>
          <li>
            <a href="/about" onClick={go('/about', {router: props.router})}>
              Our Story
            </a>
          </li>
          <li>
            <a href="/terms" onClick={go('/terms', {router: props.router})}>
              Terms
            </a>
          </li>
        </ul>
      </Column>
      <Column>
        <h3>Help</h3>
        <ul>
          <li>
            <a href="/help#q2-1">Payments</a>
          </li>
          <li>
            <a href="/help#q3-3">Delivery &amp; Pickup</a>
          </li>
          <li>
            <a href="/help#q2-5">Cancellations &amp; Returns</a>
          </li>
          <li>
            <a href="/help" onClick={go('/help', {router: props.router})}>
              FAQ
            </a>
          </li>
        </ul>
      </Column>
      <Column>
        <h3>Products</h3>
        <ul>
          <li>
            <a href="/categories/specials" onClick={go('/categories/specials', {router: props.router})}>
              Specials
            </a>
          </li>
          <li>
            <a href="/categories/clearance" onClick={go('/categories/clearance', {router: props.router})}>
              Clearance
            </a>
          </li>
          <li>
            <a href="/categories/new" onClick={go('/categories/new', {router: props.router})}>
              New Products
            </a>
          </li>
          {props.isMobile && (
            <li>
              <a href="/search" onClick={go('/search', {router: props.router})}>
                Search
              </a>
            </li>
          )}
        </ul>
      </Column>
      <Column>
        <h3>Social</h3>
        <a href="https://www.facebook.com/kckoshercoop" rel="noopener noreferrer" target="_blank">
          Facebook
        </a>
      </Column>
    </Content>
    <Credits>
      <p>© {new Date().getFullYear()} KCKosherCoop.com. All rights reserved.</p>
    </Credits>
  </Container>
);

export default withRouter(withWindowSize(Footer));
