import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Grid} from 'semantic-ui-react';
import styled from 'styled-components';
import Totals from '../Common/Totals';
import OrderItemRow from './OrderItemRow';

const getStatus = (status) => {
  if (status === 'next') {
    return 'Scheduled for next Cycle';
  } else if (status === 'current') {
    return 'Processing';
  } else {
    return 'Dispatched';
  }
};

const Container = styled.div`
  min-height: 100vh;
  background: #f7f7f7;

  span.next {
    color: #f57c00;
  }

  span.current {
    color: #2196f3;
  }

  span.archived {
    color: #090;
  }
`;

const Content = styled.div`
  margin: 20px auto;
  padding: 0 1%;
  max-width: 1200px;
`;

const Column = styled(Grid.Column)`
  padding: 20px;

  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

class Order extends React.Component {
  render() {
    const {viewer} = this.props;
    const {order} = viewer;

    // ???
    if (!order) {
      return null;
    }

    const items = (order && order.items && order.items.edges) || [];

    const showTotals = order.status !== 'archived';

    return (
      <Container onSubmit={this.handleSubmit}>
        <Content>
          <Grid stackable>
            <Grid.Column width={12}>
              <Column>
                {items.map(({node: orderItem}) => (
                  <OrderItemRow key={orderItem.id} order={order} orderItem={orderItem} showTotals={showTotals} />
                ))}
              </Column>
            </Grid.Column>
            <Grid.Column width={4}>
              <Column>
                <h3>Order #{order.rowId}</h3>
                Placed: {DateTime.fromISO(order.createdAt).toFormat('MMM d, yyyy')}
                <br />
                {order.status === 'archived' ? 'Delivered' : 'Delivery'}: {DateTime.fromISO(order.cycle.pickup).toFormat('MMM d, yyyy')}
                <br />
                Status: <span className={order.status}>{getStatus(order.status)}</span>
                <br />
                <Totals {...order} />
              </Column>
              <div style={{color: '#666', fontSize: 13, padding: 20}}>
                <p>
                  For online payments, Debit occurs after delivery once we adjust for the order that was actually delivered. For mailed
                  payments, any order adjustment will be reconciled with you after delivery as a credit (or second payment if applicable).
                </p>
                <p>
                  This order is governed by the <Link to="/terms">Terms and Conditions</Link> you have accepted as part of your Co-op
                  Membership.
                </p>
              </div>
            </Grid.Column>
          </Grid>
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Order), {
  viewer: graphql`
    fragment Order_viewer on Viewer {
      order(orderId: $orderId) {
        id
        rowId
        subtotal
        tax
        taxRate
        shipping
        shippingRate
        surcharge
        surchargeRate
        payment
        paymentType
        total
        credit
        owed
        refund
        status
        cycle {
          pickup
        }
        createdAt
        items(first: 10000, grouped: true) {
          edges {
            node {
              id
              ...OrderItemRow_orderItem
            }
          }
        }
      }
    }
  `,
});
