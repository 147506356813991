import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import OrderRow from './OrderRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// const Content = styled.div`
// 	padding: 20px;

//   background: white;
//   border: 1px solid #e5edec;
// 	border-radius: 5px;
// `;

const EmptyState = styled.div`
  width: 100%;
  padding: 20px;

  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

class Orders extends React.Component {
  render() {
    const userId = get(this.props, 'viewer.cart.id') || null;
    const orders = (get(this.props, 'viewer.user.orders.edges') || []).map((v) => v && v.node);

    return (
      <Container>
        {!orders.length && <EmptyState>You do not have any previous orders.</EmptyState>}
        {orders.map((order) => (
          <OrderRow key={order.id} order={order} userId={userId} />
        ))}
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Orders), {
  viewer: graphql`
    fragment Orders_viewer on Viewer {
      cart {
        id
      }
      user {
        orders(first: 10000, orderBy: [["cycles.deadline", "DESC"]]) {
          edges {
            node {
              id
              ...OrderRow_order
            }
          }
        }
      }
    }
  `,
});
