import {DateTime} from '@ivosabev/helpers';

function _formatTime(raw, meridiem = true, includeCST = false) {
  raw = (raw && String(raw).trim()) || null;
  if (!raw) {
    return null;
  }

  let parsed = DateTime.fromSQL(raw);

  if (meridiem) {
    parsed = parsed.toFormat('h:mma');
    // .replace('AM', 'am')
    // .replace('PM', 'pm') + (includeCST ? ' CST' : '');
  } else {
    parsed = parsed.toFormat('h:mm');
  }

  return parsed.replace(':00', '');
}

export default function formatTime(_start, _end = null, includeCST = false) {
  const start = _formatTime(_start, !_end, includeCST);
  const end = _formatTime(_end, true, includeCST);

  return start + (end ? '-' + end : '');
}
