import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddQuestionMutation($input: AddQuestionInput!) {
    addQuestion(input: $input) {
      questionEdge {
        __typename
        node {
          id
          content
        }
      }
    }
  }
`;

export const AddQuestionMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        connectionInfo: [
          {
            key: 'Product_questions',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'questionEdge',
        parentID: input.productId,
        type: 'RANGE_ADD',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['productId', 'content']),
    },
  });
};
