import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import getCartItemForProduct from '../../../helpers/getCartItemForProduct';
import ProductItem from '../../Products/ProductItem';

const Container = styled.div`
  & .heading {
    display: flex;
    justify-contnet: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 1em;
    }

    width: 100%;
    min-height: 3rem;

    & h4 {
      margin: 0;
      flex: 1;
      color: #e65100;
    }

    & a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & > div {
  }
`;

const MySplits = (props) => {
  const viewer = (props && props.viewer) || {};
  const cart = viewer.cart || {};
  const cartItems = (cart?.items?.edges || []).map(({node}) => node);

  const userId = (viewer && viewer.user && viewer.user.id) || 'guest';
  const allSplitRequests = (viewer.splitRequests && viewer.splitRequests.edges) || [];
  const membersSplitRequests = allSplitRequests.filter(({node: v}) => ((v && v.user && v.user.id) || '') !== userId);
  const mySplitRequests = allSplitRequests.filter(({node: v}) => ((v && v.user && v.user.id) || '') === userId);

  return (
    <Container>
      <div className="heading">
        <h4>Products Members Want to Split</h4>
        {membersSplitRequests.length > 6 && <Link to="/account/splits">View more ›</Link>}
      </div>
      <ProductList>
        {membersSplitRequests.length === 0 && <p>There aren't any products that members want to split at this moment.</p>}
        {membersSplitRequests.slice(0, 6).map(({node: splitRequest}) => (
          <ProductItem
            cart={cart}
            cartItem={getCartItemForProduct(cartItems, splitRequest.product.id, {
              clearanceItemId: splitRequest.clearanceItemId,
              specialItemId: splitRequest.specialItemId,
            })}
            key={splitRequest.product.id}
            product={splitRequest.product}
            splitRequest={splitRequest}
            viewer={viewer}
          />
        ))}
      </ProductList>

      <br />
      <br />

      <div className="heading">
        <h4>Products I want to Split</h4>
        {mySplitRequests.length > 6 && <Link to="/categories/clearance">View more ›</Link>}
      </div>
      <ProductList>
        {mySplitRequests.length === 0 && <p>You do not have any products that you want to split.</p>}
        {mySplitRequests.slice(0, 6).map(({node: splitRequest}) => (
          <ProductItem
            cart={cart}
            cartItem={getCartItemForProduct(cartItems, splitRequest.product.id, {
              clearanceItemId: splitRequest.clearanceItemId,
              specialItemId: splitRequest.specialItemId,
            })}
            key={splitRequest.id}
            product={splitRequest.product}
            splitRequest={splitRequest}
            viewer={viewer}
          />
        ))}
      </ProductList>
    </Container>
  );
};

export default createFragmentContainer(withRouter(MySplits), {
  viewer: graphql`
    fragment MySplits_viewer on Viewer {
      ...ProductItem_viewer
      cart {
        ...ProductItem_cart
        items(first: 10000) {
          edges {
            node {
              ...ProductItem_cartItem
              clearanceItemId
              productId
              specialItemId
            }
          }
        }
      }
      splitRequests(first: 10000) {
        edges {
          node {
            clearanceItemId
            id
            rowId
            product {
              ...ProductItem_product
              fullName
              id
              productId
            }
            specialItemId
            user {
              id
              firstName
              fullName
            }
          }
        }
      }
      user {
        id
      }
    }
  `,
});
