import {DateTime} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Sticky} from 'react-sticky';
import styled from 'styled-components';
import {withWindowSize} from '../../helpers';
import CoopBenefits from './Guest/CoopBenefits';
import CoopLocations from './Guest/CoopLocations';
import HowItWorks from './Guest/HowItWorks';
// import JoinCoop from './Guest/JoinCoop';
// import ShipHome from './Guest/ShipHome';
import StartCoop from './Guest/StartCoop';
import DeliveryStatus from './User/DeliveryStatus';
import MySplits from './User/MySplits';
import OrderDates from './User/OrderDates';
import PayMyBill from './User/PayMyBill';
import Reorder from './User/Reorder';
import WhatsOnSale from './User/WhatsOnSale';

const pallete = ['#e65100', '#eb5f00', '#f06d00', '#f57c00', '#fa8a00', '#ff9800'];

const Container = styled.div`
  flex: 1;
`;

const Menu = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
`;

const MenuLeft = styled.div`
  flex: 1;
  background: ${pallete[0]};
`;

const MenuInner = styled.div`
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  width: 1360px;
  background: #ff9800;
`;

const MenuRight = styled.div`
  flex: 1;
  background: ${pallete[pallete.length - 1]};
`;

const MenuItem = styled.a`
  display: flex;
  height: 100%;
  flex: 1;

  flex-direction: column;
  justify-content: center;

  text-align: center;
  vertical-align: middle;
  line-height: 100%;
  font-weight: bold;
  font-size: 1.1rem;
  cursor: pointer;

  color: #fff;
  background: ${(p) => p.color};

  &:hover,
  &.active {
    color: ${(p) => p.color};
    background: #fff;
  }

  & span {
    line-height: 1.33em;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Content = styled.div`
  width: 100%;
  // background: #eee;
`;

const ContentInner = styled.div`
  margin: 0 auto;
  max-width: 1360px;
  background: #fff;
`;

const ContentItem = styled.div`
  display: none;
  flex-direction: column;

  padding: 3rem 4rem 6rem 4rem;

  @media (max-width: 640px) {
    padding: 3rem 1.5rem 6rem 1.5rem;
  }

  &.active {
    display: flex;
  }

  & h2,
  & h3,
  & h4,
  & h5 {
    color: #f57c00;
  }
`;

//

const guestTabs = [
  {Component: HowItWorks, hash: 'how-the-co-op-works', title: 'How the Co-op Works'},
  {Component: CoopBenefits, hash: 'co-op-benefits', title: 'Co-op Benefits'},
  {Component: CoopLocations, hash: 'join-a-co-op', title: 'Join a Co-op'},
  {Component: StartCoop, hash: 'start-a-co-op', title: 'Start a Co-op'},

  // {hash: 'how-the-co-op-works', title: 'How the Co-op Works', Component: HowItWorks},
  // {hash: 'co-op-benefits', title: 'Co-op Benefits', Component: CoopBenefits},
  // {hash: 'co-op-communities', title: 'Co-op Communities', Component: CoopLocations},
  // {hash: 'join-a-co-op', title: 'Join a Co-op', Component: JoinCoop},
  // {hash: 'start-a-co-op', title: 'Start a Co-op', Component: StartCoop},
  // {hash: 'ship-to-your-home', title: 'Ship to Your Home', Component: ShipHome},
];

const userTabs = [
  {Component: OrderDates, hash: 'order-dates', title: 'Order Dates'},
  {Component: DeliveryStatus, hash: 'delivery-status', title: 'Delivery Status'},
  {Component: WhatsOnSale, hash: 'whats-on-sale', title: `What's on Sale`},
  {Component: Reorder, hash: 'reorder', title: 'Reorder'},
  {Component: MySplits, hash: 'my-splits-products', title: 'My Split Products'},
  {Component: PayMyBill, hash: 'pay-my-bill', title: 'Pay My Bill'},
];

class Tabs extends React.Component {
  state = {
    hashes: [],
    selectedTabIndex: 0,
    tabs: [],
  };

  constructor(props) {
    super(props);

    const isGuest = ((props && props.viewer && props.viewer.user && props.viewer.user.role) || 'guest') === 'guest';

    const tabs = isGuest ? guestTabs : userTabs;
    const hashes = (isGuest ? guestTabs : userTabs).map((v) => v.hash);
    let hash = (window.location.hash && window.location.hash.substr(1)) || '';

    // HACK: Hack for /specials
    if (!isGuest && window.location.pathname === '/specials') {
      hash = 'whats-on-sale';
    }

    const hashIndex = hashes.indexOf(hash);

    let selectedTabIndex = 0;
    if (hashIndex > -1) {
      selectedTabIndex = hashIndex;
    } else {
      let deadline = get(props.viewer, 'coop.currentCycle.deadline') || null;
      let pickup = get(props.viewer, 'coop.currentCycle.pickup') || null;
      if (deadline && pickup) {
        const from = DateTime.fromISO(deadline).plus({days: 3}).toISODate();
        const to = pickup;
        const today = DateTime.local().toISODate();

        if (today >= from && today <= to) {
          selectedTabIndex = 1;
        }
      }
    }

    this.state = {...this.state, hashes, selectedTabIndex, tabs};
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.handleHashChange, false);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.handleHashChange, false);
  }

  handleHashChange = (e) => {
    const hash = (String((e && e.newURL) || '').split('#') || [])[1] || null;
    const selectedTabIndex = (hash && this.state.hashes.indexOf(hash)) || -1;
    if (selectedTabIndex >= 0) {
      this.setState({selectedTabIndex});
    }
  };

  handleClick = (tabIndex) => (e) => {
    const hero = document.getElementById('hero');
    const announcement = document.getElementById('announcement');
    const y = (hero ? hero.getBoundingClientRect().height : 0) + (announcement ? announcement.getBoundingClientRect().height : 0);
    window.scrollTo(0, y);

    window.location.hash = this.state.hashes[tabIndex] || '';
    this.setState({selectedTabIndex: tabIndex});
  };

  render() {
    const {selectedTabIndex} = this.state;
    const {viewer} = this.props;

    return (
      <Container>
        <Sticky relative={false} topOffset={270}>
          {({style}) => (
            <Menu style={{...style, top: this.props.isMobile ? 53 : 116, zIndex: 1000}}>
              <MenuLeft />
              <MenuInner>
                {this.state.tabs.map((tab, i) => (
                  <MenuItem
                    className={selectedTabIndex === i ? 'active' : ''}
                    color={pallete[i]}
                    key={tab.hash}
                    onClick={this.handleClick(i)}>
                    <span>{tab.title}</span>
                  </MenuItem>
                ))}
              </MenuInner>
              <MenuRight />
            </Menu>
          )}
        </Sticky>
        <Content>
          <ContentInner>
            {this.state.tabs.map((tab, i) => (
              <ContentItem className={selectedTabIndex === i ? 'active' : ''} key={tab.hash}>
                <tab.Component viewer={viewer} />
              </ContentItem>
            ))}
          </ContentInner>
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(withWindowSize(Tabs)), {
  viewer: graphql`
    fragment Tabs_viewer on Viewer {
      ...DeliveryStatus_viewer
      ...MySplits_viewer
      ...OrderDates_viewer
      ...PayMyBill_viewer
      ...Reorder_viewer
      ...WhatsOnSale_viewer
      coop {
        currentCycle {
          deadline
          pickup
        }
      }
      user {
        role
      }
    }
  `,
});
