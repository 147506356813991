import {Grid} from 'semantic-ui-react';
import styled from 'styled-components';

const Content = styled.div`
  margin: 20px auto;
  max-width: 1000px;
`;

const Column = styled(Grid.Column)`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Section = styled.div`
  padding: 0 0 ${(props) => (props.last ? 0 : 1)}em 0;
`;

const Thanks = () => {
  return (
    <Content>
      <Grid stackable>
        <Grid.Column width={16}>
          <Column>
            <Inner>
              <Section>
                <h1>Thank you for your payment!</h1>
                <p>
                  Please <a href="/contact">contact us</a> if you have any additional questions or concerns.
                </p>
              </Section>
            </Inner>
          </Column>
        </Grid.Column>
      </Grid>
    </Content>
  );
};

export default Thanks;
