import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddCartItemMutation($input: AddCartItemInput!) {
    addCartItem(input: $input) {
      cart {
        id
        qty
        subtotal
        shipping
        shippingRate
        tax
        taxRate
        total
        credit
        owed
        items(first: 10000) {
          edges {
            node {
              clearanceItem {
                id
                qty
              }
              clearanceItemId
              id
              total
              qty
              specialItemId
              product {
                id
                category {
                  slug
                }
                brand {
                  name
                }
                name
                unit
                cost
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
              }
              productId
            }
          }
        }
      }
    }
  }
`;

export const AddCartItemMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['clearanceItemId', 'orderId', 'productId', 'qty', 'specialItemId', 'userId']),
    },
  });
};
