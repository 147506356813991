import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddSubstitutionMutation($input: AddSubstitutionInput!) {
    addSubstitution(input: $input) {
      substitution {
        id
        rowId
        productId
        product {
          id
          productId
          fullName
          package
        }
        substitutionId
        substitution {
          id
          productId
          fullName
          package
        }
      }
    }
  }
`;

export const AddSubstitutionMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    updater: (store) => {
      const viewerRecord = store.get(input.viewerId);

      const mutationPayload = store.getRootField('addSubstitution');
      const newSubstitition = mutationPayload.getLinkedRecord('substitution');

      // Add to Cart
      const cart = viewerRecord.getLinkedRecord('cart');
      const itemsConnectionEdges = store.get(`client:${cart.getDataID()}:items(first:10000)`).getLinkedRecords('edges');
      const itemEdge = itemsConnectionEdges.find(
        (itemEdge) => itemEdge.getLinkedRecord('node').getLinkedRecord('product').getValue('productId') === input.productId,
      );

      const itemNode = itemEdge.getLinkedRecord('node');
      const substitutions = itemNode.getLinkedRecords('substitutions') || [];
      itemNode.setLinkedRecords([...substitutions, newSubstitition], 'substitutions');

      // Add to User
      const user = viewerRecord.getLinkedRecord('user');
      if (user) {
        const userSubstitutions = user.getLinkedRecords('substitutions') || [];
        user.setLinkedRecords([...userSubstitutions, newSubstitition], 'substitutions');
      }
    },
    variables: {
      input: pick(input, ['productId', 'substitutionId']),
    },
  });
};
