/**
 * @generated SignedSource<<6ed0b023a59fa80a145efda5f4cfb509>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDates_viewer$data = {
  readonly coop: {
    readonly currentCycle: {
      readonly deadline: any | null | undefined;
      readonly freeShippingGoal: number | null | undefined;
      readonly freeShippingValue: number | null | undefined;
      readonly pickup: any | null | undefined;
      readonly pickupEnd: string | null | undefined;
      readonly pickupStart: string | null | undefined;
      readonly pickupStreet: string | null | undefined;
      readonly pickupZip: string | null | undefined;
    } | null | undefined;
    readonly nextCycles: ReadonlyArray<{
      readonly deadline: any | null | undefined;
      readonly id: string;
      readonly pickup: any | null | undefined;
    } | null | undefined> | null | undefined;
    readonly timezone: string | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly role: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "OrderDates_viewer";
};
export type OrderDates_viewer$key = {
  readonly " $data"?: OrderDates_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderDates_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadline",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickup",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderDates_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Coop",
      "kind": "LinkedField",
      "name": "coop",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timezone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Cycle",
          "kind": "LinkedField",
          "name": "currentCycle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "freeShippingGoal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "freeShippingValue",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pickupStart",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pickupEnd",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pickupStreet",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pickupZip",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Cycle",
          "kind": "LinkedField",
          "name": "nextCycles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "510d651a057d5a77cd9e872dfbe16b47";

export default node;
