import {graphql} from 'babel-plugin-relay/macro';

export default graphql`
  query AppQuery_Query($filterBy: ProductFilterInput, $orderBy: [[String]], $page: Int, $limit: Int) {
    store {
      ...App_store
    }
    viewer {
      ...App_viewer
      ...CartSubstitutions_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy, page: $page, limit: $limit)
      ...SearchBar_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy, page: $page, limit: $limit)
    }
  }
`;
