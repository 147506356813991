import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import getCartItemForProduct from '../../../helpers/getCartItemForProduct';
import ProductItem from '../../Products/ProductItem';

const Container = styled.div`
  & .heading {
    display: flex;
    justify-contnet: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 1em;
    }

    width: 100%;
    min-height: 3rem;

    & h4 {
      margin: 0;
      flex: 1;
      color: #e65100;
    }

    & a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const ProductList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  & > div {
  }
`;

const Reorder = (props) => {
  const viewer = (props && props.viewer) || {};
  const cart = viewer.cart || {};
  const cartItems = (cart?.items?.edges || []).map(({node}) => node);
  const products = (viewer.products && viewer.products.edges) || [];

  return (
    <Container>
      <div className="heading">
        <h4>My Most Ordered Items</h4>
        <Link to="/account/orders">Show my full Order History ›</Link>
      </div>
      <ProductList>
        {products.length === 0 && <p>We don't have any products on Special at the moment, please check later.</p>}
        {products.slice(0, products.length === 12 ? 12 : 6).map(({node: product}) => (
          <ProductItem
            cart={cart}
            cartItem={getCartItemForProduct(cartItems, product.id)}
            key={product.id}
            product={product}
            viewer={viewer}
          />
        ))}
      </ProductList>
    </Container>
  );
};

export default createFragmentContainer(withRouter(Reorder), {
  viewer: graphql`
    fragment Reorder_viewer on Viewer {
      ...ProductItem_viewer
      cart {
        ...ProductItem_cart
        items(first: 10000) {
          edges {
            node {
              ...ProductItem_cartItem
              clearanceItemId
              productId
              specialItemId
            }
          }
        }
      }
      products(limit: 12, offset: 0, filterBy: {slug: "reorder", isActive: true, isAvailable: true}, orderBy: [["rank", "ASC"]]) {
        edges {
          node {
            ...ProductItem_product
            cost
            fullName
            id
            rank
            unit
          }
        }
      }
    }
  `,
});
