import {money} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import omit from 'lodash/omit';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Button, Checkbox, Dropdown, Form, Grid, Message, Modal, Popup} from 'semantic-ui-react';
import styled from 'styled-components';
import {withWindowSize} from '../../helpers';
import {calcTotals} from '../../helpers/calcTotals';
import {validate} from '../../helpers/validation';
import {PayMutation} from '../../mutations/PayMutation';
import DeliveryFeesNote from '../Common/DeliveryFeesNote';
import Totals from '../Common/Totals';
import Address from './Address';

// import CheckoutThanks from './CheckoutThanks';

const getAddressValues = (suffix, data) => ({
  city: data[`${suffix}City`],
  firstName: data[`${suffix}FirstName`],
  lastName: data[`${suffix}LastName`],
  state: data[`${suffix}State`],
  street: data[`${suffix}Street`],
  zip: data[`${suffix}Zip`],
});

const LinkButton = styled.a.attrs({
  href: '',
  role: 'button',
})`
  cursor: pointer;
`;

const Title = styled.h1`
  font-weight: normal;
  font-size: 21px;
`;

const Section = styled.div`
  padding: 0 0 ${(props) => (props.last ? 0 : 1)}em 0;

  & .radio label {
    line-height: 1.25em;
  }
`;

const Notes = styled.div`
  margin-top: -5px;
  color: #aaa;
  font-size: 14px;
`;

const PaymentType = styled(({children, icon, ...rest}) => <label {...rest}>{children}</label>)`
  margin: 8px 0 3px 0;
  padding: 5px 0;
  background-image: url('${(props) => props.icon}');
  background-repeat: no-repeat;
  background-position: 20px 0px;
  font-size: 17px;
  & input {
    margin-right: 42px;
  }
  cursor: pointer;
`;

const WarningSection = styled(Section)`
  padding: 12px;
  background: rgb(255 251 235);
  border: 1px solid ${(props) => (props.error ? '#9f3a38' : 'rgb(253 230 138)')};

  & > .ui.checkbox label {
    color: ${(props) => (props.error ? '#9f3a38' : 'black')} !important;
    line-height: 1.25;
  }
`;

// const Button2 = styled.button`
// 	outline: none;

// 	display: inline-block;

//  font-family: 'PT Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
// 	font-weight: 600;
// 	text-align: center;
// 	vertical-align: middle;
// 	touch-action: manipulation;
// 	cursor: pointer;
// 	border: 1px solid transparent;
// 	white-space: nowrap;
// 	-webkit-font-smoothing: antialiased;

// 	line-height: 1.42857;
// 	border-radius: 4px;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;

// 	box-shadow: none;
// 	color: #2196F3;
// 	background: #fff;
// 	border: 2px solid #2196F3;

// 	font-size: 1.3rem;
// 	font-size: 18px;
// 	font-weight: 500;
// 	line-height: 22px;

// 	position: relative;
// 	padding: 6px 14px 6px 12px;
// 	// width: 95px;
// 	// height: 36px;
// 	vertical-align: inherit;

// 	& > .icon {
// 		margin-right: 0.5rem;
// 		font-size: 20px;
// 	}
// `;

//

// const Container = styled.div`
//   min-height: 100vh;
//   background: #f7f7f7;
// `;

// const Header = styled.div`
// 	position: relative;
// 	z-index: 1986;
// 	display: flex;
// 	align-items: center;
// 	width: 100%;
// 	height: 60px;
// 	box-shadow: 0px 1px 5px rgba(0,0,0,0.16);
//   background: #fff;
// `;

// const Logo = styled.a`
// 	margin: 0 auto;
// 	width: 250px;
// 	height: 60px;
// 	background-image: url('/next/img/logo.gif');
// 	background-repeat: no-repeat;
// 	background-size: 100%;
// 	background-position: center 7px;
// `;

const Content = styled.div`
  margin: 20px auto;
  max-width: 1000px;
`;

const Column = styled(Grid.Column)`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const PlaceOrderButton = styled(Button)`
  padding: 10px 10px !important;
  width: 100%;
  // font-size: 19px !important;
  font-size: 19px !important;
  font-weight: normal !important;
  color: white;
  background: #43b02a;
  border: 0;
`;

//
// const Payments = styled.div`
//   padding: 20px;
//   background: #fff;
//   border: 1px solid #e5edec;
//   border-radius: 5px;
// `;

const Note = styled.div`
  padding: 20px;
  color: #999;
  background-color: #fafafa;
  border-top: 1px solid #e5edec;
  font-size: 14px;
`;

// const Kor = styled.div`
//   padding: 20px;
//   background: white;
//   border: 1px solid #e5edec;
//   border-radius: 5px;

//   & h1 {
//     font-size: 23px;
//     font-weight: normal;
//   }
// `;

// const PMButto2 = styled.div`
//   padding: 20px 10px 20px 60px;
//   border: 1px solid #e5edec;
//   border-radius: 5px;
//   color: ${props => props.selected ? '#fff' : 'auto'};
//   background-color: ${props => props.selected ? '#21BA45' : 'transparent'};
//   background-image: url('${props => props.icon}');
//   background-repeat: no-repeat;
//   background-position: 15px center;
//   font-size: 19px;
//   &:hover {
//     background-color: #ccc;
//   }
// `;

// const PMButton = styled(Button)`
//   background-color: ${props => props.selected ? '#21BA45' : 'transparent'};
//   background-image: url('${props => props.icon}');
//   background-repeat: no-repeat;
//   background-position: 15px center;
// `;

//
const isEmptyString = (v) => String(v || '').trim() === '';
const isECheckPayment = (v) => v === PAYMENT_TYPE_E_CHECK;
const isCardPayment = (v) => v === PAYMENT_TYPE_CARD;
const isCheckPayment = (v) => v === PAYMENT_TYPE_CHECK;
const isOrderUpdate = (s) => s.isCheckout && Number(s.orderId);
const isValidCheckNumber = (v) => {
  if (v.length !== 9) {
    return false;
  }

  let n = 0;
  for (let i = 0; i < v.length; i += 3) {
    n += parseInt(v[i], 10) * 3 + parseInt(v[i + 1], 10) * 7 + parseInt(v[i + 2], 10);
  }

  return n !== 0 && n % 10 === 0;
};

const validationRules = {
  amount: [
    [(v, s) => !isCheckPayment(s.paymentType) && !s.isCheckout && s.orderId === 0 && Number(v) <= 0, 'Amount should be greater than $0.'],
  ],
  cardAgreement: [
    [
      (v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v),
      'You must confirm you understand the card payment conditions.',
    ],
  ],
  cardCity: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'City is required.']],
  cardCode: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Card code is required.']],
  cardExpiration: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Card expiration is required.']],
  cardFirstName: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'First name is required.']],
  cardLastName: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Last name is required.']],
  cardName: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Card name is required.']],
  cardNumber: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Card number is required.']],
  cardState: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'State is required.']],

  cardStreet: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Street is required.']],
  cardZip: [[(v, s) => !isOrderUpdate(s) && isCardPayment(s.paymentType) && isEmptyString(v), 'Zip is required.']],
  checkAccount: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'Account number is required.']],
  checkCity: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'City is required.']],
  checkDonationNumber: [[(v, s) => s.checkDonation && isEmptyString(v), 'Donation check number is required.']],
  checkFirstName: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'First name is required.']],
  checkLastName: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'Last name is required.']],
  checkNumber: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'Check number is required.']],
  checkRouting: [
    [(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'Routing number is required.'],
    [(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && !isValidCheckNumber(v), 'Routing number is invalid.'],
  ],
  checkState: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'State is required.']],

  checkStreet: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'Street is required.']],
  checkZip: [[(v, s) => !isOrderUpdate(s) && isECheckPayment(s.paymentType) && isEmptyString(v), 'Zip is required.']],

  note: [
    [
      (v, s) => !isCheckPayment(s.paymentType) && !s.isCheckout && s.orderId === 0 && isEmptyString(v),
      'Provide a reason for your payment.',
    ],
  ],
};

const getFieldsToBeValidated = (state) => {
  if (state.paymentType === PAYMENT_TYPE_CHECK) {
    return [];
  }

  let fieldsToBeValidated = [];
  if (state.paymentType === PAYMENT_TYPE_CARD) {
    fieldsToBeValidated = [
      'cardAgreement',
      'cardNumber',
      'cardName',
      'cardExpiration',
      'cardCode',
      'cardFirstName',
      'cardLastName',
      'cardStreet',
      'cardCity',
      'cardState',
      'cardZip',
    ];
  } else if (state.paymentType === PAYMENT_TYPE_E_CHECK) {
    fieldsToBeValidated = [
      'checkNumber',
      'checkAccount',
      'checkRouting',
      'checkFirstName',
      'checkLastName',
      'checkStreet',
      'checkCity',
      'checkState',
      'checkZip',
    ];
  }

  if (state.orderId === 0) {
    fieldsToBeValidated.push('amount');
    fieldsToBeValidated.push('note');
  }

  // checkDonationNumber: {value: '', errors: []},

  return fieldsToBeValidated;
};

const isValid = (state) => {
  const fieldsToBeValidated = getFieldsToBeValidated(state);
  return (
    Object.keys(state)
      .filter((v) => fieldsToBeValidated.indexOf(v) > -1)
      .findIndex((k) => ((state[k] && state[k].errors) || []).length) === -1
  );
};

const getErrorMessages = (state) => {
  const fieldsToBeValidated = getFieldsToBeValidated(state);
  return Object.keys(state)
    .filter((v) => fieldsToBeValidated.indexOf(v) > -1)
    .reduce((p, c) => [...p, ...((state[c] && state[c].errors) || [])], []);
};

//

function formatCardExpiration(s) {
  return s
    .replace(/^([1-9]\/|[2-9])$/g, '0$1/' /* 3 > 03 */)
    .replace(/^(0[1-9]|1[0-2])$/g, '$1/' /* 11 > 11 */)
    .replace(/^([0-1])([3-9])$/g, '0$1/$2' /* 13 > 01/3 */)
    .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2' /* 141 > 01/41 */)
    .replace(/^([0]+)\/|[0]+$/g, '0' /* 0/ > 0 and 00 > 0 */)
    .replace(/[^\d/]|^[/]*$/g, '' /* To allow only digits and `/` */)
    .replace(/\/\//g, '/' /* Prevent entering more than 1 `/` */);
}

//

const PAYMENT_TYPE_E_CHECK = 'ec';
const PAYMENT_TYPE_CARD = 'cc';
const PAYMENT_TYPE_CHECK = 'pc';

class Checkout extends React.Component {
  state = {
    amount: {errors: [], value: ''},

    cardAgreement: {errors: [], value: false},
    cardCity: {errors: [], value: ''},
    cardCode: {errors: [], value: ''},
    cardDonation: 0,
    cardDonationId: '',
    cardExpiration: {errors: [], value: ''},
    cardFirstName: {errors: [], value: ''},
    cardLastName: {errors: [], value: ''},
    cardName: {errors: [], value: ''},
    cardNumber: {errors: [], value: ''},
    cardRemember: false,
    cardState: {errors: [], value: ''},
    cardStreet: {errors: [], value: ''},
    cardZip: {errors: [], value: ''},

    checkAccount: {errors: [], value: ''},
    checkCity: {errors: [], value: ''},
    checkDonation: 0,
    checkDonationId: '',
    checkDonationNumber: {errors: [], value: ''},
    checkFirstName: {errors: [], value: ''},
    checkLastName: {errors: [], value: ''},
    checkNumber: {errors: [], value: ''},
    checkRemember: false,
    checkRouting: {errors: [], value: ''},
    checkState: {errors: [], value: ''},
    checkStreet: {errors: [], value: ''},
    checkZip: {errors: [], value: ''},

    form: {errors: []},
    isCheckout: false,
    isDonationsModalOpen: false,

    isSubmitting: false,
    note: {errors: [], value: ''},
    orderId: 0,
    paymentType: PAYMENT_TYPE_E_CHECK,
  };

  constructor(props) {
    super(props);

    const {viewer} = props;
    const {user} = viewer;
    const {address, cardInfo, checkInfo, firstName, lastName} = user;
    const {city, state, street, zip} = address;

    const checkRouting = (checkInfo && checkInfo.routing) || '';
    const checkAccount = (checkInfo && checkInfo.account) || '';

    const cardName = get(cardInfo, 'name', '');
    const cardNumber = get(cardInfo, 'number', '');
    const cardExpiration = get(cardInfo, 'expiration', '');

    const isCartNotEmpty = (get(viewer, 'cart.total') || 0) > 0;
    const isCheckout = window.location.href.indexOf('/checkout') > -1;
    const isBanned = get(viewer.user, 'isBanned') || false;
    const owed = Number(get(this.props.viewer, 'user.owed') || 0);

    this.canPayWithCredit = isCartNotEmpty && isCheckout && viewer.cart.owed === 0;

    let orderId = 0;
    if (isBanned && owed > 0) {
      orderId = 'owed';
      this.state.amount = {
        errors: [],
        value: String(get(this.props.viewer, 'user.owed') || 0),
      };

      this.state.note = {
        errors: [],
        value: get(this.props.viewer, 'user.owedReason'),
      };

      this.amount = owed;
      this.isCheckout = false;
    } else if (isCheckout) {
      orderId = user?.checkoutOrder?.rowId ?? 0;
    } else {
      orderId = get(this.props, 'match.params.orderId') ?? 0;
    }

    if (isCheckout && !isCartNotEmpty) {
      return (window.location.href = '/');
    }

    this.state = {
      ...this.state,
      cardCity: {errors: [], value: city},
      cardCode: {errors: [], value: ''},
      cardExpiration: {errors: [], value: cardExpiration},
      cardFirstName: {errors: [], value: firstName},
      cardLastName: {errors: [], value: lastName},
      cardName: {errors: [], value: cardName},
      cardNumber: {errors: [], value: cardNumber},
      cardRemember: cardNumber !== '',
      cardState: {errors: [], value: state},
      cardStreet: {errors: [], value: street},
      cardZip: {errors: [], value: zip},
      checkAccount: {errors: [], value: checkAccount},
      checkCity: {errors: [], value: city},
      checkFirstName: {errors: [], value: firstName},
      checkLastName: {errors: [], value: lastName},
      checkRemember: checkAccount !== '',
      checkRouting: {errors: [], value: checkRouting},
      checkState: {errors: [], value: state},
      checkStreet: {errors: [], value: street},
      checkZip: {errors: [], value: zip},
      isCheckout,
      orderId,
      paymentType: isBanned ? PAYMENT_TYPE_CARD : this.state.paymentType,
    };
  }

  getOrderOptions = () => {
    const {cart, orders} = this.props.viewer;
    const {isCheckout} = this.state;

    const orderOptions = [];

    if (!isCheckout) {
      const isBanned = get(this.props.viewer.user, 'isBanned') || false;
      const owed = Number(get(this.props.viewer, 'user.owed') || 0);
      if (owed) {
        orderOptions.push({text: `Past Due ${money(owed)}`, value: 'owed'});
      }

      if (!isBanned) {
        orderOptions.push({
          text: 'Without an Order',
          value: '0',
        });

        if (cart && cart.total > 0) {
          orderOptions.push({
            text: 'Cart',
            value: 'cart',
          });
        }
      }

      (orders.edges || []).forEach(({node: {rowId}}) => orderOptions.push({text: 'Order ' + rowId, value: String(rowId)}));

      orderOptions.sort((a, b) => a.text.localeCompare(b.text));
      orderOptions.reverse();
    }

    return orderOptions;
  };

  getTotals = () => {
    const {viewer} = this.props;
    const {isCheckout, orderId, paymentType} = this.state;
    let orderSubtotal = 0;
    let cartSubtotal = 0;
    let subtotal = 0;
    let shippingRate = 0;
    let shipping = 0;
    let taxRate = 0;
    let tax = 0;
    let total = 0;
    let credit = 0;
    let payment = 0;
    let paymentType2 = null;
    let owed = 0;
    let surchargeRate = viewer.coop.surchargeRate;
    let surcharge = 0;

    // TODO: Use calcTotals from the server eventually?
    if (isCheckout) {
      const {cart} = viewer;
      const order = viewer?.user?.checkoutOrder || {};
      const userCredit = Number(viewer.user.credit) || 0;

      shippingRate = cart.shippingRate;
      taxRate = cart.taxRate;

      cartSubtotal = cart.subtotal;
      orderSubtotal = order.subtotal || 0;

      const totals = calcTotals({
        availableCredit: userCredit + (order.credit || 0),
        discount: 0,
        payment: order.payment || 0,
        paymentType: order.paymentType,
        refund: order.refund,
        shippingRate,
        subtotal: cartSubtotal + orderSubtotal,
        taxRate,
      });

      subtotal = totals.order.subtotal;
      shipping = totals.order.shipping;
      tax = totals.order.tax;
      credit = totals.order.credit;
      payment = totals.order.payment;
      paymentType2 = order.paymentType || null;
      total = totals.order.total;
      owed = totals.owed;
    } else if (orderId > 0) {
      const orders = (viewer.orders.edges || []).map((v) => v.node);
      const order = orders.find((order) => Number(order.rowId) === Number(orderId));

      subtotal = order.subtotal;
      shippingRate = order.shippingRate;
      shipping = order.shipping;
      taxRate = order.taxRate;
      tax = order.tax;
      credit = order.credit;
      payment = order.payment;
      total = order.total;
      owed = order.owed;
    }

    taxRate = taxRate > 0 ? taxRate : 0;
    surchargeRate = paymentType === PAYMENT_TYPE_CARD ? surchargeRate : 0;
    surcharge = owed * surchargeRate;
    owed = owed + surcharge;

    return {
      cart: cartSubtotal,
      credit,
      order: orderSubtotal,
      owed,
      payment,
      paymentType: paymentType2,
      shipping,
      shippingRate,
      subtotal,
      surcharge,
      surchargeRate,
      tax,
      taxRate,
      total,
    };
  };

  handleOrderSelection = (e, {value}) => {
    e.stopPropagation();

    const isCheckout = this.state.isCheckout || value === 'cart';

    if (value === 'owed') {
      const amount = {
        errors: [],
        value: String(get(this.props.viewer, 'user.owed') || 0),
      };

      const note = {
        errors: [],
        value: get(this.props.viewer, 'user.owedReason'),
      };

      this.setState({
        amount,
        isCheckout,
        note,
        orderId: value,
      });
    } else {
      if (isCheckout) {
        const orderId = value === 'cart' ? 'cart' : Number(value);
        const order = this.props.viewer?.user?.checkoutOrder || {};
        const paymentType = order.paymentType || PAYMENT_TYPE_E_CHECK;

        // Reset validation errors
        const newState = Object.keys(this.state).reduce((result, key) => {
          if (this.state[key].errors) {
            return {...result, [key]: {...this.state[key], errors: []}};
          } else {
            return result;
          }
        }, {});

        this.setState({
          ...newState,
          amount: {errors: [], value: ''},
          isCheckout,
          note: {errors: [], value: ''},
          orderId,
          payment: order.payment || 0,
          paymentType,
        });
      } else {
        this.setState({isCheckout, orderId: value, paymentType: PAYMENT_TYPE_E_CHECK});
      }
    }
  };

  handleSectionClick = (section) => (e) => {
    const isOpen = this.state.sections[section];
    if (!isOpen) {
      this.setState({sections: this.state.sections.map((v, i) => (i === section ? true : false))});
    }
  };

  handleSubmit = async (e, {formData}) => {
    e.preventDefault();

    window.scrollTo(0, 0);

    // Prevent double clicks
    if (this.state.isSubmitting) {
      return false;
    }

    // Validation
    const newState = this.canPayWithCredit ? this.state : validate(this.state, validationRules);
    delete newState.form;
    if (!isValid(newState)) {
      this.setState({...newState});
      return;
    }

    //
    this.setState({isSubmitting: true});

    // Object.keys(
    //   omit(this.state, [
    //     'form',
    //     'isSubmitting',
    //     'checkDonation',
    //     'cardDonation',
    //     'cardDonationId',
    //     'isDonationsModalOpen',
    //     'cardAgreement',
    //   ]),
    // ).forEach((v) => console.log(v, this.state[v]));

    const data = Object.keys(
      omit(this.state, [
        'form',
        'isSubmitting',
        'checkDonation',
        'cardDonation',
        'cardDonationId',
        'isDonationsModalOpen',
        'cardAgreement',
      ]),
    ).reduce((p, c) => ({...p, [c]: typeof this.state[c].value !== 'undefined' ? this.state[c].value : this.state[c]}), {});

    data.isCheckout = this.state.isCheckout;
    data.orderId = String(data.orderId);

    const payload = {input: data};

    const onSuccess = (response) => {
      let orderId = Number(response.pay.order && response.pay.order.rowId) || 0;
      const payment = typeof response.pay.payment !== 'undefined' ? response.pay.payment : null;
      const errors = [...(response.pay.errors || [])];

      if (payment !== null) {
        if (orderId) {
          return this.props.router.push(`/orders/${orderId}`);
        } else {
          return this.props.router.push(`/pay/thanks`);
        }
      } else if (orderId) {
        errors.push({
          field: null,
          message:
            'Your Order has been placed succesfully, but your payment could not be processed. ' +
            'Please submit a payment for Order ' +
            orderId,
          payload: null,
        });
      } else {
        orderId = data.orderId;
      }

      this.setState((prevState) => {
        prevState.form.errors = [];
        return {
          ...errors.reduce((p, c) => {
            const field = c.field || 'form';
            const errors = [...(p[field].errors || []), c.message];
            return {...p, [field]: {...p[field], errors}};
          }, prevState),
          isSubmitting: false,
          orderId,
        };
      });
    };

    const onFailure = () => {
      this.setState({isSubmitting: false});
    };

    PayMutation(payload, onSuccess, onFailure);
  };

  handlePaymentTypeSelection = (paymentType) => (e) => {
    this.setState((prevState) => ({
      form: {errors: []},
      paymentType,
    }));
  };

  handleFieldChange = (e, {checked, name, type, value, ...rest}) => {
    if (type === 'checkbox') {
      value = checked;
    }

    if (typeof value === String) {
      value = value.trim();
    }

    if (name === 'cardExpiration') {
      value = value.replace(/[()_\-a-zA-Z]/g, '').trim();
      value = value ? formatCardExpiration(value) : '';
    }

    this.setState((prevState) => ({[name]: validate({...prevState, [name]: {value}}, validationRules, name)}));
  };

  handleSimpleFieldChange = (e, {checked, name, type, value, ...rest}) => {
    if (type === 'checkbox') {
      value = checked;
    }

    if (typeof value === String) {
      value = value.trim();
    }

    if (name === 'cardExpiration') {
      value = value.replace(/[()_\-a-zA-Z]/g, '').trim();
      value = value ? formatCardExpiration(value) : '';
    }

    this.setState((prevState) => ({[name]: value}));
  };

  handleAddressChange =
    (paymentType) =>
    (e, {name: _name, value}) => {
      const suffix = paymentType === PAYMENT_TYPE_CARD ? 'card' : 'check';
      const name = `${suffix}${_name.substr(0, 1).toUpperCase()}${_name.substr(1)}`;
      this.setState((prevState) => ({[name]: validate({...prevState, [name]: {value}}, validationRules, name)}));
    };

  handleDonationsModalOpen = (e) => {
    e.preventDefault();
    this.setState({isDonationsModalOpen: true});
  };

  handleDonationsModalClose = () => this.setState({isDonationsModalOpen: false});

  renderPaymentInformation = () => {
    const {isCheckout, orderId, paymentType} = this.state;
    const surchargeRate = this.props.viewer.coop.surchargeRate;

    let amount;
    let note;

    if ((!isCheckout && Number(orderId) === 0) || orderId === 'owed') {
      amount = this.state.amount;
      note = this.state.note;
    } else {
      return null;
    }

    return (
      <Section>
        <Title>Payment Information</Title>
        <Form.Group widths="equal">
          <Form.Input
            error={amount.errors.length > 0}
            label="Amount"
            name="amount"
            onChange={this.handleFieldChange}
            placeholder="Amount"
            size="small"
            value={amount.value}
            width={4}
          />
          <Form.Input
            error={note.errors.length > 0}
            label="Please write in what you are paying for"
            name="note"
            onChange={this.handleFieldChange}
            placeholder="Please write in what you are paying for"
            size="small"
            value={note.value}
            width={12}
          />
        </Form.Group>
        {paymentType === PAYMENT_TYPE_CARD && (
          <Notes>
            Please note that there is be a <strong>{surchargeRate * 100}% surcharge</strong> on card payments
          </Notes>
        )}
      </Section>
    );
  };

  renderCheck = () => {
    const {checkAccount, checkDonation, checkDonationId, checkDonationNumber, checkNumber, checkRemember, checkRouting} = this.state;

    const donationItems = (get(this, 'props.viewer.donationItems.edges') || []).map((v) => v.node);
    const options = donationItems
      .sort((a, b) => b.amount - a.amount)
      .map((donationItem) => ({
        text: `${money(donationItem.amount)} - ${donationItem.name}`,
        value: String(donationItem.rowId),
      }));

    return (
      <div>
        {this.renderPaymentInformation()}
        <Section>
          <Title>Check Information</Title>
          <Form.Group widths="equal">
            <Form.Input
              error={checkNumber.errors.length > 0}
              label="Check #"
              name="checkNumber"
              onChange={this.handleFieldChange}
              placeholder="Check #"
              size="small"
              value={checkNumber.value}
              width={4}
            />
            <Form.Input
              error={checkRouting.errors.length > 0}
              label="ABA Routing Number"
              name="checkRouting"
              onChange={this.handleFieldChange}
              placeholder="ABA Routing Number"
              size="small"
              value={checkRouting.value}
              width={6}
            />
            <Form.Input
              error={checkAccount.errors.length > 0}
              label="Account Number"
              name="checkAccount"
              onChange={this.handleFieldChange}
              placeholder="Account Number"
              size="small"
              value={checkAccount.value}
              width={6}
            />
          </Form.Group>
          <Notes>
            If you are not sure how to fill in these fields, please take a look at the{' '}
            <Popup
              basic
              content={<img alt="Check sample" src="/next/img/check.gif" />}
              position="bottom left"
              trigger={<span style={{color: '#2196f3', cursor: 'pointer'}}>sample check</span>}
            />
          </Notes>
        </Section>
        <Section>
          <Checkbox
            defaultChecked={checkRemember}
            label="Remember my payment information"
            name="checkRemember"
            onChange={this.handleFieldChange}
            value={1}
          />
        </Section>
        <Section last>
          <div>
            <Title>Check Address</Title>
          </div>
          <br />
          <Address onChange={this.handleAddressChange(PAYMENT_TYPE_E_CHECK)} value={getAddressValues('check', this.state)} />
        </Section>
        {donationItems.length > 0 && (
          <Section last>
            <Title>Donation</Title>
            <Form.Group widths="equal">
              <Form.Radio
                checked={checkDonation === 1}
                label={
                  <label>
                    I want to make an over 100% efficient donation{' '}
                    <LinkButton onClick={this.handleDonationsModalOpen}>[Learn More]</LinkButton>
                  </label>
                }
                name="checkDonation"
                onChange={this.handleSimpleFieldChange}
                value={1}
                width={6}
              />
              <Form.Radio
                checked={checkDonation === 0}
                label="No donation with my order"
                name="checkDonation"
                onChange={this.handleSimpleFieldChange}
                value={0}
                width={6}
              />
            </Form.Group>
            {checkDonation === 1 && (
              <Form.Group>
                <Form.Input
                  error={checkDonationNumber.errors.length > 0}
                  label="Check #"
                  name="checkDonationNumber"
                  onChange={this.handleFieldChange}
                  placeholder="Check #"
                  size="small"
                  value={checkDonationNumber.value}
                  width={4}
                />
                <Form.Field
                  control={Dropdown}
                  label="Donate to"
                  name="checkDonationId"
                  onChange={this.handleSimpleFieldChange}
                  options={options}
                  placeholder="Select a Donation"
                  scrolling
                  selection
                  size="small"
                  value={checkDonationId}
                  width={12}
                />
              </Form.Group>
            )}
          </Section>
        )}
      </div>
    );
  };

  renderCredit() {
    const {viewer} = this.props;
    const userCredit = Number(viewer.user.credit) || 0;
    const {isCheckout, orderId} = this.state;
    const buttonText = isCheckout ? (orderId > 0 ? 'Update Order' : 'Place Order') : 'Make a Payment';
    return (
      <div>
        <Section>
          <p>
            Your order payment is covered in full by your credit of <strong>${userCredit}.</strong>
          </p>
          <p>
            There is no need to make a payment just press <strong>{buttonText}</strong> to continue.
          </p>
        </Section>
      </div>
    );
  }

  renderCard() {
    const {cardAgreement, cardCode, cardDonation, cardDonationId, cardExpiration, cardName, cardNumber, cardRemember, isCheckout, orderId} =
      this.state;

    const donationItems = (get(this, 'props.viewer.donationItems.edges') || []).map((v) => v.node);
    const options = donationItems
      .sort((a, b) => b.amount - a.amount)
      .map((donationItem) => ({
        text: `${money(donationItem.amount)} - ${donationItem.name}`,
        value: donationItem.rowId,
      }));

    return (
      <div>
        {this.renderPaymentInformation()}
        <Section>
          <Title>Card Information</Title>
          <Form.Group widths="equal">
            <Form.Input
              error={cardName.errors.length > 0}
              label="Name on Card"
              name="cardName"
              onChange={this.handleFieldChange}
              placeholder="Name on Card"
              size="small"
              value={cardName.value}
              width={6}
            />
            <Form.Input
              error={cardNumber.errors.length > 0}
              label="Card Number"
              maxLength={16}
              name="cardNumber"
              onChange={this.handleFieldChange}
              placeholder="Card Number"
              size="small"
              value={cardNumber.value}
              width={5}
            />
            <Form.Input
              error={cardExpiration.errors.length > 0}
              label="Expiration"
              maxLength={5}
              name="cardExpiration"
              onChange={this.handleFieldChange}
              placeholder="MM/YY"
              size="small"
              value={cardExpiration.value}
              width={3}
            />
            <Form.Input
              error={cardCode.errors.length > 0}
              label="CVV"
              name="cardCode"
              onChange={this.handleFieldChange}
              placeholder="CVV"
              size="small"
              value={cardCode.value}
              width={2}
            />
          </Form.Group>
        </Section>
        <Section>
          <Checkbox
            defaultChecked={cardRemember}
            label="Remember my payment information"
            name="cardRemember"
            onChange={this.handleFieldChange}
            value={1}
          />
        </Section>
        <Section>
          <div>
            <Title>Card Address</Title>
          </div>
          <Address onChange={this.handleAddressChange(PAYMENT_TYPE_CARD)} value={getAddressValues('card', this.state)} />
        </Section>
        {donationItems.length > 0 && (
          <Section>
            <Title>Donation</Title>
            <Form.Group widths="equal">
              <Form.Radio
                checked={cardDonation === 1}
                label={
                  <label>
                    I want to make an over 100% efficient donation{' '}
                    <LinkButton onClick={this.handleDonationsModalOpen}>[Learn More]</LinkButton>
                  </label>
                }
                name="cardDonation"
                onChange={this.handleSimpleFieldChange}
                value={1}
                width={6}
              />
              <Form.Radio
                checked={cardDonation === 0}
                label="No donation with my order"
                name="cardDonation"
                onChange={this.handleSimpleFieldChange}
                value={0}
                width={6}
              />
            </Form.Group>
            {cardDonation === 1 && (
              <Form.Field
                control={Dropdown}
                label="Donate to"
                name="cardDonationId"
                onChange={this.handleSimpleFieldChange}
                options={options}
                placeholder="Select a Donation"
                scrolling
                selection
                size="small"
                value={cardDonationId}
              />
            )}
          </Section>
        )}
        <WarningSection error={cardAgreement.errors.length > 0 ? true : false} last>
          <Checkbox
            defaultChecked={cardAgreement.value}
            error={cardAgreement.errors.length > 0}
            label={`I understand that there is a 3% surcharge for paying by credit card${isCheckout || orderId > 0 ? ', and I understand there may be a pending charge on my credit card for the original amount of my order until the final charge is processed after the Co-op delivery.' : '.'}`}
            name="cardAgreement"
            onChange={this.handleFieldChange}
            value={1}
          />
        </WarningSection>
      </div>
    );
  }

  renderMail() {
    const donationItems = (get(this, 'props.viewer.donationItems.edges') || []).map((v) => v.node);

    return (
      <div>
        <Section last={donationItems.length ? false : true}>
          <Title>Mail Check</Title>
          <p>Please make a check payable to KC Kosher Co-op and send to:</p>
          <p>
            <em>
              KC Kosher Co-op
              <br />
              8212 W 97th Terrace
              <br />
              Overland Park, KS, 66212
            </em>
          </p>
          <p>
            Your order will not be placed until payment is received. Since payment must be received by the order deadline, if you are
            placing an order on the deadline you will need to pay immediately on our Payment Page or send your check via 2-day mail (eg:
            USPS Priority Mail) postmarked on the order deadline. For your reference, we will email confirmation once the check has been
            received.
          </p>
          <p>
            Please read our{' '}
            <a href="/help" target="_blank">
              FAQ
            </a>{' '}
            section, there is important information about product availability and other order logistics.
          </p>
        </Section>
        {donationItems.length > 0 && (
          <Section last>
            <Title>Donations</Title>
            <p>
              The Co-op has partnered with the below organizations to offer an over 100% efficient donation. For "Food Pantry" type of
              organizations, this means that your donation will be put into a segregated bucket that can be used for only one purpose to
              purchase food for those in need. Additionally, KC Kosher Co-op will make an additional contribution with every Member donation
              (hence the efficiency is over 100%).
            </p>
            <p>
              All donations are to 501(c)(3) non-profits and are fully tax deductible. Please consider mailing a second check for one of the
              following organizations:
            </p>
            <ul>
              {donationItems
                .sort((a, b) => b.amount - a.amount)
                .map((donationItem) => (
                  <li key={donationItem.id}>
                    {money(donationItem.amount)} - {donationItem.name}
                  </li>
                ))}
            </ul>
          </Section>
        )}
      </div>
    );
  }

  renderDonationsModal() {
    const donationItems = (get(this, 'props.viewer.donationItems.edges') || []).map((v) => v.node);

    let donationOrganizations = donationItems.reduce((p, c) => {
      const name = get(c, 'organization.name') || null;

      if (name) {
        if (!p[name]) {
          p[name] = {
            description: c.organization.description,
            donationItems: [],
            name: name,
          };
        }

        p[name].donationItems.push(c);
      }

      return p;
    }, {});
    donationOrganizations = Object.values(donationOrganizations);

    return (
      <Modal closeIcon onClose={this.handleDonationsModalClose} open={this.state.isDonationsModalOpen}>
        <Modal.Header>Give a Donation</Modal.Header>
        <Modal.Content>
          <p>
            The Co-op has partnered with the below organizations to offer an over 100% efficient donation. For "Food Pantry" type of
            organizations, this means that your donation will be put into a segregated bucket that can be used for only one purpose--to
            purchase food for those in need. Additionally, KC Kosher Co-op will make an additional contribution with every Member donation
            (hence the efficiency is over 100%).
          </p>
          <p>
            All donations are to 501(c)(3) non-profits and those organizations will send you documentation of your donation. Your donation
            will appear on your bank statement as a second debit.
          </p>
          {donationOrganizations.map((donationOrganization) => (
            <div key={donationOrganization.name} style={{paddingTop: 20}}>
              <h3>{donationOrganization.name}</h3>
              <p>{donationOrganization.description}</p>
              <ul>
                {donationOrganization.donationItems.map((donationItem) => (
                  <li key={donationItem.id}>
                    {money(donationItem.amount)} - {donationItem.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const {isCheckout, isSubmitting, orderId, paymentType} = this.state;
    const orderOptions = this.getOrderOptions();
    const totals = this.getTotals();
    const isBanned = get(this.props.viewer.user, 'isBanned') || false;
    const isPcExempt = get(this.props.viewer.user, 'isPcExempt') || false;
    const owed = get(this.props.viewer, 'user.owed') || 0;
    const owedReason = get(this.props.viewer, 'user.owedReason') || '';

    return (
      <Form error={!isValid(this.state)} onSubmit={this.handleSubmit}>
        <input name="orderId" type="hidden" value="cart" />
        <Content>
          {this.renderDonationsModal()}
          <Grid stackable>
            <Grid.Column width={11}>
              {owed > 0 && <Message content={owedReason} header={`You have an outstanding balance of ${money(owed)}`} negative />}
              <Message error list={getErrorMessages(this.state)} />
              <Column>
                <Inner>
                  {totals.payment > 0 ? (
                    paymentType === PAYMENT_TYPE_CHECK || paymentType === PAYMENT_TYPE_E_CHECK ? (
                      totals.owed > 0 ? (
                        <>
                          <h4>Update existing Order</h4>
                          <p>
                            Your order was paid by <strong>mailed check</strong>, but the total has increased and you owe an additional{' '}
                            <strong>{money(totals.owed)}</strong>. Click "Update Order" to add your cart items to your existing order and
                            complete your checkout.
                          </p>
                          {this.renderMail()}
                        </>
                      ) : (
                        <>
                          <h4>Update existing Order</h4>
                          <p>
                            Your order was paid by <strong>electronic check</strong> and no additional payment is needed at this time. Click
                            "Update Order" to add your cart items to your existing order and complete your checkout.
                          </p>
                        </>
                      )
                    ) : (
                      <>
                        <h4>Update existing Order</h4>
                        <p>
                          Your order was payed by {paymentType === PAYMENT_TYPE_CARD ? 'Card' : 'Electronic Check'}. Your payment will be
                          automatically updated to cover the new order total.
                        </p>
                        <p>Click "Update Order" to add the items from your Cart to your existing order {orderId}.</p>
                      </>
                    )
                  ) : (
                    <>
                      <Section>
                        <Grid columns={3} style={{marginBottom: 1}} widths="equal">
                          {!this.canPayWithCredit && !isBanned && (
                            <Grid.Column>
                              <PaymentType
                                icon="/next/icons/32x32/cheque.png"
                                onClick={this.handlePaymentTypeSelection(PAYMENT_TYPE_E_CHECK)}>
                                {/* // eslint-disable-next-line max-len */}
                                <input
                                  checked={paymentType === PAYMENT_TYPE_E_CHECK}
                                  name="paymentType"
                                  readOnly
                                  type="radio"
                                  value="ec"
                                />{' '}
                                Check
                              </PaymentType>
                            </Grid.Column>
                          )}
                          {!this.canPayWithCredit && (
                            <Grid.Column>
                              <PaymentType
                                icon="/next/icons/32x32/card_front.png"
                                onClick={this.handlePaymentTypeSelection(PAYMENT_TYPE_CARD)}>
                                <input checked={paymentType === PAYMENT_TYPE_CARD} name="paymentType" readOnly type="radio" value="cc" />{' '}
                                Card
                              </PaymentType>
                            </Grid.Column>
                          )}
                          {!this.canPayWithCredit && !isBanned && isPcExempt && (
                            <Grid.Column>
                              <PaymentType
                                icon="/next/icons/32x32/email_air.png"
                                onClick={this.handlePaymentTypeSelection(PAYMENT_TYPE_CHECK)}>
                                {/* // eslint-disable-next-line max-len */}
                                <input
                                  checked={paymentType === PAYMENT_TYPE_CHECK}
                                  name="paymentType"
                                  readOnly
                                  type="radio"
                                  value="pc "
                                />{' '}
                                Mail
                              </PaymentType>
                            </Grid.Column>
                          )}
                          {this.canPayWithCredit && (
                            <Grid.Column>
                              <PaymentType icon="/next/icons/32x32/credit.png">
                                <input checked name="paymentType" readOnly type="radio" value="pc" /> Credit
                              </PaymentType>
                            </Grid.Column>
                          )}
                        </Grid>
                      </Section>
                      {!this.canPayWithCredit && paymentType === PAYMENT_TYPE_E_CHECK && this.renderCheck()}
                      {!this.canPayWithCredit && paymentType === PAYMENT_TYPE_CARD && this.renderCard()}
                      {!this.canPayWithCredit && paymentType === PAYMENT_TYPE_CHECK && this.renderMail()}
                      {this.canPayWithCredit && this.renderCredit()}
                    </>
                  )}
                  {!this.props.isMobile && (
                    <React.Fragment>
                      <br />
                      <Grid>
                        <Grid.Column width={6}>
                          <PlaceOrderButton disabled={isSubmitting} loading={isSubmitting} positive size="huge">
                            {isCheckout ? (orderId > 0 ? 'Update Order' : 'Place Order') : 'Make a Payment'}
                          </PlaceOrderButton>
                        </Grid.Column>
                      </Grid>
                    </React.Fragment>
                  )}
                </Inner>
              </Column>
            </Grid.Column>
            <Grid.Column width={5}>
              <Column>
                <Inner>
                  {orderOptions.length > 0 && (
                    <>
                      <Dropdown
                        fluid
                        onChange={this.handleOrderSelection}
                        options={orderOptions}
                        placeholder="Select Order"
                        selection
                        value={String(orderId)}
                      />
                      <br />
                    </>
                  )}
                  <PlaceOrderButton disabled={isSubmitting} loading={isSubmitting} positive size="huge">
                    {isCheckout ? (orderId > 0 ? 'Update Order' : 'Place Order') : 'Make a Payment'}
                  </PlaceOrderButton>
                  {(isCheckout || (Number(orderId) !== 0 && orderId !== 'owed')) && <Totals {...totals} />}
                </Inner>
                <Note>
                  <p>
                    <u>Delivery fee</u> will ONLY be charged if the community does not reach the combined $7,000 minimum order. Learn more
                    about{' '}
                    <Popup
                      content={
                        <DeliveryFeesNote>
                          <h3>Free deliveries</h3>
                          <p>Delivery fee will only be charged if the community does not reach the combined $7,000 minimum order.</p>
                          <p>
                            If you pay via check or credit card online we will automatically adjust payment before processing for actual
                            delivery tier (which is often free).
                          </p>
                          <p>If you paid via physical check, the delivery fee will be automatically put as a credit on to your account.</p>
                          <h4>Delivery Tiers</h4>
                          <ul>
                            <li>
                              $7000+ - <span>Free Delivery!</span>
                            </li>

                            <li>
                              $6000 - $7000 - <span>3% Fee</span>
                            </li>
                            <li>
                              $5000 - $6000 - <span>5% Fee</span>
                            </li>
                            <li>
                              $4000 - $5000 - <span>7% Fee</span>
                            </li>
                            <li>
                              $3000 - $4000 - <span>10% Fee</span>
                            </li>
                            <li>
                              {/* Under $3000 - <span>Ship to Home Option</span> (coming soon) */}
                              Under $3000 - Not shipping
                            </li>
                          </ul>
                        </DeliveryFeesNote>
                      }
                      on="click"
                      position="bottom left"
                      trigger={<span style={{color: '#2196f3', cursor: 'pointer'}}>free deliveries</span>}
                    />
                    .
                  </p>
                  {orderId !== 0 && (
                    <p>
                      We will NOT charge your account at this time! Your account will only be debited after your order ships and will be for
                      the actual amount of your delivered order.
                    </p>
                  )}
                  <p>
                    The information on this page will be transmitted securely using SSL encryption. Learn more about &nbsp;
                    <a href="/help#q2-1" style={{color: '#2196f3'}} target="_blank">
                      paying online
                    </a>
                    .
                  </p>
                </Note>
              </Column>
            </Grid.Column>
          </Grid>
        </Content>
      </Form>
    );
  }
}

export default createFragmentContainer(withRouter(withWindowSize(Checkout)), {
  viewer: graphql`
    fragment Checkout_viewer on Viewer {
      id
      cart {
        subtotal
        shipping
        shippingRate
        tax
        taxRate
        total
        credit
        owed
      }
      coop {
        currentCycle {
          deadline
        }
        taxRate
        surchargeRate
      }
      donationItems(first: 100) {
        edges {
          node {
            id
            rowId
            organization {
              id
              rowId
              name
              description
            }
            name
            amount
          }
        }
      }
      orders(filterBy: {unpaid: true}, first: 100, orderBy: [["cycleId", "DESC"]]) {
        edges {
          node {
            id
            rowId
            subtotal
            shippingRate
            shipping
            taxRate
            tax
            total
            credit
            payment
            paymentType
            owed
          }
        }
      }
      user {
        firstName
        lastName
        address {
          street
          city
          state
          zip
        }
        cardInfo {
          name
          number
          expiration
        }
        checkInfo {
          account
          routing
        }
        checkoutOrder {
          id
          rowId
          subtotal
          shippingRate
          shipping
          taxRate
          tax
          total
          credit
          payment
          paymentType
          owed
        }
        isBanned
        isPcExempt
        owed
        owedReason
        credit
      }
    }
  `,
});
