import {isEmail} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import {createFragmentContainer} from 'react-relay';
import {Form, Message} from 'semantic-ui-react';
import styled from 'styled-components';
import {ContactMutation} from '../mutations/ContactMutation';

const Container = styled(Form)`
  min-height: 100vh;
  // background: #f7f7f7;
`;

const Wrapper = styled.div`
  max-width: 480px;
  margin: 0 auto 100px auto;
  padding: 20px;
`;

const Column = styled.div`
  margin-bottom: 20px;
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

class Contact extends React.Component {
  state = {
    email: {error: false, value: ''},
    isForm: true,
    message: {error: false, value: ''},
    messages: [],
    name: {error: false, value: ''},
    phone: {error: false, value: ''},
  };

  recaptchaRef = React.createRef();

  handleChange = (e, {checked, name, type, value}) => {
    if (type === 'checkbox') {
      value = checked;
    }

    if (typeof value === String) {
      value = value.trim();
    }

    //
    let error = false;
    if (name === 'email') {
      if (!isEmail(value)) {
        error = true;
      }
    }

    this.setState((prevState) => ({[name]: {...prevState[name], error, value}}));
  };

  handleCaptchaa = (captcha) => {
    console.log('C', captcha);
    this.setState({captcha});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {email, message, name} = this.state;
    const captcha = this.recaptchaRef.current.getValue() || null;

    // Validations
    let error = false;
    const errors = {
      email: {...email, error: false},
      message: {...message, error: false},
      name: {...name, error: false},
    };

    if (!isEmail(email.value)) {
      error = true;
      errors.email = {...email, error: true};
    }

    if (!String(name).trim().length) {
      error = true;
      errors.name = {...name, error: true};
    }

    if (!String(message).trim().length) {
      error = true;
      errors.message = {...message, error: true};
    }

    if (!String(captcha).trim().length) {
      error = true;
      errors.captcha = {...captcha, error: true};
      alert('Please click the Captcha verification');
    }

    if (error) {
      this.setState({form: errors});
      return;
    }

    //
    const allowed = ['email', 'name', 'phone', 'message'];

    //
    const payload = {
      input: {
        ...Object.keys(this.state)
          .filter((f) => allowed.indexOf(f) >= 0)
          .reduce((p, key) => ({...p, [key]: this.state[key].value}), {}),
        captcha,
        referer: 'N/A', // TODO: Add Browser history tracking and add referer
        state: get(this.props.viewer, 'user.address.state') || 'N/A',
      },
    };

    const onSuccess = (response) => {
      if (response.contact.errors) {
        const messages = [];
        const errors = response.register.errors.reduce((p, c) => {
          messages.push(c.message);
          return {...p, [c.field]: {...this.state[c.field], error: true}};
        }, {});
        this.setState({messages, ...errors});
      } else {
        this.setState({isForm: false});
      }
    };

    const onFailure = (response) => {
      console.log('Fail', response);
    };

    ContactMutation(payload, onSuccess, onFailure);
  };

  renderThanks() {
    return <Message content="Thank you for your inquiry. We will get back to you as soon as possible." header="Message sent!" positive />;
  }

  renderForm() {
    const {email, message, messages, name, phone} = this.state;

    return (
      <div>
        <Message content={messages.join('<br />')} error header="Please fix the following problems" />
        <Column>
          <Inner>
            <Message
              content={messages.join('<br />')}
              error
              header="Please fix the following problems"
              visible={Boolean(messages.length)}
            />
            <Form.Input
              error={email.error}
              label="Email"
              name="email"
              onChange={this.handleChange}
              placeholder=""
              required
              value={email.value}
            />
            <Form.Input
              error={name.error}
              label="Name"
              name="name"
              onChange={this.handleChange}
              placeholder=""
              required
              value={name.value}
            />
            <Form.Input error={phone.error} label="Phone" name="phone" onChange={this.handleChange} placeholder="" value={phone.value} />
            <Form.TextArea
              error={message.error}
              label="Message"
              name="message"
              onChange={this.handleChange}
              placeholder=""
              required
              rows={8}
              value={message.value}
            />
            <Recaptcha ref={this.recaptchaRef} sitekey="6Lc2SGgUAAAAANXem9d4AIJ3U06spInb1URGhTJv" />
          </Inner>
        </Column>
        <div style={{padding: '20px 20px'}}>
          <Form.Button fluid primary size="large" type="submit">
            Send
          </Form.Button>
        </div>
      </div>
    );
  }

  render() {
    const {isForm, messages} = this.state;

    return (
      <Container error={!isEmpty(messages)} onSubmit={this.handleSubmit}>
        <Wrapper>
          <Inner>
            <h3 style={{textAlign: 'center'}}>Contact Us</h3>
            {isForm ? this.renderForm() : this.renderThanks()}
          </Inner>
        </Wrapper>
      </Container>
    );
  }
}

export default createFragmentContainer(Contact, {
  viewer: graphql`
    fragment Contact_viewer on Viewer {
      user {
        address {
          state
        }
      }
    }
  `,
});
