import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation UpdateImageMutation($input: UpdateImageInput!) {
    updateImage(input: $input) {
      image {
        isPrimary
      }
      images(first: 10000) {
        edges {
          node {
            isPrimary
          }
        }
      }
    }
  }
`;

export const UpdateImageMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        fieldIDs: {
          image: input.id,
        },
        type: 'FIELDS_CHANGE',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['id', 'isPrimary']),
    },
  });
};
