/**
 * @generated SignedSource<<815df39db5fceec5c6464c5036aaa621>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContactInput = {
  captcha: string;
  email: string;
  message: string;
  name: string;
  phone?: string | null | undefined;
  referer?: string | null | undefined;
  state?: string | null | undefined;
};
export type ContactMutation$variables = {
  input: ContactInput;
};
export type ContactMutation$data = {
  readonly contact: {
    readonly _empty: string | null | undefined;
  } | null | undefined;
};
export type ContactMutation = {
  response: ContactMutation$data;
  variables: ContactMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactPayload",
    "kind": "LinkedField",
    "name": "contact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_empty",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a18bdca7ec549af73dee97b48e8293a0",
    "id": null,
    "metadata": {},
    "name": "ContactMutation",
    "operationKind": "mutation",
    "text": "mutation ContactMutation(\n  $input: ContactInput!\n) {\n  contact(input: $input) {\n    _empty\n  }\n}\n"
  }
};
})();

(node as any).hash = "049430b9964de4eacbb51c111b5aca67";

export default node;
