import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Button, Message, Rating, Table, TextArea} from 'semantic-ui-react';
import styled from 'styled-components';
import {RateProductMutation} from '../mutations/RateProductMutation';

const Container = styled.div``;
const Column = styled.div`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Heading = styled.h2``;

const STATE_DEFAULTS = {
  comment: '',
  errors: {
    messages: [],
  },
  isAnonymous: false,
  isSubmitting: false,
  rating: 0,
  success: '',
};

class ProductRatingRow extends React.Component {
  state = {
    ...STATE_DEFAULTS,
  };

  handleChange = (e, {name, type, value, ...rest}) => {
    if (type === 'checkbox') {
      this.setState({[name]: rest.checked});
    } else if (typeof rest.rating !== 'undefined') {
      this.setState({[name]: rest.rating});
    } else {
      this.setState({[name]: value});
    }
  };

  handleRate = (productId) => (e) => {
    e.preventDefault();

    const payload = {
      input: {
        comment: this.state.comment,
        isAnonymous: this.state.isAnonymous,
        productId: this.props.product.productId,
        rating: this.state.rating,
      },
    };

    const onSuccess = (response) => {
      window.location.reload(true);

      this.setState({
        ...STATE_DEFAULTS,
        success: 'Your Rating has been saved.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        ...STATE_DEFAULTS,
        errors: {
          messages: ['There was a problem saving your Rating'],
        },
      });
    };

    RateProductMutation(payload, onSuccess, onFailure);
  };

  handleSkip = (productId) => (e) => {
    e.preventDefault();

    const payload = {
      input: {
        comment: '',
        isAnonymous: false,
        productId: this.props.product.productId,
        rating: 0,
      },
    };

    const onSuccess = (response) => {
      window.location.reload(true);

      this.setState({
        ...STATE_DEFAULTS,
        success: 'Your Rating has been saved.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        ...STATE_DEFAULTS,
        errors: {
          messages: ['There was a problem saving your Rating'],
        },
      });
    };

    RateProductMutation(payload, onSuccess, onFailure);
  };

  render() {
    const {product} = this.props;

    return (
      <Table.Row key={product.id}>
        <Table.Cell width={7}>
          <Link to={`/products/${product.productId}`}>{product.fullName}</Link>
        </Table.Cell>
        <Table.Cell width={2}>
          <Rating clearable defaultRating={0} icon="star" maxRating={5} name="rating" onRate={this.handleChange} />
          {/* <br />
          <br />
          <Checkbox name="isAnonymous" label="Post Anonymously" onChange={this.handleChange} /> */}
        </Table.Cell>
        <Table.Cell width={5}>
          {/* TODO: Not supported anymore by React Semantic-UI: autoHeight={true} */}
          <TextArea name="comment" onChange={this.handleChange} style={{width: '100%'}} />
        </Table.Cell>
        <Table.Cell width={1}>
          <Button onClick={this.handleRate(product.productId)} primary>
            Rate
          </Button>
        </Table.Cell>
        <Table.Cell width={1}>
          <Button onClick={this.handleSkip(product.productId)}>Skip</Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

class Ratings extends React.Component {
  state = {
    ...STATE_DEFAULTS,
  };

  handleSplit = (splitProduct, splitUser) => (e) => {
    const to = splitUser.fullName;
    const from = get(this, 'props.viewer.user.email') || null;
    const subject = `KC Kosher Co-op - ${splitProduct.fullName}`;

    /* eslint-disable max-len */
    const message = `Hi ${splitUser.firstName},
I am interested in arranging a split of ${
      splitProduct.fullName
    } on the kckoshercoop.com website. Please contact me so we can arrange who will purchase the product and then after (or at) delivery how we will meet to split the product and cost.

Thanks,
${get(this, 'props.viewer.user.fullName')}`;
    /* eslint-enable max-len */

    this.setState({
      from,
      message,
      splitProduct,
      splitUser,
      subject,
      to,
    });
  };

  render() {
    const products = get(this.props.viewer, 'user.unratedProducts') || [];

    return (
      <Container>
        {this.state.errors.messages.length > 0 && (
          <Message
            content={
              <div>
                {this.state.errors.messages.map((v, i) => (
                  <p key={i}>{v}</p>
                ))}
              </div>
            }
            error
          />
        )}
        {this.state.success.length > 0 && <Message content={<div>{this.state.success}</div>} success />}
        <Column>
          <Inner>
            <Heading>Rate Your Previously Ordered Products</Heading>
            <p>
              Your experience with this product is the most valuable contribution to member's trying to order it! If you have a good recipe
              (or how to prepare) related to this product, that is also greatly appreciated by the Co-op community.
            </p>
            <p>
              Please keep your review objective and clean and note that your name will be published with your review once approved by the
              administrator.
            </p>
            {/*<p>
              <Link to="/rated">Click Here</Link> to browse products that other people have left comments on.
            </p>
            */}
          </Inner>
        </Column>
        <br />
        <Table celled selectable={products.length !== 0}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Rating</Table.HeaderCell>
              <Table.HeaderCell>Comment</Table.HeaderCell>
              <Table.HeaderCell>&nbsp;</Table.HeaderCell>
              <Table.HeaderCell>&nbsp;</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {products.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={5} textAlign="center">
                  <div style={{padding: '100px 0 100px 0'}}>You do not have any Products that are not rated.</div>
                </Table.Cell>
              </Table.Row>
            )}
            {products.map((product) => (
              <ProductRatingRow key={product.id} product={product} />
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Ratings), {
  viewer: graphql`
    fragment Ratings_viewer on Viewer {
      id
      user {
        id
        email
        fullName
        unratedProducts {
          id
          productId
          fullName
          package
        }
      }
    }
  `,
});
