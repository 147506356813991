import styled from 'styled-components';
import {withWindowSize} from '../../helpers';

const MobileContainer = styled.div`
  & > img {
    width: 100%;
    margin-bottom: 1em;
  }
`;

const Container = styled.div`
  background-image: url('${(p) => p.image}');
  background-repeat: no-repeat;
  background-position: ${(p) => p.position || 'left top'};
  background-size: ${(p) => 100 - (p.size || 100)}% auto;

  & > div {
    margin-${(p) => ((p.position || 'left top').indexOf('left') === -1 ? 'right' : 'left')}: ${(p) => 100 - (p.size || 100)}%;
    padding-${(p) => ((p.position || 'left top').indexOf('left') === -1 ? 'right' : 'left')}: 2rem;
    width: ${(p) => p.size || 100}%;
  }
`;

const TextWithImage = (props) => {
  if (props.isMobile) {
    return (
      <MobileContainer {...props}>
        <img alt="" src={props.image} />
        <div>{props.children}</div>
      </MobileContainer>
    );
  } else {
    return (
      <Container {...props}>
        <div>{props.children}</div>
      </Container>
    );
  }
};

export default withWindowSize(TextWithImage);
