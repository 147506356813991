import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation ResetSubstitutionsMutation($input: ResetSubstitutionsInput!) {
    resetSubstitutions(input: $input) {
      _empty
    }
  }
`;

export const ResetSubstitutionsMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    updater: (store) => {
      const viewerRecord = store.get(input.viewerId);
      const cart = viewerRecord.getLinkedRecord('cart');
      const itemsConnectionEdges = store.get(`client:${cart.getDataID()}:items(first:10000)`).getLinkedRecords('edges');
      const itemEdge = itemsConnectionEdges.find(
        (v) => v.getLinkedRecord('node').getLinkedRecord('product').getValue('productId') === input.productId,
      );
      itemEdge.getLinkedRecord('node').setLinkedRecords([], 'substitutions');
    },
    variables: {
      input: pick(input, ['productId']),
    },
  });
};
