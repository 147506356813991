/**
 * @generated SignedSource<<bb26f30501b56271138ce29cdc91e375>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSubstitutionInput = {
  productId?: string | null | undefined;
  substitutionId?: string | null | undefined;
};
export type AddSubstitutionMutation$variables = {
  input: AddSubstitutionInput;
};
export type AddSubstitutionMutation$data = {
  readonly addSubstitution: {
    readonly substitution: {
      readonly id: string;
      readonly product: {
        readonly fullName: string | null | undefined;
        readonly id: string;
        readonly package: string | null | undefined;
        readonly productId: string | null | undefined;
      } | null | undefined;
      readonly productId: string | null | undefined;
      readonly rowId: number | null | undefined;
      readonly substitution: {
        readonly fullName: string | null | undefined;
        readonly id: string;
        readonly package: string | null | undefined;
        readonly productId: string | null | undefined;
      } | null | undefined;
      readonly substitutionId: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type AddSubstitutionMutation = {
  response: AddSubstitutionMutation$data;
  variables: AddSubstitutionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "package",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddSubstitutionPayload",
    "kind": "LinkedField",
    "name": "addSubstitution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Substitution",
        "kind": "LinkedField",
        "name": "substitution",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "substitutionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "substitution",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSubstitutionMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSubstitutionMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "249b5a7ab4c4a79831a3f2fe54f0ddf9",
    "id": null,
    "metadata": {},
    "name": "AddSubstitutionMutation",
    "operationKind": "mutation",
    "text": "mutation AddSubstitutionMutation(\n  $input: AddSubstitutionInput!\n) {\n  addSubstitution(input: $input) {\n    substitution {\n      id\n      rowId\n      productId\n      product {\n        id\n        productId\n        fullName\n        package\n      }\n      substitutionId\n      substitution {\n        id\n        productId\n        fullName\n        package\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a69e3377dce4a5242a604b3eed3f01f6";

export default node;
