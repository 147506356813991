import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 260px;
`;

const Header = styled.div`
  padding: 14px 25px 13px 25px;
  border-bottom: 1px solid #e5edec;
  font-size: 19px;
  font-weight: bold;
`;

const Content = styled.div`
  padding: 25px;
`;

const Category = styled.div`
  padding-bottom: 15px;
  cursor: pointer;
`;

const Title = styled(({children, icon, selected, ...rest}) => <a {...rest}>{children}</a>)`
  padding: 10px 10px 10px 0;
  font-size: 0.9em;
  position: relative;
  color: ${(props) => (props.selected ? '#1976D2' : '#222')};
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  &.selected {
    color: #1976d2;
  }
  &::after {
    position: absolute;
    content: '';
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    width: 24px;
    right: 0;
    z-index: -1;
    background-image: url('${(props) => props.icon}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;
  }
`;

const SubCategories = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  padding-top: 5px;
  padding-left: 15px;
  color: #999;
`;

const SubCategory = styled.a`
  display: block;
  padding: 5px 0;
  text-decoration: none;
  color: ${(props) => (props.selected ? '#1976D2' : '#999')};
  cursor: pointer;
  font-size: 15px;
  &:hover {
    color: #1976d2;
  }
`;

class StoreCategories extends React.Component {
  categorySlug = null;
  subcategorySlug = null;
  subsubcategorySlug = null;

  constructor(props) {
    super(props);

    this.parseLocation(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.parseLocation(nextProps);
  }

  parseLocation = (props) => {
    let slug;

    if (props.category) {
      slug = `categories/${props.category.split('/').slice(0, -1).join('/')}`;
    } else {
      slug = props.match.location.pathname;
    }

    const parts = slug.split('/').filter((v) => String(v).trim() !== '');

    if (parts.length >= 1 && parts[0] === 'categories') {
      if (parts.length === 1) {
        parts.push(this.props.viewer.categories.edges[0].node.slug);
        this.props.router.replace('/categories/' + this.props.viewer.categories.edges[0].node.slug);
      }

      this.categorySlug = parts[1];
      this.subcategorySlug = parts[2] ? [parts[1], parts[2]].filter(Boolean).join('/') : null;
      this.subsubcategorySlug = parts[3] ? [parts[1], parts[2], parts[3]].filter(Boolean).join('/') : null;
    } else {
      this.categorySlug = null;
      this.subcategorySlug = null;
      this.subsubcategorySlug = null;
    }
  };

  handleCategoryClick = (slug) => (e) => {
    this.props.router.push(`/categories/${slug}`);
  };

  render() {
    const {categorySlug, subcategorySlug, subsubcategorySlug} = this;
    const {viewer} = this.props;

    const allCategories = ((viewer && viewer.categories && viewer.categories.edges) || []).map((v) => v.node);

    const category = allCategories.find((category) => category.slug === categorySlug);
    const categories = ((category && category.categories && category.categories.edges) || []).map((v) => v.node);

    if (!category && categories.length === 0) {
      return null;
    }

    return (
      <Container>
        <Header>{(category && category.name) || 'Subcategories'}</Header>
        <Content>
          {/* <Category key="specials">
            <Title
              icon={`/next/img/categories/specials.png`}
              onClick={this.handleCategoryClick('specials')}
              selected={currentCategory === 'specials'}
            >
              Specials
            </Title>
          </Category>
          <Category key="clearance">
            <Title
              icon={`/next/img/categories/clearance.png`}
              onClick={this.handleCategoryClick('clearance')}
              selected={currentCategory === 'clearance'}
            >
              Clearance
            </Title>
          </Category>
          <Category key="new-products">
            <Title
              icon={`/next/img/categories/new.png`}
              onClick={this.handleCategoryClick('new')}
              selected={currentCategory === 'new'}
            >
              New Products
            </Title>
          </Category> */}

          {category && (
            <Category key={`top-${category.id}`}>
              <Title onClick={this.handleCategoryClick(`${categorySlug}/top`)} selected={`${categorySlug}/top` === subcategorySlug}>
                Top Products
              </Title>
            </Category>
          )}
          {categories.map((category) => (
            <Category key={category.id}>
              <Title
                icon={`/next/img/categories/${category.slug}.png`}
                onClick={this.handleCategoryClick(category.slug)}
                selected={category.slug === subcategorySlug}>
                {category.name}
              </Title>
              <SubCategories show={category.slug === subcategorySlug}>
                <SubCategory
                  key={`top-${category.id}`}
                  onClick={this.handleCategoryClick(`${category.slug}/top`)}
                  selected={`${category.slug}/top` === subsubcategorySlug}>
                  Top Products
                </SubCategory>
                {category.categories.edges.map(({node: subCategory}) => (
                  <SubCategory
                    key={subCategory.id}
                    onClick={this.handleCategoryClick(subCategory.slug)}
                    selected={subCategory.slug === subsubcategorySlug}>
                    {subCategory.name}
                  </SubCategory>
                ))}
              </SubCategories>
            </Category>
          ))}
        </Content>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(StoreCategories), {
  viewer: graphql`
    fragment StoreCategories_viewer on Viewer {
      categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
        edges {
          node {
            id
            name
            slug
            categories(filterBy: {isActive: true}) {
              edges {
                node {
                  id
                  name
                  slug
                  categories(filterBy: {isActive: true}) {
                    edges {
                      node {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});
