import {DateTime, money, parseGraphqlErrors} from '@ivosabev/helpers';
import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Icon} from 'semantic-ui-react';
import styled from 'styled-components';
import {showBanner} from '../actions/showBanner';
import {go} from '../helpers';
import formatTime from '../helpers/formatTime';
import {toGlobalId} from '../helpers/toGlobalId';
import {AddCartItemMutation} from '../mutations/AddCartItemMutation';
import {DeleteCartItemMutation} from '../mutations/DeleteCartItemMutation';
import {UpdateCartItemMutation} from '../mutations/UpdateCartItemMutation';
import CartSubstitutions from './CartSubstitutions';
import {Button2} from './Common/Button';
import {CustomIcon} from './CustomIcon/CustomIcon';
import ProductImage from './Products/ProductImage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #000;
  background: #fff;
  overflow: hidden;
  text-align: left;
  line-height: 1.4285em;
  box-shadow: 1px 0px 5px 5px rgba(0, 0, 0, 0.16);
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  height: 67px;
  border-bottom: 1px solid #d5d5d6;
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: 400;
`;

//

const Deadline = styled.div`
  width: 100%;
  padding: 1em;
  color: #fff;
  background: #f06d00;
  text-align: center;

  & > strong {
    text-decoration: underline;
  }
`;

//

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: ${(props) => (props.height ? props.height - 61 - 106 : 0)}px;
  overflow: auto;
  flex: 1;
`;

const Footer = styled.div`
  padding: 15px;
  border-top: 1px solid #d5d5d6;
`;

const Total = styled.div`
  display: flex;
  padding: 0 5px 15px 5px;
  font-size: 19px;
`;

const TotalQty = styled.div`
  width: 50%;
  & > span {
    font-size: 15px;
  }
`;

const TotalAmount = styled.div`
  width: 50%;
  text-align: right;
`;

const CheckoutButton = styled(Button2)`
  padding: 10px;
  width: 100%;
  font-size: 19px;

  color: white;
  background: #ff5722;
  border: 0;

  &:hover {
    background: #f4511e;
  }

  &:active {
    background: #e64a19;
  }

  &:disabled {
    background: #ccc;
  }
`;

//

const ProductItem = styled.div`
  display: flex;
  width: 100%;
  min-height: 120px;
  align-items: center;
  // justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #d5d5d6;

  &:last-child {
    border: 0;
  }
`;

const Chooser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 20px;
  height: 100%;
`;

const Up = styled(CustomIcon)`
  margin: 0 !important;
  width: 100%;
  font-size: 20px !important;
  text-align: center;
  line-height: 0.8em;
  cursor: pointer;
`;

const Down = styled(CustomIcon)`
  margin: 0 !important;
  width: 100%;
  font-size: 20px !important;
  text-align: center;
  line-height: 0.8em;
  cursor: pointer;
`;

const Qty = styled.div`
  margin-left: 0px;
  width: 100%;
  text-align: center;
  font-size: 15px;
`;

const Image = styled(ProductImage)`
  margin: 0 10px;
  min-width: 64px;
`;

const ProdcutItemContent = styled.div`
  width: 95%;
  padding-right: 5%;
  display: flex;
  flex-direction: column;
`;

const Name = styled.a`
  color: ##1976d2;
  font-size: 0.9rem;
`;

const Package = styled.div`
  color: #9e9e9e;
  font-size: 0.9rem;
`;

const Price = styled.div`
  margin: 5px 0 5px 5px;
`;

const RemoveButton = styled(Icon)`
  margin: 10px 10px 10px 20px !important;
  width: 38px;
  cursor: pointer;
  opacity: 0.3 !important;
  &:hover {
    opacity: 1 !important;
    color: red;
  }
`;

class Cart extends React.Component {
  // static defaultProps: {};

  state = {
    windowHeight: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  };

  handleIncrease = (cartItem) => (e) => {
    e.stopPropagation();

    const {cart} = this.props.viewer;
    const product = cartItem.product;

    const onSuccess = () => {};
    const onFailure = (r) => {
      const errors = parseGraphqlErrors(r);
      showBanner(
        'error',
        errors.map((e) => e.message),
      );
    };

    if (cartItem && cartItem.qty > 0) {
      const payload = {
        input: {
          id: cartItem.id,
          qty: cartItem.qty + 1,
        },
      };

      UpdateCartItemMutation(payload, onSuccess, onFailure);
    } else {
      const payload = {
        input: {
          productId: product.productId,
          qty: 1,
          userId: cart.id,
        },
      };

      AddCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  handleDecrease = (cartItem) => (e) => {
    e.stopPropagation();

    const onSuccess = () => {};
    const onFailure = () => {};

    if (cartItem.qty > 1) {
      const payload = {
        input: {
          id: cartItem.id,
          qty: cartItem.qty - 1,
        },
      };

      UpdateCartItemMutation(payload, onSuccess, onFailure);
    } else {
      const payload = {
        input: {
          id: cartItem.id,
        },
      };

      DeleteCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  handleDeleteCartItem = (cartItem) => (e) => {
    e.preventDefault();

    if (window.confirm('Are you sure?')) {
      const payload = {
        input: {
          id: cartItem.id,
        },
      };

      const onSuccess = () => {};
      const onFailure = () => {};

      DeleteCartItemMutation(payload, onSuccess, onFailure);
    }
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();

    if (document.referrer) {
      window.history.go(-1);
    } else {
      this.props.router.push('/');
    }
  };

  handleCheckout = () => {
    // Note: Don't warn about substitutions

    // const hasMissingSubstitutions = (get(this.props.viewer, 'cart.items.edges') || []).reduce(
    //   (p, c) => (((c && c.node && c.node.substitutions) || []).length === 0 ? true : p),
    //   false,
    // );

    // if (
    //   hasMissingSubstitutions &&
    //   !window.confirm(
    //     // eslint-disable-next-line max-len
    //     `You have not selected any substitutions! To do this, please return to your Shopping Cart and click the "Select" button to the right of each item.\n\nKnowing what "Substitutions" are acceptable if your ordered product is not available is critical in our attempt to fulfill your entire order. For example, this lets us know you are fine with Brand X ground beef if Brand Y is not available.\n\nPlease click "Cancel" to return to your cart and select substitutions or "Ok" to continue on to review your order before placing it.`,
    //   )
    // ) {
    //   return;
    // }

    this.props.router.push('/checkout');
  };

  render() {
    // const {windowHeight} = this.state;
    const {router, viewer} = this.props;

    const {cart, user} = viewer;
    const subtotal = (cart && cart.subtotal) || 0;
    const credit = (user && user.credit) || 0;
    const items = get(viewer, 'cart.items.edges') || [];

    const timezone = viewer?.coop?.timezone || 'America/Chicago';
    let currentCycleDeadline = get(viewer, 'coop.currentCycle.deadline') || null;
    currentCycleDeadline = DateTime.fromSQL(`${currentCycleDeadline} 23:59:59 ${timezone}`, {setZone: true});
    const now = DateTime.local();
    const days = currentCycleDeadline.diff(now).as('days');

    return (
      <Container>
        <Header>
          <Title>Your Cart</Title>
          <CustomIcon name="fas-times" onClick={this.handleClose} size="2x" style={{color: '#2185d0', cursor: 'pointer', marginRight: 0}} />
        </Header>
        {days > 0 && days <= 7 && (
          <Deadline>
            Order by{' '}
            <strong>
              {currentCycleDeadline.toFormat('ccc, L/d')}, {formatTime(currentCycleDeadline.toSQL(), null, true)}
            </strong>{' '}
            to have your items delivered <strong>this cycle</strong>.
          </Deadline>
        )}
        <Content>
          {items.map(({node: item}) => {
            const {clearanceItemId, product, specialItemId} = item;
            const variantId = clearanceItemId
              ? toGlobalId('ClearanceItem', clearanceItemId)
              : specialItemId
                ? toGlobalId('SpecialItem', specialItemId)
                : '';
            const url = `/products/${product.productId}/${variantId}`;
            return (
              <ProductItem key={item.id}>
                <Chooser>
                  <Up name="fas-angle-up" onClick={this.handleIncrease(item)} />
                  <Qty>{item.qty}</Qty>
                  <Down name="fas-angle-down" onClick={this.handleDecrease(item)} />
                </Chooser>
                <Image size="64" src={get(item, 'product.images.edges[0].node.url') || null} />
                <ProdcutItemContent>
                  <Name href={`/products/${item.product.productId}`} onClick={go(url, {router})}>
                    {(item.clearanceItemId || item.specialItemId) && (
                      <CustomIcon
                        name="fas-badge-percent"
                        style={{
                          color: item.clearanceItemId ? '#eb5f00' : '#ff9800',
                          fontSize: 20,
                          marginRight: 4,
                          position: 'relative',
                          top: 1,
                        }}
                      />
                    )}
                    {item.product.fullName}
                  </Name>
                  <Package>{item.product.package}</Package>
                  <CartSubstitutions
                    category={(item.product.category && item.product.category.slug) || null}
                    item={item}
                    product={item.product}
                    viewer={this.props.viewer}
                  />
                </ProdcutItemContent>
                <Price>{money(item.total)}</Price>
                <RemoveButton name="trash alternate outline" onClick={this.handleDeleteCartItem(item)} size="large" />
              </ProductItem>
            );
          })}
        </Content>
        <Footer>
          <Total>
            <TotalQty>Subtotal</TotalQty>
            <TotalAmount>{money(subtotal)}</TotalAmount>
          </Total>
          {credit > 0 && (
            <Total style={{color: '#aaa'}}>
              <TotalQty>Available Credit</TotalQty>
              <TotalAmount>{money(credit)}</TotalAmount>
            </Total>
          )}
          <CheckoutButton disabled={!(subtotal > 0)} onClick={this.handleCheckout}>
            Checkout
          </CheckoutButton>
        </Footer>
      </Container>
    );
  }
}
// Cart.defaultProps = {};

export default createFragmentContainer(withRouter(Cart), {
  viewer: graphql`
    fragment Cart_viewer on Viewer {
      ...CartSubstitutions_viewer @arguments(filterBy: $filterBy, orderBy: $orderBy, page: $page, limit: $limit)
      coop {
        currentCycle {
          deadline
          pickup
          pickupStart
          pickupEnd
        }
        timezone
      }
      cart {
        id
        qty
        subtotal
        items(first: 10000) {
          edges {
            node {
              ...CartSubstitutions_item
              clearanceItemId
              id
              specialItemId
              total
              qty
              product {
                ...CartSubstitutions_product
                id
                category {
                  slug
                }
                productId
                fullName
                package
                images(first: 1) {
                  edges {
                    node {
                      url
                    }
                  }
                }
              }
              substitutions {
                id
              }
            }
          }
        }
      }
      user {
        credit
        role
      }
    }
  `,
});
