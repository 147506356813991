import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation AddCommentMutation($input: AddCommentInput!) {
    addComment(input: $input) {
      commentEdge {
        __typename
        node {
          id
          name
          comment
          rating
          createdAt
        }
      }
    }
  }
`;

export const AddCommentMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        connectionInfo: [
          {
            key: 'Product_comments',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'commentEdge',
        parentID: input.productId,
        type: 'RANGE_ADD',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['productId', 'comment', 'rating']),
    },
  });
};
