import {Link, withRouter} from 'found';
import styled from 'styled-components';
import {go} from '../../../helpers';
import {OrangeButton} from '../../Common/Button';
import TextWithImage from '../../Common/TextWithImage';

const Container = styled.div`
  & h2 {
    text-align: center;
  }

  ul ul {
    margin-top: 1em;
    margin-bottom: 1em;
  }
`;

const StartCoop = (props) => (
  <Container>
    <TextWithImage image="/next/img/join-a-coop.jpg" size={70}>
      <p>
        Co-ops are generally started by an individual or a core group in a community <Link to="/contact">contacting us</Link>.
      </p>

      <p>To see if starting a Co-op is right for your community, here are some of the questions we will ask you.</p>

      <ul>
        <li>Does your community have local sources of kosher food? </li>
        <li>Do people already order from out of town?</li>
        <li>How many kosher consumers are in the community?</li>
        <li>
          Do you have enough people participating to hit the $7,000 community minimum? (Please note we sometimes ship to communities placing
          smaller order, but smaller buying groups may not get the lowest possible price. Let’s discuss!)
        </li>
        <li>
          Do you have a location that would be a good delivery location? Delivery location requirements:
          <ul>
            <li>Be a central location for the participating community </li>
            <li>Should have a large enough parking lot/ driveway that a semi truck can easily pull in and out</li>
            <li>
              Should have a place to easily unload the truck (ideally to an indoor location or an outdoor covered area) and then load into
              members’ cars as they pull up.{' '}
            </li>
          </ul>
          In the vast majority of our Co-ops, we deliver to a local shul or dayschool
        </li>
        <li>
          Do you have someone who can be our Community Liaison? Responsibilities include:
          <ul>
            <li>Representing the community and the Co-op</li>
            <li>Running the local delivery (meeting truck, coordinating delivery, finding labor to help)</li>
            <li>Helping to spread the word to the local community</li>
            <li>In most cities, the Liaison receives compensation.</li>
          </ul>
        </li>
      </ul>
      <p>
        Please know that while we would love to service everyone, in some circumstances we determine that starting a Co-op would not be
        feasible due to introducing unhealthy competition or other logistical issues.
      </p>

      <h4>That is really about it!</h4>
      <p>Once you answer these questions contact us and it takes just a few minutes to light up a new Co-op on the website.</p>

      <br />
      <OrangeButton onClick={go('/contact', {router: props.router})} size="big">
        Start your own Co-op
      </OrangeButton>
    </TextWithImage>
  </Container>
);

export default withRouter(StartCoop);
