import {graphql} from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      coop {
        id
        rowId
        name
      }
      user {
        email
        phone
        phone2
        firstName
        lastName
        address {
          street
          city
          state
          zip
        }
      }
    }
  }
`;

export const UpdateUserMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {input},
  });
};
