import fromGlobalId from './fromGlobalId';

export default function getCartItemForProduct(cartItems, productId, {clearanceItemId, specialItemId} = {}) {
  productId = fromGlobalId(productId || '')?.id || null;
  clearanceItemId = fromGlobalId(clearanceItemId || '')?.id || null;
  specialItemId = fromGlobalId(specialItemId || '').id || null;

  return (
    cartItems.find((cartItem) => {
      const cartItemProductId = fromGlobalId(cartItem.productId || '').id || null;
      const cartItemSpecialItemId = fromGlobalId(cartItem.specialItemId || '').id || null;
      const cartItemClearanceItemId = fromGlobalId(cartItem.clearanceItemId || '').id || null;

      // console.log('D', cartItem.productId, cartItem.clearanceItemId, cartItem.specialItemId);
      // console.log('D', cartItemProductId, cartItemClearanceItemId, cartItemSpecialItemId);
      // console.log('C', productId, clearanceItemId, specialItemId);

      return (
        String(cartItemProductId) === String(productId) &&
        Number(cartItemClearanceItemId) === Number(clearanceItemId) &&
        Number(cartItemSpecialItemId) === Number(specialItemId)
      );
    }) || null
  );
}
