import {graphql} from 'babel-plugin-relay/macro';
import {Link, withRouter} from 'found';
import get from 'lodash/get';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Message, Table} from 'semantic-ui-react';
import styled from 'styled-components';
import {DeleteSubstitutionMutation} from '../mutations/DeleteSubstitutionMutation';
import {CustomIcon} from './CustomIcon/CustomIcon';

const Container = styled.div``;

const Column = styled.div`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;
`;

const Inner = styled.div`
  padding: 20px;
`;

const Heading = styled.h2``;

class Substitutions extends React.Component {
  state = {
    errors: {
      messages: [],
    },
    isSubmitting: false,
    openProductId: null,
    success: '',
  };

  handleToggleSubstitutions = (productId) => (e) => this.setState({openProductId: productId});

  handleDeleteSubstitution = (productId, substitutionId) => (e) => {
    e.preventDefault();

    const payload = {
      input: {
        productId,
        substitutionId,
        viewerId: this.props.viewer.id,
      },
    };

    const onSuccess = (response) => {
      this.setState({
        success: 'The Substitution has been deleted.',
      });

      setTimeout(() => this.setState({success: ''}), 5000);
    };

    const onFailure = (rawErrors) => {
      this.setState({
        error: '',
      });
    };

    DeleteSubstitutionMutation(payload, onSuccess, onFailure);
  };

  render() {
    const {openProductId} = this.state;
    const products = Object.values(
      (get(this.props.viewer.user, 'substitutions') || []).reduce((p, c) => {
        const product = (c && c.product) || null;

        if (product && product.productId) {
          if (p[product.productId]) {
            p[product.productId].substitutions.push(c);
          } else if (c.product) {
            p[product.productId] = {
              ...product,
              substitutions: [c],
            };
          }
        }

        return p;
      }, {}),
    ).sort((a, b) => a.fullName.localeCompare(b.fullName));

    return (
      <Container>
        {this.state.errors.messages.length > 0 && (
          <Message
            content={
              <div>
                {this.state.errors.messages.map((v, i) => (
                  <p key={i}>{v}</p>
                ))}
              </div>
            }
            error
          />
        )}
        {this.state.success.length > 0 && <Message content={<div>{this.state.success}</div>} success />}
        <Column>
          <Inner>
            <Heading>Acceptable Substitutions</Heading>
            <p>
              When you purchase products, there is an option in the cart to specify an alternate product should the supplier be out of what
              you ordered. This is a critical feature to increase order fulfillment.
            </p>
            <p>
              When you specify a sub, we store it so you don't have to enter it each time you order. If you want to change subs, you can
              click the product below to view or remove subs.
            </p>
          </Inner>
        </Column>
        <br />
        <Table celled selectable={products.length !== 0}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                Product <small style={{fontWeight: 'normal'}}>(click product to expand subs)</small>
              </Table.HeaderCell>
              <Table.HeaderCell>Package</Table.HeaderCell>
              <Table.HeaderCell>Your Subs</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {products.length === 0 && (
              <Table.Row>
                <Table.Cell colSpan={3} textAlign="center">
                  <div style={{padding: '100px 0 100px 0'}}>You do not have any Accepted Substitutions.</div>
                </Table.Cell>
              </Table.Row>
            )}
            {products.reduce((p, product, i) => {
              p.push(
                <Table.Row
                  key={`Product-${product.id}`}
                  onClick={this.handleToggleSubstitutions(product.productId)}
                  style={{cursor: 'pointer'}}>
                  <Table.Cell width={10}>{product.fullName}</Table.Cell>
                  <Table.Cell width={4}>{product.package}</Table.Cell>
                  <Table.Cell textAlign="center" verticalAlign="middle" width={2}>
                    {product.substitutions.length}
                  </Table.Cell>
                </Table.Row>,
              );

              if ((openProductId && openProductId === product.productId) || (!openProductId && i === 0)) {
                product.substitutions.forEach((substitution) => {
                  p.push(
                    <Table.Row key={`Substitution-${substitution.id}`}>
                      <Table.Cell width={10}>
                        &mdash;&nbsp;&nbsp;
                        <Link to={`/products/${substitution.substitution.productId}`}>{substitution.substitution.fullName}</Link>
                      </Table.Cell>
                      <Table.Cell width={4}>{substitution.substitution.package}</Table.Cell>
                      <Table.Cell textAlign="center" verticalAlign="middle" width={2}>
                        <CustomIcon
                          color="red"
                          name="fas-times"
                          onClick={this.handleDeleteSubstitution(product.productId, substitution.productId)}
                          size="large"
                          style={{cursor: 'pointer'}}
                        />
                      </Table.Cell>
                    </Table.Row>,
                  );
                });
              }

              return p;
            }, [])}
          </Table.Body>
        </Table>
      </Container>
    );
  }
}

export default createFragmentContainer(withRouter(Substitutions), {
  viewer: graphql`
    fragment Substitutions_viewer on Viewer {
      id
      user {
        id
        email
        fullName
        substitutions {
          id
          rowId
          product {
            id
            productId
            fullName
            package
          }
          substitution {
            id
            productId
            fullName
            package
          }
        }
      }
    }
  `,
});
