import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import {go} from '../../helpers';

const Container = styled.div`
  background: #eeeeee;

  & > div:first-child {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    margin: 0 auto;
    padding: 0 2% 2% 2%;
    max-width: 1360px;
    background: #fff;
  }
`;

const HomeCategoryItem = styled.div`
  width: 100%;
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url('${(p) => p.image}');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  cursor: pointer;

  @media (min-width: 48rem) {
    width: 49%;
    &:nth-child(odd) {
      margin-right: 2%;
    }
  }

  @media (min-width: 62rem) {
    width: 23.5%;
    &:not(:nth-child(4n)) {
      margin-right: 2%;
    }
  }

  &:before {
    content: '';
    float: left;
    padding-top: 100%;
  }

  & h4 {
    margin-top: 85%;
    color: #2196f3;
    color: #e65100;
    text-align: center;

    text-transform: uppercase;
    font-size: 21px;
  }

  &:hover {
    background-color: #ededed;
  }
`;

const saleCategories = [
  {
    node: {
      name: 'Clearance',
      slug: 'clearance',
    },
  },
  {
    node: {
      name: 'Specials',
      slug: 'specials',
    },
  },
];

const HomeCategories = (props) => {
  const categories = props?.viewer?.categories?.edges;

  return (
    <Container>
      <div>
        {[...(categories || []), ...saleCategories].map(({node: category}) => (
          <HomeCategoryItem
            image={`/next/img/categories2/${category.slug}.png`}
            key={category.slug}
            onClick={go(`/categories/${category.slug}`, {router: props.router})}>
            <h4>{category.name}</h4>
          </HomeCategoryItem>
        ))}
      </div>
    </Container>
  );
};

export default createFragmentContainer(withRouter(HomeCategories), {
  viewer: graphql`
    fragment HomeCategories_viewer on Viewer {
      categories(first: 10000, filterBy: {isActive: true, parentId: 0}) {
        edges {
          node {
            name
            slug
          }
        }
      }
    }
  `,
});
