import {graphql} from 'babel-plugin-relay/macro';
import pick from 'lodash/pick';
import {commitMutation} from 'react-relay';
import {environment} from '../environment';

const mutation = graphql`
  mutation DeleteImageMutation($input: DeleteImageInput!) {
    deleteImage(input: $input) {
      deletedImageId
    }
  }
`;

export const DeleteImageMutation = (payload, success, failure) => {
  const {input} = payload;

  return commitMutation(environment, {
    configs: [
      {
        deletedIDFieldName: 'deletedImageId',
        type: 'NODE_DELETE',
      },
    ],
    mutation,
    onCompleted: (response, errors) => (errors ? failure(errors) : success(response)),
    onError: (err) => failure(err),
    variables: {
      input: pick(input, ['imageId']),
    },
  });
};
