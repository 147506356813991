import {library} from '@fortawesome/fontawesome-svg-core';
// import {fal} from '@fortawesome/pro-light-svg-icons';
// import {fas} from '@fortawesome/pro-solid-svg-icons';
import {faBalanceScale as farBalanceScale, faHandshake as farHandshake} from '@fortawesome/pro-regular-svg-icons';
import {
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faAngleUp as fasAngleUp,
  faArchive as fasArchive,
  faBadgeDollar as fasBadgeDollar,
  faBadgePercent as fasBadgePercent,
  faBars as fasBars,
  faCreditCard as fasCreditCard,
  faEnvelope as fasEnvelope,
  faInfoCircle as fasInfoCircle,
  faMinus as fasMinus,
  faQuestion as fasQuestion,
  faShoppingCart as fasShoppingCart,
  faSignOutAlt as fasSignOutAlt,
  faStar as fasStar,
  faTasks as fasTasks,
  faTimes as fasTimes,
  faUser as fasUser,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  farBalanceScale,
  farHandshake,
  fasAngleDown,
  fasAngleLeft,
  fasAngleRight,
  fasAngleUp,
  fasArchive,
  fasBadgeDollar,
  fasBadgePercent,
  fasBars,
  fasCreditCard,
  fasEnvelope,
  fasInfoCircle,
  fasMinus,
  fasQuestion,
  fasShoppingCart,
  fasSignOutAlt,
  fasStar,
  fasTasks,
  fasTimes,
  fasUser,
);
