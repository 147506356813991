import {Link, withRouter} from 'found';
import React from 'react';
import {Grid, Menu} from 'semantic-ui-react';
import styled from 'styled-components';
import {withWindowSize} from '../helpers';

const Container = styled.div`
  min-height: 100vh;
  background: #f7f7f7;
`;

const Content = styled.div`
  margin: 20px auto;
  padding: 0 1%;
  max-width: 1200px;
`;

const Column = styled(Grid.Column)`
  background: white;
  border: 1px solid #e5edec;
  border-radius: 5px;

  .ui.menu {
    box-shadow: none !important;
    border: 0 !important;
  }
`;

const Item = (props) => (
  <Link activePropName="active" as={Menu.Item} {...props}>
    {props.children}
  </Link>
);

class Account extends React.Component {
  render() {
    return (
      <Container onSubmit={this.handleSubmit}>
        <Content>
          <Grid stackable>
            <Grid.Column width={4}>
              <Column>
                <Menu fluid vertical>
                  <Item to="/account/profile">Your Account</Item>
                  <Item to="/account/orders">Your Orders</Item>
                  <Item to="/account/ratings">Your Ratings</Item>
                  <Item to="/account/substitutions">Your Substitutions</Item>
                  <Item to="/account/splits">Split Products</Item>
                  {this.props.isMobile && <Item to="/logout">Logout</Item>}
                </Menu>
              </Column>
            </Grid.Column>
            <Grid.Column width={12}>{this.props.children}</Grid.Column>
          </Grid>
        </Content>
      </Container>
    );
  }
}

export default withRouter(withWindowSize(Account));
