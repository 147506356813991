import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import get from 'lodash/get';
import {createFragmentContainer} from 'react-relay';
import {Grid, Image, Label} from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled(Grid)`
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background: #f9f9f9;
  }
`;

// const Name = styled.div``;

// const Price = styled.div``;

const OrderItem = (props) => {
  const {orderItem, showTotals} = props;
  const {product} = orderItem;
  const imageUrl = get(product, 'image.url', '/next/img/default-product.png');

  return (
    <Container onClick={() => props.router.push(`/products/${product.productId}`)}>
      <Grid.Column width={2}>
        <Image size="small" src={imageUrl} />
      </Grid.Column>
      <Grid.Column width={showTotals ? 11 : 12}>
        {orderItem.clearanceItemId && (
          <Label size="medium" style={{background: '#eb5f00', color: 'white', marginRight: 6, position: 'relative', top: -4}}>
            CLEARANCE
          </Label>
        )}
        {orderItem.specialItemId && (
          <Label size="medium" style={{background: '#ff9800', color: 'white', marginRight: 6, position: 'relative', top: -4}}>
            ON SALE
          </Label>
        )}
        {product.fullName}
        <br />
        {product.package}
      </Grid.Column>
      <Grid.Column width={showTotals ? 1 : 2}>{orderItem.qty}x</Grid.Column>
      {showTotals && <Grid.Column width={2}>${orderItem.total.toFixed(2)}</Grid.Column>}
    </Container>
  );
};

export default createFragmentContainer(withRouter(OrderItem), {
  orderItem: graphql`
    fragment OrderItemRow_orderItem on OrderItem {
      clearanceItemId
      specialItemId
      total
      qty
      product {
        productId
        fullName
        package
        cost
        image {
          url
        }
      }
    }
  `,
});
