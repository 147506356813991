import {graphql} from 'babel-plugin-relay/macro';
import {withRouter} from 'found';
import {createFragmentContainer} from 'react-relay';
import styled from 'styled-components';
import {withWindowSize} from '../../helpers';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const Container = styled.div`
  z-index: 2000 !important;
  width: 100%;
  background: rgba(255, 255, 255, 0.975);

  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    position: relative;
  }
`;

const Header = (props) => (
  <Container style={{position: 'relative', ...props.style}}>
    {props.isMobile ? <MobileHeader viewer={props.viewer} /> : <DesktopHeader viewer={props.viewer} />}
  </Container>
);

export default createFragmentContainer(withRouter(withWindowSize(Header)), {
  viewer: graphql`
    fragment Header_viewer on Viewer {
      ...DesktopHeader_viewer
      ...MobileHeader_viewer
    }
  `,
});
