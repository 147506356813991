import styled from 'styled-components';

const Container = styled.div`
  max-width: 480px;
  min-width: 300px;
  width: 100%;

  h3 {
    margin: 0 0 1em 0;
    font-size: 18px;
  }

  h4 {
    margin: 1em 0 1em 0;
    font-size: 18px;
  }

  p {
    margin: 0 0 0.5em 0;
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 0 0.5em 0;
    font-size: 16px;
    list-style: none;

    & :last-child span {
      color: #f57c00;
    }

    & :first-child span {
      color: #2196f3;
    }
  }
`;

const DeliveryFeesNote = () => (
  <Container>
    <h3>Free deliveries</h3>
    <p>Delivery fee will only be charged if the community does not reach the combined $7,000 minimum order.</p>
    <p>
      If you pay via check or credit card online we will automatically adjust payment before processing for actual delivery tier (which is
      often free).
    </p>
    <p>If you paid via physical check, the delivery fee will be automatically put as a credit on to your account.</p>
    <h4>Delivery Tiers</h4>
    <ul>
      <li>
        $7000+ - <span>Free Delivery!</span>
      </li>

      <li>
        $6000 - $7000 - <span>3% Fee</span>
      </li>
      <li>
        $5000 - $6000 - <span>5% Fee</span>
      </li>
      <li>
        $4000 - $5000 - <span>7% Fee</span>
      </li>
      <li>
        $3000 - $4000 - <span>10% Fee</span>
      </li>
      <li>
        {/* Under $3000 - <span>Ship to Home Option</span> (coming soon) */}
        Under $3000 - Not shipping
      </li>
    </ul>
  </Container>
);

export default DeliveryFeesNote;
