/**
 * @generated SignedSource<<166ab0904399639d6d8b6951043083fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Checkout_viewer$data = {
  readonly cart: {
    readonly credit: number | null | undefined;
    readonly owed: number | null | undefined;
    readonly shipping: number | null | undefined;
    readonly shippingRate: number | null | undefined;
    readonly subtotal: number | null | undefined;
    readonly tax: number | null | undefined;
    readonly taxRate: number | null | undefined;
    readonly total: number | null | undefined;
  } | null | undefined;
  readonly coop: {
    readonly currentCycle: {
      readonly deadline: any | null | undefined;
    } | null | undefined;
    readonly surchargeRate: number | null | undefined;
    readonly taxRate: number | null | undefined;
  } | null | undefined;
  readonly donationItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amount: number | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly organization: {
          readonly description: string | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly rowId: number;
        } | null | undefined;
        readonly rowId: number;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly credit: number | null | undefined;
        readonly id: string;
        readonly owed: number | null | undefined;
        readonly payment: number | null | undefined;
        readonly paymentType: string | null | undefined;
        readonly rowId: number | null | undefined;
        readonly shipping: number | null | undefined;
        readonly shippingRate: number | null | undefined;
        readonly subtotal: number | null | undefined;
        readonly tax: number | null | undefined;
        readonly taxRate: number | null | undefined;
        readonly total: number | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly address: {
      readonly city: string | null | undefined;
      readonly state: string | null | undefined;
      readonly street: string | null | undefined;
      readonly zip: string | null | undefined;
    } | null | undefined;
    readonly cardInfo: {
      readonly expiration: string | null | undefined;
      readonly name: string | null | undefined;
      readonly number: string | null | undefined;
    } | null | undefined;
    readonly checkInfo: {
      readonly account: string | null | undefined;
      readonly routing: string | null | undefined;
    } | null | undefined;
    readonly checkoutOrder: {
      readonly credit: number | null | undefined;
      readonly id: string;
      readonly owed: number | null | undefined;
      readonly payment: number | null | undefined;
      readonly paymentType: string | null | undefined;
      readonly rowId: number | null | undefined;
      readonly shipping: number | null | undefined;
      readonly shippingRate: number | null | undefined;
      readonly subtotal: number | null | undefined;
      readonly tax: number | null | undefined;
      readonly taxRate: number | null | undefined;
      readonly total: number | null | undefined;
    } | null | undefined;
    readonly credit: number | null | undefined;
    readonly firstName: string | null | undefined;
    readonly isBanned: boolean | null | undefined;
    readonly isPcExempt: boolean | null | undefined;
    readonly lastName: string | null | undefined;
    readonly owed: number | null | undefined;
    readonly owedReason: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Checkout_viewer";
};
export type Checkout_viewer$key = {
  readonly " $data"?: Checkout_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Checkout_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtotal",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipping",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingRate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tax",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxRate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "credit",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "owed",
  "storageKey": null
},
v9 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v0/*: any*/),
  (v10/*: any*/),
  (v1/*: any*/),
  (v3/*: any*/),
  (v2/*: any*/),
  (v5/*: any*/),
  (v4/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "payment",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentType",
    "storageKey": null
  },
  (v8/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Checkout_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Cart",
      "kind": "LinkedField",
      "name": "cart",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Coop",
      "kind": "LinkedField",
      "name": "coop",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Cycle",
          "kind": "LinkedField",
          "name": "currentCycle",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "deadline",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "surchargeRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v9/*: any*/)
      ],
      "concreteType": "DonationItemConnection",
      "kind": "LinkedField",
      "name": "donationItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DonationItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DonationItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DonationOrganization",
                  "kind": "LinkedField",
                  "name": "organization",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v11/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "donationItems(first:100)"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filterBy",
          "value": {
            "unpaid": true
          }
        },
        (v9/*: any*/),
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            [
              "cycleId",
              "DESC"
            ]
          ]
        }
      ],
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "orders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "orders(filterBy:{\"unpaid\":true},first:100,orderBy:[[\"cycleId\",\"DESC\"]])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zip",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CardInfo",
          "kind": "LinkedField",
          "name": "cardInfo",
          "plural": false,
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expiration",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CheckInfo",
          "kind": "LinkedField",
          "name": "checkInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "account",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "routing",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Order",
          "kind": "LinkedField",
          "name": "checkoutOrder",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBanned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPcExempt",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owedReason",
          "storageKey": null
        },
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "77342f735b33621037bcf082a848ccd0";

export default node;
